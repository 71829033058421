import * as React from "react";
import { RouteComponentProps } from "react-router";
import { FormEvent, useEffect, useState } from "react";
import { TControlGroupState } from "../../types/ControlGroup";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/Store";
import Navbar from "../nav/Navbar";
import {
  getControlPointInfo,
  getControlPointOpenInstance,
  trans
} from "../../generic/Helpers";
import {
  TApiControlPointEntity,
  TOpenInstance
} from "../../types/ControlPoint";
import { Dispatch } from "redux";
import { getControlGroup } from "../../actions/ControlGroupActions";
import Loading from "../Loading";
import {
  dismissInstances,
  getDeviation
} from "../../actions/ControlPointActions";
import EmptyPage from "./EmptyPage";
import { TDeviationState } from "../../types/Deviation";

interface IDismissProps extends RouteComponentProps<any> {}

const Dismiss: React.FC<IDismissProps> = ({
  match: {
    params: { controlGroupId, controlPointId }
  },
  history,
  location
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [instances, setInstances] = useState<Array<number>>([]);
  const [actionSetId, setActionSetId] = useState<number | null>(null);
  const [reason, setReason] = useState("");
  const controlGroup: TControlGroupState = useSelector<
    IAppState,
    TControlGroupState
  >((state: IAppState) => state.controlGroupState);
  const devs: TDeviationState = useSelector<IAppState, TDeviationState>(
    (state: IAppState) => state.deviation
  );
  const dismissLoader = useSelector(
    (state: IAppState) => state.loader.dismissInstance
  );

  useEffect(() => {
    dispatch(getControlGroup(controlGroupId));
    if (controlPointId) {
      setInstances([parseInt(controlPointId)]);
    }
  }, [dispatch, controlGroupId, controlPointId]);

  useEffect(() => {
    const openInstances: Array<TOpenInstance> = controlGroup.data
      ? Object.values(controlGroup.data.openInstances).filter(
          inst => !!inst.ControlID
        )
      : [];
    const selectedInstances: Array<TOpenInstance> = instances.reduce(
      (selected, id) => {
        const open = openInstances.find(inst => inst.ID === id);
        if (open) {
          selected.push(open);
        }
        return selected;
      },
      [] as Array<TOpenInstance>
    );

    const selectedControls = selectedInstances.map(inst =>
      getControlPointInfo(
        controlGroup.data && controlGroup.data.info,
        inst.ControlID!
      )
    );

    const selectedActionSetIds = selectedControls.reduce(
      (ids, point) => {
        ids[+point.ActionSetID] = true;
        return ids;
      },
      {} as Record<number, boolean>
    );

    const setIds = Object.keys(selectedActionSetIds);
    const firstSetId = parseInt(setIds[0]);

    if (setIds.length === 1 && firstSetId > 0) {
      setActionSetId(firstSetId);
    } else {
      setActionSetId(null);
    }
  }, [dispatch, instances, controlGroup]);

  useEffect(() => {
    if (actionSetId) {
      dispatch(getDeviation(actionSetId));
    }
  }, [dispatch, actionSetId]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const instanceId = parseInt(e.target.value);
    if (e.target.checked) {
      if (!instances.includes(instanceId)) {
        setInstances([...instances, instanceId]);
      }
    } else {
      if (instances.includes(instanceId)) {
        const index = instances.indexOf(instanceId);
        const remain = [
          ...instances.slice(0, index),
          ...instances.slice(index + 1)
        ];
        setInstances(remain);
      }
    }
  };

  const handleFormSubmitAction = (e: FormEvent) => {
    e.preventDefault();
    if (instances.length < 1) {
      return;
    }

    const now = new Date();
    const data = {
      dismissTime: now.toISOString(),
      instances,
      reason
    };

    dispatch(
      dismissInstances(data, () => {
        history.push(location.state.redirectUrl);
      })
    );
  };

  const eligibleControlPoints: Array<TApiControlPointEntity> = [];

  if (controlGroup && controlGroup.data) {
    const openInstances = controlGroup.data.openInstances;
    controlGroup.data.contents.forEach(point => {
      if (point.TreeNode.ID in openInstances) {
        eligibleControlPoints.push(point);
      }
    });
  }

  if (eligibleControlPoints.length < 1) {
    return (
      <>
        <Navbar
          headerText={trans("page-dismiss-control-title")}
          backLink={() => {
            history.push(location.state.redirectUrl);
          }}
        />
        <EmptyPage text={trans("no-active-control-points")} />
      </>
    );
  }

  return (
    <>
      <Navbar
        headerText={trans("page-dismiss-control-title")}
        backLink={() => {
          history.push(location.state.redirectUrl);
        }}
      />
      <section>
        <form onSubmit={handleFormSubmitAction}>
          <div className="text-block">
            {controlGroup.isFetching ? (
              <Loading text={trans("loading-controls")} />
            ) : (
              eligibleControlPoints.map(
                (controlPoint: TApiControlPointEntity) => {
                  const controlPointInfo = getControlPointInfo(
                    controlGroup.data && controlGroup.data.info,
                    controlPoint.TreeNode.ID
                  );
                  const controlPointOpenInstance = getControlPointOpenInstance(
                    controlGroup.data && controlGroup.data.openInstances,
                    controlPoint.TreeNode.ID
                  );
                  return (
                    <div
                      className="input-group"
                      key={controlPointOpenInstance.ID}
                    >
                      <input
                        type="checkbox"
                        id={"cp-" + controlPointOpenInstance.ID}
                        onChange={handleCheckboxChange}
                        value={controlPointOpenInstance.ID}
                        checked={instances.includes(
                          controlPointOpenInstance.ID
                        )}
                      />
                      <label
                        className="ml-1"
                        htmlFor={"cp-" + controlPointOpenInstance.ID}
                      >
                        {controlPointOpenInstance.ForcedTitle
                          ? controlPointOpenInstance.ForcedTitle
                          : controlPointInfo.TreeNode.Name}
                      </label>
                    </div>
                  );
                }
              )
            )}
          </div>
          <div className="text-block">
            <label htmlFor={"reason"}>{trans("input-dismiss-reason")}</label>
            {actionSetId && devs && devs.isFetching ? (
              <Loading text={trans("global-loading")} />
            ) : (
              actionSetId &&
              devs.data &&
              devs.data.deviation && (
                <select
                  onChange={e => setReason(e.target.value)}
                  className="mt-2 mb-2"
                >
                  <option value="">
                    {trans("input-select-failure-reason")}
                  </option>
                  {devs.data.deviation.FailureReasons.map(reason => {
                    return (
                      <option key={reason.ID} value={reason.Text}>
                        {reason.Text}
                      </option>
                    );
                  })}
                </select>
              )
            )}

            <textarea
              name="reason"
              id="reason"
              className="textarea"
              placeholder={trans("input-enter-dismiss-reason")}
              onChange={e => setReason(e.target.value)}
              value={reason}
              required
            />
          </div>
          <div className="d-flex justify--center">
            <button
              type="submit"
              className="btn btn-primary btn-small mt-3"
              disabled={dismissLoader.isFetching || instances.length < 1}
            >
              {trans("input-save-dismiss-instances")}
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

export default Dismiss;
