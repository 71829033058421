import React from "react";
import Submit from "./Submit";
import {trans} from "../../../generic/Helpers";

const SubmitYesNo = ({ onClick, completed, disabled = false }: any) => {
  if (completed) {
    return <Submit completed />;
  }
  return (
    <div className="buttons-block">
      <div className="button__wrapper button-left">
        <button
          disabled={disabled}
          className="btn-yes"
          onClick={() => onClick(true)}
          type="submit"
        >
            {trans("global-yes")}
        </button>
      </div>
      <div className="button__wrapper button-right">
        <button
          disabled={disabled}
          className="btn-no"
          onClick={() => onClick(false)}
          type="submit"
        >
            {trans("global-no")}
        </button>
      </div>
    </div>
  );
};

export default SubmitYesNo;
