import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 150
    },
    cardHeader: {},
    title: {
      fontSize: 12,
      overflow: "hidden",
      width: "100%"
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    deleteIcon: {
      marginLeft: "auto",
      color: red[500],
      padding: 0
    }
  })
);

interface ImageCardProps {
  title?: string;
  src: string;
  handleDeleteClick: any; // TODO: add props
  disabled?: boolean;
}

export default function ImageCard({
  title = "",
  src,
  handleDeleteClick,
  disabled
}: ImageCardProps) {
  const classes = useStyles({});
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={src} title={title} />
      <CardActions disableSpacing>
        <Typography
          className={classes.title}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {title}
        </Typography>
        <IconButton
          onClick={handleDeleteClick}
          className={classes.deleteIcon}
          aria-label="add to favorites"
          disabled={disabled}
        >
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
