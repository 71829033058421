import { PerformInstance } from "../types/Requests";
import { SnackbarTypes, addSnackbar } from "../reducers/SnackbarsReducer";

const actionStatusMessages = {
  [PerformInstance.SUCCESS]: {
    title: `request-${PerformInstance.SUCCESS}`,
    type: SnackbarTypes.SUCCESS
  },
  [PerformInstance.FAILED]: {
    title: `request-${PerformInstance.FAILED}`,
    type: SnackbarTypes.ERROR
  }
};

const showMessage = (message: { title: string; type: string }, dispatch: any) =>
  dispatch(
    addSnackbar({
      title: message.title,
      type: message.type
    })
  );

export const requestStatusInformer = (store: any) => (next: any) => (
  action: any
) => {
  const type: keyof typeof actionStatusMessages = action.type;
  const actionStatusMessage = actionStatusMessages[type];
  if (actionStatusMessage) {
    showMessage(actionStatusMessage, store.dispatch);
  }
  return next(action);
};
