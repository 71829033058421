import * as React from "react";
import { formatAsDate, trans } from "../../generic/Helpers";
import ReactDatePicker from "react-datepicker";
import { FormEvent, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  clearReportData,
  getReportProgress,
  initReport
} from "../../actions/SipActions";
import { IAuthState } from "../../types/Auth";
import { IAppState } from "../../store/Store";
import Loading from "../Loading";

interface IReportProps extends RouteComponentProps<any> {}

const Report: React.FC<IReportProps> = ({
  match: {
    params: { id, type }
  },
  history
}): JSX.Element => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [startDate, setStartDate] = useState<any>(firstDay);
  const [endDate, setEndDate] = useState<any>(date);
  const dispatch = useDispatch<Dispatch<any>>();
  const maxDate = new Date();
  const reportInitLoader = useSelector<IAppState, any> (
    (state: IAppState) => state.loader.reportInit
  )

  useEffect(
    () => {
      dispatch(clearReportData());
    },
    [dispatch, id]
  );

  const auth: IAuthState = useSelector<IAppState, IAuthState>(
    (state: IAppState) => state.authState
  );

  const completed =
    !!auth.reportGuid && !!auth.reportProgress && auth.reportProgress >= 100;

  useEffect(
    () => {
      if (auth.reportGuid && !completed) {
        setTimeout(() => {
          dispatch(getReportProgress(auth.reportGuid));
        }, 2000);
      }
    },
    [auth.reportProgress, auth.reportGuid, dispatch, completed]
  );

  const handleStartChange = (date: Date) => {
    let maxEndDate = new Date(date.getTime());
    maxEndDate.setDate(1);
    maxEndDate.setMonth(maxEndDate.getMonth() + 2);
    maxEndDate.setDate(0);

    setStartDate(date);
    if (endDate > maxEndDate) {
      setEndDate(maxEndDate);
    }
  };

  const handleEndChange = (date: Date) => {
    let minStartDate = new Date(date.getTime());
    minStartDate.setDate(1);
    minStartDate.setMonth(minStartDate.getMonth() - 1);

    setEndDate(date);
    if (startDate < minStartDate) {
      setStartDate(minStartDate);
    }
  };

  const initReportAction = (e: FormEvent) => {
    e.preventDefault();
    const config = {
      FolderID: id,
      ReportConfig: {
        Status: {
          2: 1,
          3: 1,
          4: 1,
          5: 1
        },
        Format: "html"
      },
      StartDate: formatAsDate(startDate),
      EndDate: formatAsDate(endDate)
    };
    dispatch(initReport(config));
  };

  return (
    <>
      <Navbar
        headerText={trans("page-report-title")}
        backLink={() => {
          history.goBack();
        }}
      />
      <section>
        {!auth.reportGuid && (
          <form
            className="form-group form-vertical reminder-form"
            method="POST"
            onSubmit={initReportAction}
          >
            <div className="text-block">
              <label htmlFor="start">{trans("report-start-date")}</label>
              <div className="input-group mb-3">
                <ReactDatePicker
                  selected={startDate}
                  onChange={handleStartChange}
                  dateFormat="yyyy-MM-dd"
                  timeCaption="time"
                  maxDate={maxDate}
                  className="inp"
                  name="StartDate"
                />
              </div>
              <label htmlFor="end">{trans("report-end-date")}</label>
              <div className="input-group mb-3">
                <ReactDatePicker
                  selected={endDate}
                  onChange={handleEndChange}
                  dateFormat="yyyy-MM-dd"
                  timeCaption="time"
                  maxDate={maxDate}
                  className="inp"
                  name="EndDate"
                />
              </div>
              <div className="d-flex justify--center">
                <button
                  disabled={reportInitLoader.isFetching}
                  type="submit"
                  className="btn btn-primary btn-small mt-3"
                >
                  {trans("input-get-report")}
                </button>
              </div>
            </div>
          </form>
        )}

        {auth.reportGuid && !completed && (
          <Loading
            text={`${trans("global-loading")}: ${
              auth.reportProgress ? auth.reportProgress : 0
            }%`}
          />
        )}

        {completed && auth.reportProgress && (
          <>
            <div className="text-block">{trans("report-loaded")}</div>
            <div className="input-group mt-3 d-flex justify--center">
              <a
                href={auth.reportUrl ? auth.reportUrl : "#"}
                target="_blank"
                className="btn btn-small btn-primary"
                rel="noopener noreferrer"
              >
                {trans("report-view")}
              </a>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default Report;
