import React from "react";

const Title = ({ title = '' }: { title: string }) => (
  <div className="control-point__requirements">
    <div className="text-center">
      <p>{title}</p>
    </div>
  </div>
);

export default Title;
