import * as React from "react";
import { useEffect } from "react";
import { TSipState } from "../../types/Sip";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/Store";
import {
  TGeoLockState,
  TGeoPermissionsState,
  TGeoReadingFailureState,
  TGeoReadingSuccessState
} from "../../types/GeoLock";
import { trans } from "../../generic/Helpers";
import { Dispatch } from "redux";
import {
  checkGeolocationPermissions,
  clearGeolocation,
  saveGeolocationPermissions,
  startGeolocation
} from "../../actions/SipActions";
import Loading from "../Loading";
import { createGeofenceFromSip } from "../../generic/GeoFencing";
import { RouteComponentProps, withRouter } from "react-router";
import SipGeoLockError from "./SipGeoLockError";

interface ISipGeoLockProps extends RouteComponentProps<any> {
  sip: TSipState;
}

const SipGeoLock: React.FC<ISipGeoLockProps> = ({ sip, children, history }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  useEffect(() => {
    dispatch(clearGeolocation());
    dispatch(checkGeolocationPermissions());
    return () => {
      dispatch(clearGeolocation());
    };
  }, [dispatch, sip]);

  const geoState = useSelector<IAppState, TGeoLockState>(
    (state: IAppState) => state.geoLock
  );

  const bypassLock = () => <>{children}</>;

  if (
    sip.data &&
    (!sip.data.node.GpsLock || !sip.data.node.GpsLock.LockEnabled)
  ) {
    return bypassLock();
  }

  const triggerGeolocation = () => {
    const geofence = createGeofenceFromSip(sip!.data!.node.GpsLock!);
    dispatch(startGeolocation(geofence!));
  };

  const goBack = () => {
    history.goBack();
  };

  if (
    !geoState ||
    geoState.status === undefined ||
    geoState.status === "checkingPermissions"
  ) {
    return <Loading text={trans("global-loading")} />;
  }

  if (geoState.status === "checkedPermissions") {
    switch (geoState.permission) {
      case TGeoPermissionsState.GRANTED:
        triggerGeolocation();
        return <Loading text={trans("global-loading")} />;

      case TGeoPermissionsState.DENIED:
        const alertTitle = trans("message-geolocation-denied");
        const errorExplanation = trans("message-geolocation-denied-longtext");
        const canRetry = true;

        return (
          <SipGeoLockError
            alertTitle={alertTitle}
            errorExplanation={errorExplanation}
            canRetry={canRetry}
            tryAgain={triggerGeolocation}
            goBack={goBack}
          />
        );

      case TGeoPermissionsState.NOT_SET:
      default:
        return (
          <>
            <h3>{trans("geolocation-required")}</h3>
            <p>{trans("message-geolocation-required-longtext")}</p>
            <div className="actions-bar">
              <div className="actions-bar-item">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={triggerGeolocation}
                >
                  {trans("input-geolocation-proceed")}
                </button>
              </div>
            </div>
          </>
        );
    }
  }

  if (geoState.status === "querying") {
    return <Loading text={trans("global-loading")} />;
  }

  if (geoState.status === "queried") {
    saveGeolocationPermissions(TGeoPermissionsState.GRANTED);

    switch (geoState.queriedState) {
      case TGeoReadingSuccessState.RANGE_NOT_SET:
      case TGeoReadingSuccessState.WITHIN_RANGE:
        return bypassLock();

      case TGeoReadingSuccessState.OUT_OF_RANGE:
        const alertTitle = trans("message-geolocation-outside-range");
        return (
          <SipGeoLockError
            alertTitle={alertTitle}
            canRetry={true}
            tryAgain={triggerGeolocation}
            goBack={goBack}
          />
        );

      default:
        return <></>;
    }
  }

  if (geoState.status === "queryFailed") {
    let alertTitle: string;
    let errorExplanation: string;
    let canRetry: boolean;

    switch (geoState.error) {
      case TGeoReadingFailureState.GEOLOCATION_UNAVAILABLE:
        alertTitle = trans("message-geolocation-unavailable");
        errorExplanation = trans("message-geolocation-unavailable-longtext");
        canRetry = true;
        break;

      case TGeoReadingFailureState.PERMISSION_DENIED:
        saveGeolocationPermissions(TGeoPermissionsState.DENIED);
        alertTitle = trans("message-geolocation-denied");
        errorExplanation = trans("message-geolocation-denied-longtext");
        canRetry = true;

        break;

      case TGeoReadingFailureState.POSITION_UNAVAILABLE:
        alertTitle = trans("message-geolocation-position-unavailable");
        errorExplanation = trans(
          "message-geolocation-position-unavailable-longtext"
        );
        canRetry = true;
        break;

      case TGeoReadingFailureState.TIMEOUT:
        alertTitle = trans("message-geolocation-timeout");
        errorExplanation = trans("message-geolocation-timeout-longtext");
        canRetry = true;
        break;

      default:
        alertTitle = trans("global-error");
        errorExplanation = geoState.error;
        canRetry = true;
    }

    return (
      <SipGeoLockError
        alertTitle={alertTitle}
        errorExplanation={errorExplanation}
        canRetry={canRetry}
        tryAgain={triggerGeolocation}
        goBack={goBack}
      />
    );
  }

  // this should not be reachable
  debugger;
  return <></>;
};

export default withRouter(SipGeoLock);
