import * as React from "react";

type IInputErrorProps = {
    visible?: boolean;
    message: string;
};

const InputError: React.FC<IInputErrorProps> = (props): JSX.Element => {
    let {visible, message} = props;
    if (typeof visible === "undefined") {
        visible = !!message;
    }

    return <div className={["inp-error", visible ? 'inp-error--visible' : ''].join(' ')}>
        {message}
    </div>
    ;
};

export default InputError;
