import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { FormEvent, useEffect, useState } from "react";
import {
  getAuditConfig,
  getAuditSites,
  startAudit
} from "../../actions/AuditActions";
import Navbar from "../nav/Navbar";
import { IAuditSite, IAuditState } from "../../types/Audit";
import { IAppState } from "../../store/Store";
import ReactDatePicker from "react-datepicker";
import { setLocalStorage, trans } from "../../generic/Helpers";
import { generateUrl } from "../../generic/Requests";
import { useRequest } from "../../hooks/useRequest";
import Alert from "../Alert";
import { clearErrors } from "../../actions/AuthActions";

interface IDetailsProps extends RouteComponentProps<any> {}

const Details: React.FC<IDetailsProps> = ({
  match: {
    params: { customerId, sipId }
  },
  history
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const audit: IAuditState = useSelector<IAppState, IAuditState>(
    (state: IAppState) => state.audit
  );
  const [customer, setCustomer] = useState("");
  const [date, setDate] = useState<any>(new Date());
  const [site, setSite] = useState("");
  const { app } = useRequest();

  useEffect(() => {
    dispatch(getAuditSites(sipId));
  }, [dispatch, sipId]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const data = {
      auditTime: date,
      representative: customer,
      sip: sipId,
      site
    };
    dispatch(clearErrors());
    dispatch(
      startAudit(data, () => {
        setLocalStorage("currentAuditId", sipId);
        dispatch(getAuditConfig(sipId));
      })
    );
  };

  useEffect(() => {
    if (audit.data && audit.data.info && audit.data.info.UniqueID) {
      return history.push(
        generateUrl(
          customerId,
          sipId,
          undefined,
          `audit/${audit.data.info.UniqueID}`
        )
      );
    }
  }, [audit]);

  return (
    <>
      <Navbar headerText={trans("page-audit-details-title")} />
      {app.hasError() && (
        <Alert type={"danger"} text={app.getError()} error={true} />
      )}
      <form className="form-group form-vertical" onSubmit={handleFormSubmit}>
        <div className="text-block">
          <label htmlFor="sites">
            {trans("audit-select-site-input-label")}
          </label>
          <div className="input-group mb-3">
            <select
              name="sites"
              id="sites"
              required={true}
              onChange={e => setSite(e.target.value)}
            >
              <option value="">{trans("audit-please-select-site")}</option>
              {audit.data &&
                audit.data.sites &&
                audit.data.sites.map((site: IAuditSite) => {
                  return (
                    <option value={site.ID} key={site.ID}>
                      ({site.Identifier}) {site.Name} - {site.City},{" "}
                      {site.Address}
                    </option>
                  );
                })}
            </select>
          </div>
          <label htmlFor="customer">
            {trans("audit-customer-input-label")}
          </label>
          <div className="input-group mb-3">
            <input
              className="inp"
              type="text"
              id="customer"
              value={customer}
              onChange={e => setCustomer(e.target.value)}
            />
          </div>
          <label htmlFor="date">{trans("audit-select-audit-date-label")}</label>
          <div className="input-group">
            <ReactDatePicker
              selected={date}
              onChange={(e: Date | null) => setDate(e)}
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeSelect={true}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              className="inp"
              required={true}
            />
          </div>
        </div>
        <div className="input-group mt-3 d-flex justify--center">
          <button className="btn btn-small btn-primary">
            {trans("proceed-to-performing-the-controls")}
          </button>
        </div>
      </form>
    </>
  );
};

export default Details;
