import { useDispatch, useSelector } from "react-redux";

import {
  setFormFields,
  setForm,
  setStartDate
} from "../reducers/RepeatControlGroupReducer";

// TODO: convert to ts
export const useRepeatControlGroupForm = () => {
  const dispatch = useDispatch();
  const { forms, ids, startDate, dateSelected } = useSelector(
    state => state.repeatControlGroupForms
  );

  return {
    forms,
    ids,
    dateSelected,
    startDate,
    setForm: (formId, form) => dispatch(setForm(formId, form)),
    setFormFields: (formId, value) => dispatch(setFormFields(formId, value)),
    setStartDate: date => dispatch(setStartDate(date))
  };
};
