import { Reducer } from "redux";
import {
  GeoLock,
  initialGeoLockState,
  TGeoLockAction,
  TGeoLockState,
  TGeoReadingFailureState
} from "../types/GeoLock";
import {endReducer} from "../store/Store";

export const geoLockReducer: Reducer<TGeoLockState, TGeoLockAction> = (
  state = initialGeoLockState,
  action
) => {
  switch (action.type) {
    case GeoLock.CLEAR: {
      return {
        status: undefined
      };
    }

    case GeoLock.CHECK_PERMISSIONS: {
      return {
        status: "checkingPermissions"
      };
    }

    case GeoLock.RESOLVE_PERMISSIONS: {
      return {
        status: "checkedPermissions",
        permission: action.payload
      };
    }

    case GeoLock.REQUEST: {
      return {
        status: "querying"
      };
    }

    case GeoLock.SUCCESS: {
      return {
        status: "queried",
        queriedState: action.payload
      };
    }

    case GeoLock.FAILURE: {
      const err = action.payload;
      let error: string | null = null;
      if (err && err.toString() === "[object PositionError]") {
        const errPos = (err as any) as PositionError;
        switch (errPos.code) {
          case errPos.PERMISSION_DENIED:
            error = TGeoReadingFailureState.PERMISSION_DENIED;
            break;

          case errPos.POSITION_UNAVAILABLE:
            error = TGeoReadingFailureState.POSITION_UNAVAILABLE;
            break;

          case errPos.TIMEOUT:
            error = TGeoReadingFailureState.TIMEOUT;
            break;
        }
      }

      if (!error) {
        try {
          error = (err as any).message;
        } catch (e) {
          error = String(err);
        }
      }

      return {
        status: "queryFailed",
        error: error!
      };
    }

    default:
      return endReducer(state, action);
  }
};
