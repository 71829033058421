import * as React from "react";
import { isEmpty } from "lodash";
import {
  TOpenInstance
} from "../../../../types/ControlPoint";
import {
  controlPointControls,
  generateStandaloneReminderInstance
} from "../../../../generic/Helpers";
import ListItem from "../../../ListItem";
import ControlPointBase from "../../../control-point/ControlPointBase";
import { RouteComponentProps, withRouter } from "react-router";

interface IStandaloneReminderInstanceProps extends RouteComponentProps<any> {
  instance: TOpenInstance;
  openByDefault?: boolean;
  hideContextMenu?: boolean;
}

const StandaloneReminderInstance: React.FC<
  IStandaloneReminderInstanceProps
> = ({
  instance,
  openByDefault,
  hideContextMenu,
  match: {
    params: { controlGroupId }
  }
}): JSX.Element => {
  const pointInstance = generateStandaloneReminderInstance(
    instance
  );

  const contextItems = controlPointControls(
    instance,
    controlGroupId,
    undefined
  );

  return (
    <ListItem
      key={instance.ID}
      title={instance.ForcedTitle}
      detail={instance.ForcedNotes || ""}
      itemType={"control-point"}
      id={instance.ID}
      settings={!hideContextMenu && !isEmpty(contextItems)}
      openByDefault={openByDefault}
      controls={contextItems}
      content={<ControlPointBase instance={pointInstance} />}
    />
  );
};

export default withRouter(StandaloneReminderInstance);
