import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  DeepPartial,
  Store
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { Authenticate, IAuthState } from "../types/Auth";
import { authReducer } from "../reducers/AuthReducer";
import { removeLocalStorage } from "../generic/Helpers";
import { TCustomerListState, TCustomerState } from "../types/Customer";
import { customerListReducer } from "../reducers/CustomerListReducer";
import { customerReducer } from "../reducers/CustomerReducer";
import { TSipControlGroupsState, TSipListState, TSipState } from "../types/Sip";
import { sipListReducer } from "../reducers/SipListReducer";
import { sipReducer } from "../reducers/SipReducer";
import { TControlGroupState } from "../types/ControlGroup";
import { controlGroupReducer } from "../reducers/ControlGroupReducer";
import { TDocState } from "../types/Documentation";
import { docReducer } from "../reducers/DocReducer";
import { TDeviationState } from "../types/Deviation";
import { deviationReducer } from "../reducers/DeviationReducer";
import { TTranslationState } from "../types/Translation";
import { translationReducer } from "../reducers/TranslationsReducer";
import { IAuditState } from "../types/Audit";
import { auditReducer } from "../reducers/AuditReducer";
import { sipControlGroupsReducer } from "../reducers/SipControlGroupsReducer";
import { TGeoLockState } from "../types/GeoLock";
import { geoLockReducer } from "../reducers/GeoLockReducer";
import { offlineReducer, TOfflineState } from "../reducers/OfflineReducer";
import { requestStatusInformer } from "../middlewares/requestStatusInformer";
import loaderReducer from "../reducers/LoaderReducer";
import { snackbarReducer, TSnackbarsState } from "../reducers/SnackbarsReducer";
import { repeatControlGroupReducer } from "../reducers/RepeatControlGroupReducer";
import { requestReducer } from "../reducers/RequestReducer";

export interface IAppState {
  authState: IAuthState;
  customerState: TCustomerState;
  customerListState: TCustomerListState;
  sipListState: TSipListState;
  sipState: TSipState;
  sipControlGroupsState: TSipControlGroupsState;
  controlGroupState: TControlGroupState;
  documentation: TDocState;
  deviation: TDeviationState;
  translations: TTranslationState;
  audit: IAuditState;
  geoLock: TGeoLockState;
  offline: TOfflineState;
  snackbars: TSnackbarsState;
  loader: any; // TODO: add types
  repeatControlGroupForms: any;
  request: any;
}

const initialAppState: DeepPartial<IAppState> = {
  authState: undefined,
  customerState: undefined,
  customerListState: undefined,
  sipListState: undefined,
  sipState: undefined,
  sipControlGroupsState: undefined,
  controlGroupState: undefined,
  documentation: undefined,
  deviation: undefined,
  translations: undefined,
  audit: undefined,
  geoLock: undefined,
  offline: undefined,
  snackbars: undefined,
  loader: undefined,
  repeatControlGroupForms: undefined,
  request: undefined
};

const appReducer = combineReducers<IAppState>({
  authState: authReducer,
  customerState: customerReducer,
  customerListState: customerListReducer,
  sipListState: sipListReducer,
  sipState: sipReducer,
  sipControlGroupsState: sipControlGroupsReducer,
  controlGroupState: controlGroupReducer,
  documentation: docReducer,
  deviation: deviationReducer,
  translations: translationReducer,
  audit: auditReducer,
  geoLock: geoLockReducer,
  offline: offlineReducer,
  snackbars: snackbarReducer,
  loader: loaderReducer(),
  repeatControlGroupForms: repeatControlGroupReducer,
  request: requestReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === Authenticate.LOGOUT) {
    removeLocalStorage("token");
    state = initialAppState;
  }

  return appReducer(state, action);
};

export function endReducer<T>(this: void, state: T, action: never): T {
  return state;
}

export default function configureStore(): Store<IAppState, AnyAction> {
  return createStore(
    rootReducer,
    initialAppState,
    composeWithDevTools(applyMiddleware(thunk, requestStatusInformer))
  );
}
