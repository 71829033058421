import React, { useState } from "react";
import {
  TControlPointInstance,
  TControlPointAction,
  TControlPointData,
  TControlPointFormBase
} from "../../../types/ControlPoint";
import ControlPointForm from "../../forms/ControlPoint";
import getPerformStatus from "../../../helpers/components/forms/controlPoint/getPerformStatus";
import Loading from "../../Loading";
import { ControlPointType } from "../../../generic/Config";
import moment from "moment";
import { isEmpty } from "lodash";

interface IDefaultControlPointFormProps {
  instance: TControlPointInstance<TControlPointFormBase>;
  handleControlPointAction: TControlPointAction;
}

const DefaultControlPointForm = (props: IDefaultControlPointFormProps) => {
  const { instance } = props;

  const [formFields, setFormFields] = useState({
    userInput:
      instance.ControlType === ControlPointType.DATE
        ? moment(new Date()).format("YYYY-MM-DD")
        : "",
    userNotes: "",
    customFields: instance.CustomFields || [],
    photoMappings: [],
    isSameAsLastAudit: false,
    sameAsLastAuditNotes: ""
  });

  const [completed, setCompleted] = useState(false);

  const handleSetFormsFields = (fields: any) =>
    setFormFields({ ...formFields, ...fields });

  const handleControlAction = () => {
    // TODO: pass form data directly,
    // now we reformat correct data only
    const data: TControlPointData = {
      notes: formFields.userNotes,
      value: formFields.userInput,
      conditionIsTrue: getPerformStatus(
        instance.ControlType,
        instance.ConditionDetails,
        formFields.userInput
      ),
      customFields: formFields.customFields
        .map(({ Name: title, value }: any) => ({ title, value }))
        .filter(
          // TODO: make more readable
          // if not required and input is empty, we filter this out
          (customField: any) => !!customField.value
        ),
      photos: formFields.photoMappings.map(({ photoId }: any) => ({
        photoId
      })),
      additionalData: !!formFields.sameAsLastAuditNotes
        ? {
            isSameAsLastAudit: formFields.isSameAsLastAudit,
            sameAsLastAuditNotes: formFields.sameAsLastAuditNotes
          }
        : {}
    };

    return props.handleControlPointAction(data);
  };

  const handleSubmit = (formFields: any) => {
    if (instance.Audit && completed) {
      return setCompleted(false);
    }
    if (instance.Audit && !completed) {
      setCompleted(true);
      return handleControlAction();
    }

    return handleControlAction();
  };

  // TODO: fix the logic to render component in a parent or container component
  if (isEmpty(instance)) {
    return <Loading />;
  }
  if (!isEmpty(instance)) {
    return (
      <ControlPointForm
        formFields={formFields}
        setFormFields={handleSetFormsFields}
        controlPoint={instance}
        onSubmit={handleSubmit}
        completed={completed}
      />
    );
  }
  return <></>;
};

export default DefaultControlPointForm;
