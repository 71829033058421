import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { trans } from "../../generic/Helpers";

interface IDatePickerProps {
  selectedDate: Date;
  handleDateChange: any;
  disabled?: boolean;
}

const MaterialDatePicker: React.FC<IDatePickerProps> = ({
  selectedDate,
  handleDateChange,
  disabled = false
}) => {
  return (
    <KeyboardDatePicker
      margin="normal"
      id="date-picker-dialog"
      label={trans("mui-select-date")}
      format="YYYY-MM-DD"
      value={selectedDate}
      onChange={handleDateChange}
      disabled={disabled}
      KeyboardButtonProps={{
        "aria-label": "change date"
      }}
      className="mui-date-picker form-control__input"
    />
  );
};

export default MaterialDatePicker;
