import { AsyncThunkAction } from "../types/Requests";
import { apiCall, asyncRequest } from "../generic/Requests";
import {
  ControlGroup,
  TGetControlGroupAction,
  TControlGroupState,
  TApiControlGroupContent
} from "../types/ControlGroup";
import {
  GetDropdown,
  TApiDropdown,
  TGetDropdownAction
} from "../types/Dropdown";
import {
  DocumentationGet,
  TApiDocumentation,
  TDocState,
  TGetDocumentationAction
} from "../types/Documentation";

export const getControlGroup = (
  id: number,
  callback?: (...args: any) => any
): AsyncThunkAction<TControlGroupState, TGetControlGroupAction> => {
  return asyncRequest(
    ControlGroup,
    apiCall<null, TApiControlGroupContent>(
      "GET",
      `/controlGroup/${id}`,
      null,
      true
    ),
    callback
  );
};

export const getDropdown = (
  id: number | undefined
): AsyncThunkAction<TControlGroupState, TGetDropdownAction> => {
  return asyncRequest(
    GetDropdown,
    apiCall<null, TApiDropdown>("GET", `/dropdown/${id}`, null, true),
    null
  );
};

export const getDocumentation = (
  docId: number
): AsyncThunkAction<TDocState, TGetDocumentationAction> => {
  return asyncRequest(
    DocumentationGet,
    apiCall<null, TApiDocumentation>(
      "GET",
      `/documentation/${docId}`,
      null,
      true
    ),
    null
  );
};
