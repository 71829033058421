import { Errors } from "../types/Auth";

interface IState {
  loading: boolean;
  loaded: boolean;
  error: [];
  requestsLoading: number;
}

const initialState: IState = {
  loading: false,
  loaded: false,
  error: [],
  requestsLoading: 0
};

export const requestReducer = (state: IState = initialState, action: any) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILED)/.exec(type);

  if (!matches) return state;

  const [, , requestState] = matches;

  let req: number = isNaN(state.requestsLoading) ? 0 : state.requestsLoading;
  switch (requestState) {
    case "REQUEST":
      req++;
      break;

    default:
      req--;
      break;
  }

  let errors = [];
  if (requestState === "FAILED") {
    errors = [...state.error, payload.errorResponse.data];
  } else {
    errors = state.error;
  }

  if (type === Errors.CLEAR_ERRORS) {
    return {
      ...state,
      error: []
    };
  } else {
    return {
      ...state,
      requestsLoading: req > 0 ? req : 0,
      loading: errors.length < 1 && req > 0,
      loaded: errors.length > 0 || req < 1,
      error: errors
    };
  }
};
