import {
  TCircularGeofence,
  TGeoPosition,
  TGeoReadingSuccessState
} from "../types/GeoLock";
import { getDistanceBetweenLatLon } from "./Geography";
import { TSipGpsLock } from "../types/Sip";

const cacheAge = 600; //10 minutes in seconds

export function createGeofenceFromSip(
  sipGpsLockInfo: TSipGpsLock
): TCircularGeofence | null {
  if (!sipGpsLockInfo.LockEnabled) {
    return null;
  }

  return {
    center: {
      latitude: sipGpsLockInfo.Lat,
      longitude: sipGpsLockInfo.Lng
    },
    radius: sipGpsLockInfo.Distance
  };
}

export function validateGeoFencing(
  geofence: TCircularGeofence
): Promise<TGeoReadingSuccessState> {
  return getCurrentPosition().then(
    current => {
      if (current === null) {
        return Promise.resolve(TGeoReadingSuccessState.OUT_OF_RANGE);
      } else {
        const withinRange = positionWithinRangeOf(geofence, current);
        return Promise.resolve(
          withinRange
            ? TGeoReadingSuccessState.WITHIN_RANGE
            : TGeoReadingSuccessState.OUT_OF_RANGE
        );
      }
    },
    err => {
      return Promise.reject(err);
    }
  );
}

function getCurrentPosition(): Promise<TGeoPosition | null> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      error => {
        reject(error);
      },
      {
        maximumAge: cacheAge,
        timeout: 10000,
        enableHighAccuracy: false
      }
    );
  });
}

function positionWithinRangeOf(
  geofence: TCircularGeofence,
  current: TGeoPosition
): boolean {
  const currentDistance = getDistanceBetweenLatLon(
    current.latitude,
    current.longitude,
    geofence.center.latitude,
    geofence.center.longitude
  );
  return currentDistance <= geofence.radius;
}
