import React from "react";

interface IControlInstaceTimeProps {
  days: string | number | boolean;
  hours: string | number | boolean;
  minutes: string | number | boolean;
  color: string | number | boolean; // TODO: fix typechecking since it shouldn't expect numbers
  hasEnded: string | number | boolean;
}

const TimeView = ({
  days,
  hours,
  minutes,
  color,
  hasEnded
}: IControlInstaceTimeProps) => {
  if (hasEnded) {
    return <div />;
  }
  return (
    <div
      style={{
        backgroundColor: `#${color}`,
        borderColor: `#${color}`
      }}
      className="control-instance-timer"
    >
      {days > 0 && <div className="control-instance-timer__hours">{days}</div>}
      <div className="control-instance-timer__minutes-and-seconds">
        {`${hours}:${minutes}`}
      </div>
    </div>
  );
};

export default TimeView;
