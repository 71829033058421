import { AxiosResponse } from "axios";
import { TApiControlGroupEntity } from "./ControlGroup";
import { TApiTreeNodeEntityInSip, TApiTreeNodeEntityIsSip, TNodeTypeIdentifier} from "./Tree";

export interface TApiSipEntity {
  ClosePeriods: [] | [TApiSipClosePeriod];
  Auditable: boolean;
  DisplayDemand: boolean;
  GpsLock: TSipGpsLock | null;
  NodeTypeIdentifier: TNodeTypeIdentifier.SIP;
  TreeNode: TApiTreeNodeEntityIsSip;
  UseInstanceWarningTimes: boolean;
  WarnDailyWorkFinished: boolean;
}

export interface TApiSipClosePeriod {
  ClosedFrom: Date;
  ClosedTo: Date;
  Notes: string|null;
}
export type TSipGpsLock = {
  LockEnabled: true;
  Lat: number;
  Lng: number;
  Distance: number;
};

export interface TApiSipList {
  documentation: Record<number, number>;
  remainingWork: Record<number, number>;
  sips: TApiSipEntity[];
}

export interface TSipListState {
  readonly isFetching?: boolean;
  readonly data?: TApiSipList;
}

export const initialSipListState: TSipListState = {
  isFetching: false,
  data: undefined
};

export enum SipList {
  REQUEST = "SIP_LIST_REQUEST",
  SUCCESS = "SIP_LIST_SUCCESS",
  FAILED = "SIP_LIST_FAILED"
}

export interface TApiFolderEntity {
  NodeTypeIdentifier: TNodeTypeIdentifier.FOLDER;
  TreeNode: TApiTreeNodeEntityInSip;
}

export type TGetSipListAction = {
  type: SipList.REQUEST;
} | {
  type: SipList.SUCCESS;
  payload: AxiosResponse<TApiSipList>;
}|{
  type: SipList.FAILED;
  payload: AxiosResponse;
}

export type TApiSipDirectContent = TApiControlGroupEntity | TApiFolderEntity;

export interface TApiSip {
  contents: Array<TApiSipDirectContent>;
  documentation: Record<number, number>;
  node: TApiSipEntity;
  remainingWork: Record<number, number>;
}

export interface TSipState {
  readonly isFetching?: boolean;
  readonly data?: TApiSip;
}

export const initialSipState: TSipState = {
  isFetching: false,
  data: undefined
};

export enum Sip {
  REQUEST = "SIP_REQUEST",
  SUCCESS = "SIP_SUCCESS",
  FAILED = "SIP_FAILED"
}

export type TGetSipAction = {
  type: Sip.REQUEST;
} |
{
  type:  Sip.SUCCESS;
  payload: AxiosResponse<TApiSip>;
} | {
  type: Sip.FAILED;
  payload: AxiosResponse;
}

export type SipActions = TGetSipListAction | TGetSipAction;

export interface TSipControlGroupsState {
  readonly isFetching?: boolean;
  readonly data?: TApiSipControlGroups;
}

export interface TApiSipControlGroups {
  readonly controlGroups: Array<TApiControlGroupEntity>;
}

export enum SipControlGroups {
  REQUEST = "SIP_CTRLGROUPS_REQUEST",
  SUCCESS = "SIP_CTRLGROUPS_SUCCESS",
  FAILED = "SIP_CTRLGROUPS_FAILED"
}

export type TGetSipControlGroupsAction = {
  type: SipControlGroups.REQUEST;
} | {
  type: SipControlGroups.SUCCESS;
  payload: AxiosResponse<TApiSipControlGroups>;
} | {
  type: SipControlGroups.FAILED;
  payload: AxiosResponse;
}

export const initialSipControlGroupsState: TSipControlGroupsState = {
  isFetching: false,
  data: undefined
};
