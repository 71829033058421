import * as React from "react";

interface ILoadingProps {
  text?: string;
}

const Loading: React.FC<ILoadingProps> = (props): JSX.Element => {
  const { text } = props;

  return (
    <div className="lds-loading-container">
      <div className="lds-dual-ring" />
      {text && <span className="loading-text">{text}</span>}
    </div>
  );
};

export default Loading;
