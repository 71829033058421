import {Reducer} from "redux";
import {initialSipListState, SipList, TGetSipListAction, TSipListState} from "../types/Sip";
import {sortBy} from "lodash";
import {endReducer} from "../store/Store";

export const sipListReducer: Reducer<TSipListState, TGetSipListAction> = (
  state = initialSipListState,
  action
) => {
  switch (action.type) {
    case SipList.REQUEST: {
      return {
        isFetching: true
      };
    }

    case SipList.SUCCESS:
      return {
        isFetching: false,
        data: {
          ...action.payload.data,
          sips: sortBy(action.payload.data.sips, v => v.TreeNode.TreeLeft)
        }
      };

      case SipList.FAILED:
          return state;

      default:
        return endReducer(state, action);
  }
};
