let apiBaseUrl = process.env.REACT_APP_API_URL || '';
apiBaseUrl = apiBaseUrl.replace(/\/$/, '');

export const config = {
  BASE_API_URL: `${apiBaseUrl}/clientApi/v2`,
  BASE_REPORT_URL: `${apiBaseUrl}/adminApi/?r=report/folderreport`,
  DEFAULT_LANG: 'en_gb',
};

export enum ControlGroupType {
  CONTROLS_LIST = 2,
  FORMULA = 4,
  REGULAR = 1,
  WORKFLOW = 3
}

export enum TrafficLightsScale {
  FIXED_TIME = 1,
  PERCENTAGE_OPEN_TIME = 2
}

export enum ListViewMode {
  RADIO = "1",
  DROPDOWN = "2",
  CHECKBOX = "3"
}

export enum PhotoMode {
  NOT_ALLOWED = 1,
  ALLOWED = 2,
  REQUIRED = 3
}

export enum ControlPointType {
  NUMBER = 1,
  INTERVAL = 2,
  DATE = 3,
  YESNO = 4,
  SELECT_FROM_LIST = 5,
  TIMESTAMP = 6
}

export enum ControlPointNumberComparison {
  LessThanOrEqual = "LE",
  LessThan = "L",
  EqualTo = "E",
  MoreThan = "M",
  MoreThanOrEqual = "ME",

}

export enum ControlPointIntervalComparison {
  BoundsInclusive = "inclusive",
  BoundsExclusive = "exclusive",
}

export enum ApiTaskStatus {
  STATUS_PENDING = 1,
  STATUS_RUNNING = 2,
  STATUS_FINISHED = 3,
  STATUS_SKIPPED = 4,
  STATUS_FAILED = 10,
}
