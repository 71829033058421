import * as React from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import CustomerListContainer from "./containers/CustomerListContainer";
import { authenticated } from "./components/hoc/requiresAuthentication";
import SipListContainer from "./containers/SipListContainer";
import SipContainer from "./containers/SipContainer";
import ControlGroupContainer from "./containers/ControlGroupContainer";
import DocContainer from "./containers/DocContainer";
import About from "./components/pages/About";
import Deviation from "./components/control-point/Deviation";
import Reminder from "./components/control-point/Reminder";
import Settings from "./components/pages/Settings";
import AdHoc from "./components/pages/AdHoc";
import repeatControlPoint from "./components/adHoc/RepeatControlPoint";
import AdHocFromControlPoint from "./components/adHoc/AdHocFromControlPoint";
import Dismiss from "./components/pages/Dismiss";
import Details from "./components/audit/Details";
import Perform from "./components/audit/Perform";
import Repeat from "./components/pages/Repeat";
import Report from "./components/pages/Report";
import AdHocFromControlGroup from "./components/adHoc/AdHocFromControlGroup";
import PasswordChange from "./components/pages/PasswordChange";
import requiresAcceptTOS from "./components/hoc/requiresAcceptTOS";
import Review from "./components/audit/Review";
import Notes from "./components/audit/Notes";
import Results from "./components/audit/Results";
import Success from "./components/audit/Success";

interface IRoutesProps extends RouteComponentProps<any> {}

const AuthRoutes: React.FC<IRoutesProps> = (): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        key="customers"
        path={"/app"}
        component={CustomerListContainer}
      />
      <Route
        exact
        key="sip-list"
        path={`/app/customer/:customerId`}
        component={SipListContainer}
      />
      <Route
        exact
        key="sip-list"
        path={`/app/customer/:customerId/sip/:sipId`}
        component={SipContainer}
      />
      <Route
        exact
        key="control-group"
        path={`/app/customer/:customerId/sip/:sipId/control-group/:controlGroupId`}
        component={ControlGroupContainer}
      />
      <Route
        exact
        key="reminder-deviation"
        path={`/app/customer/:customerId/sip/:sipId/control-group/:controlGroupId/deviation`}
        component={Deviation}
      />
      <Route
        exact
        key="control-point-deviation"
        path={`/app/customer/:customerId/sip/:sipId/control-group/:controlGroupId/deviation/:controlPointId`}
        component={Deviation}
      />
      <Route
        exact
        key="control-point-reminder"
        path={`/app/customer/:customerId/sip/:sipId/control-group/:controlGroupId/reminder/:controlPointId`}
        component={Reminder}
      />
      <Route
        exact
        key="control-group-reminder"
        path={`/app/customer/:customerId/sip/:sipId/control-group/:controlGroupId/reminder`}
        component={Reminder}
      />
      <Route
        exact
        key="control-group"
        path={`/app/customer/:customerId/sip/:sipId/control-group/:controlGroupId/repeat`}
        component={Repeat}
      />
      <Route
        exact
        key="documentation"
        path={`/app/doc/:docId`}
        component={DocContainer}
      />
      <Route exact key="about" path={"/app/about"} component={About} />
      <Route exact key="about" path={"/app/settings"} component={Settings} />
      <Route
        exact
        key="sip-adhoc"
        path={"/app/customer/:customerId/sip/:sipId/ad-hoc"}
        component={AdHoc}
      />
      <Route
        exact
        key="control-group-adhoc"
        path={
          "/app/customer/:customerId/sip/:sipId/control-group/:controlGroupId/ad-hoc"
        }
        component={AdHoc}
      />
      <Route
        exact
        key="repeat-control-point"
        path={
          "/app/customer/:customerId/sip/:sipId/control-group/:controlGroupId/repeat-control-point/:controlPointId"
        }
        component={repeatControlPoint}
      />
      <Route
        exact
        key="adhoc-from-control-point"
        path={
          "/app/customer/:customerId/sip/:sipId/ad-hoc-from-control-point/:controlGroupId"
        }
        component={AdHocFromControlPoint}
      />
      <Route
        exact
        key="adhoc-from-control-group"
        path={"/app/customer/:customerId/ad-hoc-from-control-group/:sipId"}
        component={AdHocFromControlGroup}
      />
      <Route
        exact
        key="dismiss-controls"
        path={`/app/dismiss/:controlGroupId`}
        component={Dismiss}
      />
      <Route
        exact
        key="dismiss-controls"
        path={`/app/dismiss/:controlGroupId/selected/:controlPointId`}
        component={Dismiss}
      />
      <Route
        exact
        key="audit-details"
        path={`/app/customer/:customerId/sip/:sipId/audit`}
        component={Details}
      />
      <Route
        exact
        key="audit-perform"
        path={`/app/customer/:customerId/sip/:sipId/audit/:auditId`}
        component={Perform}
      />
      <Route
        exact
        key="audit-review"
        path={`/app/customer/:customerId/sip/:sipId/audit/:auditId/review`}
        component={Review}
      />
      <Route
        exact
        key="audit-notes"
        path={`/app/customer/:customerId/sip/:sipId/audit/:auditId/notes`}
        component={Notes}
      />
      <Route
        exact
        key="audit-results"
        path={`/app/customer/:customerId/sip/:sipId/audit/:auditId/results`}
        component={Results}
      />
      <Route
        exact
        key="audit-success"
        path={`/app/customer/:customerId/sip/:sipId/audit/:auditId/success`}
        component={Success}
      />
      <Route
        exact
        key="report"
        path={`/app/report/:type/:id`}
        component={Report}
      />
      <Route
        exact
        key="password-change"
        path={`/app/password/change`}
        component={PasswordChange}
      />
    </Switch>
  );
};

export default authenticated(requiresAcceptTOS(withRouter(AuthRoutes)));
