import React from "react";

interface BaseErrorProps {
  title: string;
  info: string;
  buttonText: string;
  handleClick: any; // TODO: add correct props
}

function BaseError(props: BaseErrorProps) {
  const { title, info, buttonText, handleClick } = props;
  return (
    <div className="base-error">
      <h3 className="base-error__error-title">{title}</h3>
      <div className="base-error__error-info">{info}</div>
      <button
        onClick={handleClick}
        className="btn btn-primary base-error__button-refresh"
      >
        {buttonText}
      </button>
    </div>
  );
}

export default BaseError;
