import * as React from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { getAuditConfig, getAuditControls } from "../../actions/AuditActions";
import {
  IAuditControlGroup,
  IAuditControlPoint,
  IAuditState
} from "../../types/Audit";
import { IAppState } from "../../store/Store";
import Loading from "../Loading";
import { getLocalStorage, keyExists, trans } from "../../generic/Helpers";
import {
  getControlGroup,
  getDropdown
} from "../../actions/ControlGroupActions";
import ListItem from "../ListItem";
import { TControlGroupState } from "../../types/ControlGroup";
import ControlPointBase from "../control-point/ControlPointBase";
import { TAuditData } from "../../types/ControlPoint";
import { IAdHocInstance, IAuditInstance } from "../../types/Requests";
import { createAdHoc } from "../../actions/ControlPointActions";
import { findIndex, find } from "lodash";
import { TApiDropdownListItem } from "../../types/Dropdown";
import { isEmpty } from "lodash";
import { getRequestUserInputAndCalculationValues } from "../../helpers/components/repeat";
import { useDropdown } from "../../hooks/useDropdown";
import { useRequest } from "../../hooks/useRequest";
import Alert from "../Alert";
import { clearErrors } from "../../actions/AuthActions";

interface IPerformProps extends RouteComponentProps<any> {}

const Perform: React.FC<IPerformProps> = ({
  match: {
    params: { auditId, sipId }
  },
  history
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const audit: IAuditState = useSelector<IAppState, IAuditState>(
    (state: IAppState) => state.audit
  );
  const controlGroup: TControlGroupState = useSelector<
    IAppState,
    TControlGroupState
  >((state: IAppState) => state.controlGroupState);
  const [performedInstances, setPerformedInstances] = useState<Array<any>>([]);
  const [auditControlGroup, setAuditControlGroup] = useState<
    IAuditControlGroup | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState<any>();
  const [performRemaining, setPerformRemaining] = useState(false);
  const auditConfig = keyExists("currentAuditConfig")
    ? JSON.parse(getLocalStorage("currentAuditConfig"))
    : {};
  const { ListItems: listItems } = useDropdown(auditConfig.AnswersListID);
  const { app } = useRequest();

  const getNextControlGroupIndex = (): number => {
    if (audit.data && audit.data.controls) {
      return findIndex(audit.data.controls, (control: any) => {
        return find(control.ControlPointStatuses, (controlPoint: any) => {
          return controlPoint.ControlInstance === null;
        });
      });
    }

    return -1;
  };

  useEffect(() => {
    if (app.hasError()) {
      setLoading(false);
    }
  }, [app]);

  useEffect(() => {
    dispatch(getAuditControls(auditId));
    dispatch(getAuditConfig(sipId));
    if (isEmpty(controlGroup.dropdown) && !isEmpty(auditConfig)) {
      dispatch(getDropdown(auditConfig.AnswersListID));
    }
  }, [dispatch]);

  useEffect(() => {
    if (audit.data && audit.data.controls) {
      const nextIndex = getNextControlGroupIndex();
      if (nextIndex > -1) {
        setAuditControlGroup(audit.data.controls[nextIndex]);
        dispatch(
          getControlGroup(audit.data.controls[nextIndex].ControlGroupNode.ID)
        );
      } else {
        history.push(window.location.pathname + "/review");
      }
    }
  }, [audit.data, history, dispatch]);

  useEffect(() => {
    if (controlGroup.dropdown) {
      setDropdown(controlGroup.dropdown[Object.keys(controlGroup.dropdown)[0]]);
    }
  }, [controlGroup.dropdown]);

  const generateAuditInstance = (
    instance: any,
    data: TAuditData
  ): IAuditInstance => {
    const startTime = new Date();
    const endTime = new Date();
    endTime.setTime(startTime.getTime() + 2 * 24 * 60 * 60 * 1000);
    return {
      customFields: data.customFields,
      controlPoint: instance.TreeNode && instance.TreeNode.ID,
      endTime,
      performStatus: data.conditionIsTrue,
      performTime: startTime.toISOString(),
      startTime,
      userNotes: data.notes,
      partOfBatch: auditId,
      photoMappings: data.photos
        ? data.photos.map(photo => ({ photo: photo.photoId }))
        : [],
      ...getRequestUserInputAndCalculationValues(
        data.value,
        instance.ControlType
      ),
      ...data.additionalData
    };
  };

  const isPerformed = (auditInstance: IAuditInstance): boolean => {
    return !!find(performedInstances, (instance: IAdHocInstance) => {
      return instance.controlPoint === auditInstance.controlPoint;
    });
  };

  const handleControlPoint = (instance: any, data: TAuditData) => {
    const startTime = new Date();
    const endTime = new Date();
    endTime.setTime(startTime.getTime() + 2 * 24 * 60 * 60 * 1000);
    const auditInstance = generateAuditInstance(instance, data);

    if (isPerformed(auditInstance)) {
      setPerformedInstances(
        performedInstances.map(instance =>
          instance.controlPoint === auditInstance.controlPoint
            ? auditInstance
            : instance
        )
      );
    } else {
      setPerformedInstances([...performedInstances, auditInstance]);
    }
  };

  const constructRemainingControls = (e: FormEvent) => {
    e.preventDefault();
    if (auditControlGroup && controlGroup) {
      let array: any = [];
      auditControlGroup.ControlPointStatuses.forEach(
        (auditControlPoint: IAuditControlPoint) => {
          if (auditControlPoint.ControlInstance === null) {
            const instance: any =
              controlGroup.data &&
              controlGroup.data.info[auditControlPoint.ControlPointNode.ID];

            if (listItems) {
              const data: TAuditData = {
                notes: "",
                value: [listItems[value]],
                customFields: [],
                conditionIsTrue: true,
                photos: [],
                isSameAsLastAudit: false,
                sameAsLastAuditNotes: ""
              };

              const auditInstance = generateAuditInstance(instance, data);
              if (!isPerformed(auditInstance)) {
                array = [...array, auditInstance];
              }
            }
          }
        }
      );
      setPerformedInstances([...performedInstances, ...array]);
      setPerformRemaining(true);
    }
  };

  useEffect(() => {
    if (performRemaining) {
      performAuditInstances();
      setPerformRemaining(false);
    }
  }, [performRemaining]);

  const performAuditInstances = () => {
    setLoading(true);
    const allInstances = performedInstances.map((instance: IAuditInstance) => {
      return dispatch(createAdHoc(instance));
    });

    Promise.all(allInstances).then(() => {
      if (audit.data && audit.data.controls) {
        setLoading(false);
        const nextIndex = getNextControlGroupIndex();
        setPerformedInstances([]);
        setAuditControlGroup(audit.data.controls[nextIndex]);
        dispatch(getAuditControls(auditId));
        dispatch(
          getControlGroup(audit.data.controls[nextIndex].ControlGroupNode.ID)
        );
        dispatch(clearErrors());
      }
    });
  };

  return (
    <>
      <Navbar
        headerText={
          auditControlGroup
            ? auditControlGroup.ControlGroupNode.Name
            : "Perform Audit"
        }
      />
      {(!auditControlGroup ||
        audit.isFetching ||
        controlGroup.isFetching ||
        loading) &&
      !app.hasError() ? (
        <Loading text={trans("global-loading")} />
      ) : (
        <>
          {app.hasError() && (
            <Alert type={"danger"} text={app.getError()} error={true} />
          )}
          <div className="item-list">
            {auditControlGroup &&
              controlGroup.data &&
              auditControlGroup.ControlPointStatuses.map(
                (auditControlPoint: IAuditControlPoint) => {
                  if (auditControlPoint.ControlInstance === null) {
                    const auditInstance =
                      controlGroup.data &&
                      controlGroup.data.info[
                        auditControlPoint.ControlPointNode.ID
                      ];

                    if (!auditInstance) {
                      return false;
                    }

                    return (
                      <ListItem
                        key={auditControlPoint.ControlPointNode.ID}
                        title={auditControlPoint.ControlPointNode.Name}
                        detail={auditControlPoint.ControlPointNode.Notes}
                        openByDefault={true}
                        content={
                          <ControlPointBase
                            audit={true}
                            instance={{
                              ...auditInstance,
                              Audit: true
                            }}
                            callback={handleControlPoint}
                          />
                        }
                      />
                    );
                  }
                  return false;
                }
              )}
          </div>
          <div className="text-block">
            <div>{trans("audit-perform-all-remaining-intro")}</div>
            <form
              className="d-flex align-items--center mt-3 audit__perform-all--form"
              onSubmit={constructRemainingControls}
            >
              <select
                className="form-control__input mt-2 mb-2"
                onChange={e => setValue(e.target.value)}
                required={true}
              >
                <option value="">
                  {trans("input-select-action-from-dropdown")}
                </option>
                {dropdown &&
                  dropdown.ListItems &&
                  dropdown.ListItems.map(
                    (item: TApiDropdownListItem, index: number) => {
                      return (
                        <option key={item.ID} value={index}>
                          {item.Text}
                        </option>
                      );
                    }
                  )}
              </select>
              <button type="submit" className="btn btn-primary ml-5 btn-small">
                {trans("button-perform-all-remaining")}
              </button>
            </form>
          </div>
          <div className="input-group mt-3 d-flex justify--center">
            <button
              className="btn btn-small btn-primary"
              onClick={performAuditInstances}
              disabled={!performedInstances.length}
            >
              {trans("global-save")}
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Perform;
