import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TControlGroupState } from "../../../../types/ControlGroup";
import { TApiControlPointEntity } from "../../../../types/ControlPoint";
import { IAdHocInstance } from "../../../../types/Requests";
import {
  generateAdHocInstance,
  getControlPointInfo,
  getDocumentation
} from "../../../../generic/Helpers";
import ListItem from "../../../ListItem";
import ControlPointBase from "../../../control-point/ControlPointBase";
import { getControlGroup } from "../../../../actions/ControlGroupActions";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

interface IUnscheduledControl extends RouteComponentProps<any> {
  controlGroup: TControlGroupState;
  controlPoint: TApiControlPointEntity;
}

const UnscheduledControl: React.FC<IUnscheduledControl> = ({
  controlGroup,
  controlPoint,
  match: {
    params: { controlGroupId }
  }
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const instance: IAdHocInstance = generateAdHocInstance(controlPoint);
  const controlPointInfo = getControlPointInfo(
    controlGroup.data && controlGroup.data.info,
    controlPoint.TreeNode.ID
  );

  const doc = getDocumentation(
    controlPoint.TreeNode.ID,
    controlGroup.data ? controlGroup.data.documentation : {}
  );

  return (
    <ListItem
      key={controlPoint.TreeNode.ID}
      title={controlPointInfo.TreeNode.Name}
      detail={controlPointInfo.TreeNode.Notes}
      itemType={"control-point"}
      id={controlPoint.TreeNode.ID}
      docs={!!doc}
      settings={!!doc}
      content={
        <ControlPointBase
          performAdHoc={true}
          instance={instance}
          callback={() => dispatch(getControlGroup(controlGroupId))}
        />
      }
      controls={{ doc }}
    />
  );
};

export default withRouter(UnscheduledControl);
