import * as React from "react";
import { RouteComponentProps, Prompt } from "react-router";
import Navbar from "../nav/Navbar";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  generateAdHocInstance,
  trans,
  getDocumentation
} from "../../generic/Helpers";
import ListItem from "../ListItem";
import Loading from "../Loading";
import { useState } from "react";
import ControlPointForm from "../forms/ControlPoint";
import { useControlGroup } from "../../hooks/useControlGroup";
import { useRepeatControlGroupForm } from "../../hooks/useRepeatControlGroupForm";
import { generateUrl } from "../../generic/Requests";
import { clearForms } from "../../reducers/RepeatControlGroupReducer";
import {
  useSetRepeatFormsFromControlGroup,
  getRepeatControlPointForm,
  getControlPointRequests
} from "../../helpers/components/repeat";
import { TControlPointInstance } from "../../types/ControlPoint";
import { ControlPointType } from "../../generic/Config";
import ReactDatePicker from "react-datepicker";
import { useAdHocPerformTime } from "../../hooks/useAdHocPerformTime";

interface IRepeatProps extends RouteComponentProps<any> {}

// TODO: handle leaving route with a dialog text
const Repeat: React.FC<IRepeatProps> = ({
  match: {
    params: { customerId, controlGroupId, sipId }
  },
  history
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  // TODO: create a wrapped loader
  const [loading, setLoading] = useState(false);
  const [maxDate] = useAdHocPerformTime();
  const [perform, setPerform] = useState<Date | null>(new Date());

  // using redux state
  const controlGroup = useControlGroup(controlGroupId);
  const {
    forms,
    ids,
    startDate,
    setForm,
    setFormFields,
    setStartDate
  } = useRepeatControlGroupForm();
  // initializing repeat form data after control group is fetched
  useSetRepeatFormsFromControlGroup(
    controlGroupId,
    controlGroup,
    ids,
    dispatch
  );
  const controlGroupInfo =
    (controlGroup && controlGroup.data && controlGroup.data.info) || {};
  const controlInfoKeys = Object.keys(controlGroupInfo);

  const isCorrectForm =
    ids.length > 0 &&
    ids.every((key: string) => controlInfoKeys.includes(`${key}`));

  // We can't use goBack, since we might come from deviations, or reminder
  // and there is no other way to get back to the sip
  // TODO: fix navigation either use menu from the right bar or make navigation not use goBack
  // TODO: maybe export different location with proper function names
  const goToSipPage = () =>
    history.push({
      pathname: generateUrl(customerId, sipId)
    });

  // TODO: define prop types
  const saveRepeatableState = (id: any, formFields: any, controlPoint: any) => {
    if (formFields.completed) {
      return setFormFields(id, { completed: false });
    }

    const repeatControlPointForm = getRepeatControlPointForm(
      id,
      forms[id],
      controlPoint
    );
    setForm(id, repeatControlPointForm);

    if (!repeatControlPointForm.performStatus) {
      history.push({
        pathname: generateUrl(
          customerId,
          sipId,
          controlGroupId,
          `deviation/${id}`
        ),
        state: {
          isFromRepeatControlGroup: true,
          instance: controlPoint,
          instanceRequest: repeatControlPointForm
        }
      });
    }
  };

  const performRepeatableInstances = () => {
    setLoading(true);

    Promise.all(
      getControlPointRequests(ids, forms).map((request: any) =>
        dispatch(request)
      )
    ).then(() => {
      dispatch(clearForms());
      goToSipPage();
    });
    // TODO: onCatch show a toast message that an error happend
    // TODO: consider a toast message on success as well
  };

  const showSubmitButton =
    ids.length > 0 && ids.every((id: string | number) => forms[id].completed);

  const handlePrompt = (location: any) => {
    if (
      location.pathname.includes(
        generateUrl(customerId, sipId, controlGroupId, `deviation/`)
      )
    ) {
      return true;
    }
    // TODO: handle edited form fields better with formState functionality
    const hasEditedForm = ids.some(
      (id: any) =>
        (!!forms[id].userInput &&
          controlGroup.data.info[id].ControlType !== ControlPointType.DATE) ||
        !!forms[id].userNotes ||
        forms[id].customFields.some((field: any) => !!field.value) ||
        forms[id].photoMappings.length > 0
    );
    if (hasEditedForm) {
      return trans("repeat-control-group-unfinished");
    }
    return true;
  };

  React.useEffect(() => {
    history.listen(location => {
      const deviationUrl = generateUrl(
        customerId,
        sipId,
        controlGroupId,
        `deviation/`
      );
      const repeatUrl = generateUrl(
        customerId,
        sipId,
        controlGroupId,
        `repeat`
      );
      const createReminderUrl = generateUrl(
        customerId,
        sipId,
        controlGroupId,
        `reminder`
      );
      if (
        [repeatUrl, deviationUrl, createReminderUrl].every(
          (url: string) => !location.pathname.includes(url)
        )
      ) {
        dispatch(clearForms());
      }
    });
  }, []);

  return (
    <>
      <Navbar headerText={trans("page-repeat-control-group-title")} backLink={goToSipPage} />
      <Prompt when message={handlePrompt} />
      {(ids.length === 0 || loading || !isCorrectForm) && (
        <Loading text={trans("global-loading")} />
      )}
      {!startDate && (
        <div className="text-block mt-3">
          <p className="pb-3">
            {trans("repeat-control-point-datepicker-info-text")}
          </p>
          <label htmlFor="start">{trans("input-select-date")}</label>
          <div className="input-group mb-3">
            <ReactDatePicker
              selected={perform}
              onChange={e => setPerform(e)}
              showTimeSelect={true}
              timeFormat="HH:mm"
              timeIntervals={30}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeCaption="time"
              maxDate={maxDate}
              className="inp"
              placeholderText={trans("input-select-date")}
            />
          </div>
          <div className="input-group mt-3 d-flex justify--center">
            <button
              onClick={() => setStartDate(perform)}
              disabled={!perform}
              className="btn btn-small btn-primary"
            >
              {trans("global-ok")}
            </button>
          </div>
        </div>
      )}
      {startDate && (
        <>
          {ids.length > 0 &&
            isCorrectForm &&
            !loading &&
            controlGroup &&
            controlGroup.data &&
            controlGroup.data.contents &&
            !controlGroup.isFetching && (
              <div className="item-list">
                {ids.map((id: any) => {
                  const formData = forms[id];
                  const controlPoint = controlGroup.data.info[id];
                  // required block for list item menu items
                  const instance: TControlPointInstance<
                    any
                  > = generateAdHocInstance(controlPoint);
                  const doc = getDocumentation(
                    controlPoint.TreeNode.ID,
                    controlGroup.data ? controlGroup.data.documentation : {}
                  );
                  // form handlers
                  const handleSetFormFields = (fields: any) =>
                    setFormFields(id, fields);
                  const handleSubmit = () =>
                    saveRepeatableState(id, formData, controlPoint);
                  return (
                    <ListItem
                      key={id}
                      title={controlPoint.TreeNode.Name}
                      itemType={"control-point"}
                      id={controlPoint.TreeNode.ID}
                      disabled={forms[controlPoint.TreeNode.ID].completed}
                      // for some reason we use the same value for 3 different props
                      settings={!!doc}
                      controls={{ doc }}
                      docs={!!doc}
                      openByDefault={true}
                      content={
                        <ControlPointForm
                          //TODO: remove dependency instance is used by ListItem
                          instance={instance}
                          controlPoint={controlPoint}
                          formFields={{
                            userInput: formData.userInput,
                            userNotes: formData.userNotes,
                            customFields: formData.customFields,
                            photoMappings: formData.photoMappings,
                            isSameAsLastAudit: formData.isSameAsLastAudit,
                            sameAsLastAuditNotes: formData.sameAsLastAuditNotes,
                            performDate: startDate
                          }}
                          setFormFields={handleSetFormFields}
                          onSubmit={handleSubmit}
                          completed={forms[controlPoint.TreeNode.ID].completed}
                        />
                      }
                    />
                  );
                })}
              </div>
            )}
          {showSubmitButton && !loading && (
            <div className="input-group mt-3 d-flex justify--center">
              <button
                className="btn btn-small btn-primary"
                onClick={performRepeatableInstances}
              >
                {trans("global-save")}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Repeat;
