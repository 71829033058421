import { RouteComponentProps, withRouter } from "react-router";
import { TControlGroupState } from "../../../types/ControlGroup";
import * as React from "react";
import {
  TApiControlPointEntity,
} from "../../../types/ControlPoint";
import {
  getFirstOpenInstance,
  trans
} from "../../../generic/Helpers";
import EmptyPage from "../../pages/EmptyPage";
import OpenInstance from "./types/OpenInstance";
import { find } from "lodash";

interface IWorkflowProps extends RouteComponentProps<any> {
  controlGroup: TControlGroupState;
}

const Workflow: React.FC<IWorkflowProps> = ({
  controlGroup
}) => {
  if (controlGroup.data) {
    const firstOpenItem = getFirstOpenInstance(
      controlGroup,
      controlGroup.data.openInstances
    );

    if (!firstOpenItem) {
      return <></>;
    }

    const workflowItem = find(
      controlGroup.data.contents,
      (e: TApiControlPointEntity) => {
        return e.TreeNode.ID === firstOpenItem.ControlID;
      }
    );

    if (workflowItem) {
      return (
        <div className="item-list">
          <OpenInstance
            controlGroup={controlGroup}
            controlPoint={workflowItem}
            openByDefault={true}
            hideContextMenu={true}
          />
        </div>
      );
    }
  }

  return <EmptyPage text={trans("no-active-control-points")} />;
};

export default withRouter(Workflow);
