import { AxiosResponse } from "axios";
import {TApiReportTask, TApiTaskResponse} from "./Task";

export enum ReportInit {
  REQUEST = "REPORT_INIT_REQUEST",
  SUCCESS = "REPORT_INIT_SUCCESS",
  FAILED = "REPORT_INIT_FAILED",
  CLEAR = "REPORT_DATA_CLEAR"
}

export type TInitReportAction = {
  type: ReportInit.REQUEST;
} | {
  type: ReportInit.SUCCESS;
  payload: AxiosResponse<TApiTaskResponse<TApiReportTask>>;
} | {
  type: ReportInit.FAILED;
  payload: AxiosResponse;
} | {
  type: ReportInit.CLEAR;
}

export enum GetReportProgress {
  REQUEST = "GET_REPORT_PROGRESS_REQUEST",
  SUCCESS = "GET_REPORT_PROGRESS_SUCCESS",
  FAILED = "GET_REPORT_PROGRESS_FAILED"
}

export type TGetReportProgress = {
  type: GetReportProgress.REQUEST;
} | {
  type: GetReportProgress.SUCCESS;
  payload: AxiosResponse<TApiTaskResponse<TApiReportTask>>;
} | {
  type: GetReportProgress.FAILED;
  payload: AxiosResponse;
}

export type IReportActions = TInitReportAction | TGetReportProgress;
