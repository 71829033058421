import { AxiosResponse } from "axios";

export interface TApiTranslationData {
  translations: TTranslationStrings;
}

export type TTranslationStrings = Record<string, string>;

export interface TTranslationState {
  readonly isFetching?: boolean;
  readonly data?: TTranslationStrings;
}

export const initialTranslationState: TTranslationState = {
  isFetching: false,
  data: undefined
};

export enum GetTranslations {
  REQUEST = "GET_TRANSLATIONS_REQUEST",
  SUCCESS = "GET_TRANSLATIONS_SUCCESS",
  FAILED = "GET_TRANSLATIONS_FAILED"
}

export type TGetTranslation = {
  type: GetTranslations.REQUEST;
} | {
  type: GetTranslations.SUCCESS;
  payload: AxiosResponse<TApiTranslationData>;
} |  {
  type: GetTranslations.FAILED;
  payload: AxiosResponse;
}
