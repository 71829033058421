import * as React from "react";
import ListItem from "../ListItem";
import EmptyPage from "./EmptyPage";
import { TApiSipEntity, TSipListState } from "../../types/Sip";
import { RouteComponentProps, withRouter } from "react-router";
import {
  getDocumentation,
  getLocalStorage,
  getRemainingWork,
  keyExists,
  trans
} from "../../generic/Helpers";
import { generateUrl } from "../../generic/Requests";

interface ISipListProps extends RouteComponentProps<any> {
  sips: TSipListState;
}

const SipList: React.FC<ISipListProps> = ({
  match: {
    params: { customerId }
  },
  sips
}): JSX.Element => {
  if (sips.data && sips.data.sips.length === 0) {
    return <EmptyPage text={trans("siplist-empty")} />;
  }
  return (
    <div className="item-list">
      {sips.data &&
        sips.data.sips.map((sip: TApiSipEntity) => {
          const documentation = getDocumentation(
            sip.TreeNode.ID,
            sips.data && sips.data.documentation
          );
          const detail =
            keyExists("currentAuditId") &&
            parseInt(getLocalStorage("currentAuditId")) === sip.TreeNode.ID
              ? `${trans("sip-list-audit-in-progress")}`
              : "";
          let controls = sip.Auditable
            ? {
                doc: documentation
              }
            : {
                sipAdhoc: {
                  sipId: sip.TreeNode.ID,
                  redirectUrl: window.location.pathname
                },
                AdHocFromControlGroup: {
                  sipId: sip.TreeNode.ID,
                  redirectUrl: window.location.pathname
                },
                doc: documentation,
                report: {
                  type: "sip",
                  id: sip.TreeNode.ID
                }
              };

          return (
            <ListItem
              key={sip.TreeNode.ID}
              url={generateUrl(customerId, sip.TreeNode.ID)}
              title={sip.TreeNode.Name}
              detail={detail}
              count={getRemainingWork(
                sip.TreeNode.ID,
                sips.data && sips.data.remainingWork
              )}
              gps={!!sip.GpsLock}
              docs={!!documentation}
              settings={(sip.Auditable && !!documentation) || !sip.Auditable}
              controls={controls}
            />
          );
        })}
    </div>
  );
};

export default withRouter(SipList);
