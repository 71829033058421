import * as React from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import {
  getLocalStorage,
  removeLocalStorage,
  trans
} from "../../generic/Helpers";
import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdditionalRecipient,
  finishAudit,
  getAuditRecipients
} from "../../actions/AuditActions";
import {
  IAuditState,
  TAuditRecipient,
  TRecipientState
} from "../../types/Audit";
import { IAppState } from "../../store/Store";
import { find, filter } from "lodash";
import { generateUrl } from "../../generic/Requests";
import { useRequest } from '../../hooks/useRequest';
import Alert from '../Alert';
import { clearErrors } from '../../actions/AuthActions';

interface IResultsProps extends RouteComponentProps<any> {}

const Results: React.FC<IResultsProps> = ({
  match: {
    params: { customerId, sipId, auditId }
  },
  history
}): JSX.Element => {
  const dispatch = useDispatch();
  const audit: IAuditState = useSelector<IAppState, IAuditState>(
    (state: IAppState) => state.audit
  );
  const [recipients, setRecipients] = useState<any>([]);
  const [additionalRecipient, setAdditionalRecipient] = useState("");
  const [format, setFormat] = useState("pdf");
  const auditConfig = JSON.parse(getLocalStorage("currentAuditConfig"));
  const { app } = useRequest();

  useEffect(() => {
    dispatch(getAuditRecipients(sipId));
  }, [sipId, dispatch]);

  const handleCheckboxChange = (email: string, name: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const recipientObject = {
      Email: email,
      Name: name
    };
    const recipientInList = find(recipients, (r: TAuditRecipient) => {
      return r.Name === name;
    });
    if (e.target.checked) {
      if (!recipientInList) {
        setRecipients([...recipients, recipientObject]);
      }
    } else {
      if (recipientInList) {
        setRecipients(
          filter(recipients, (r: TAuditRecipient) => {
            return r.Name !== name;
          })
        );
      }
    }
  };

  const handleAddRecipient = (e: FormEvent) => {
    e.preventDefault();
    const recipientObject = {
      Name: "",
      Email: additionalRecipient
    };
    dispatch(addAdditionalRecipient(recipientObject));
    setRecipients([...recipients, recipientObject]);
    setAdditionalRecipient("");
  };

  const handleFinishAudit = () => {
    let recipientsData: TRecipientState[] = [];
    recipients.map((recipient: TAuditRecipient) => {
      return recipientsData.push({
        name: recipient.Name,
        email: recipient.Email
      });
    });

    const auditData: any = {
      audit: auditId,
      format,
      recipients: recipientsData
    };

    dispatch(
      finishAudit(auditData, () => {
        removeLocalStorage("currentAuditConfig");
        removeLocalStorage("currentAudit");
        removeLocalStorage("currentAuditId");
        history.push(
          generateUrl(customerId, sipId, undefined, `audit/${auditId}/success`)
        );
      })
    );
    dispatch(clearErrors());
  };

  return (
    <>
      <Navbar headerText={trans("audit-results-page-title")} />
      {app.hasError() && <Alert type={"danger"} text={app.getError()} error={true}/>}
      <div className="text-block">
        <h3 className="mb-3">{trans("audit-select-recipients")}</h3>
        {audit.data &&
          audit.data.recipients &&
          audit.data.recipients.map(
            (recipient: TAuditRecipient, index: number) => {
              return (
                <div className="input-group pb-1" key={index}>
                  <input
                    type="checkbox"
                    name="controlGroupId"
                    id={"cg-" + recipient.ID}
                    value={recipient.Email}
                    onChange={handleCheckboxChange(
                      recipient.Email,
                      recipient.Name
                    )}
                    checked={
                      !!find(recipients, (r: TAuditRecipient) => {
                        return r.Name === recipient.Name;
                      })
                    }
                    required
                  />
                  <label className="ml-1" htmlFor={"cg-" + recipient.ID}>
                    {recipient.Name} ({recipient.Email})
                  </label>
                </div>
              );
            }
          )}
        <div className="d-flex direction--column mt-5">
          <form onSubmit={handleAddRecipient}>
            <label htmlFor="recipient">
              {trans("audit-additional-recipients")}
            </label>
            <div className="input-group mb-3">
              <input
                className="inp w-100"
                type="email"
                id="recipient"
                required={true}
                value={additionalRecipient}
                onChange={e => setAdditionalRecipient(e.target.value)}
              />
            </div>
            <button className="btn btn-small btn-primary" type="submit">
              {trans("global-ok")}
            </button>
          </form>
          {auditConfig.AllowExcelFormat && (
            <div className="mt-5">
              <h3 className="mb-3">{trans("audit-export-format")}</h3>
              <div className="input-group pb-1">
                <input
                  type="radio"
                  name="format"
                  id={"pdf"}
                  value={"pdf"}
                  onChange={e => setFormat(e.target.value)}
                  checked={format === "pdf"}
                  required
                />
                <label className="ml-1" htmlFor={"pdf"}>
                  {trans("audit-pdf-export")}
                </label>
              </div>
              <div className="input-group pb-1">
                <input
                  type="radio"
                  name="format"
                  id={"xls"}
                  value={"xls"}
                  onChange={e => setFormat(e.target.value)}
                  checked={format === "xls"}
                  required
                />
                <label className="ml-1" htmlFor={"xls"}>
                  {trans("audit-xls-export")}
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify--center">
        <button
          onClick={handleFinishAudit}
          className="btn btn-primary btn-small mt-3"
        >
          {trans("audit-send-emails-and-proceed")}
        </button>
      </div>
    </>
  );
};

export default Results;
