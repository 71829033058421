import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useEffect } from "react";
import { getCustomerList } from "../actions/CustomerActions";
import { IAppState } from "../store/Store";
import { TCustomerListState } from "../types/Customer";
import Loading from "../components/Loading";
import CustomerList from "../components/pages/CustomerList";
import { trans } from "../generic/Helpers";
import Navbar from "../components/nav/Navbar";

interface ICustomerListContainerProps extends RouteComponentProps<any> {}

const CustomerListContainer: React.FC<ICustomerListContainerProps> = (
  props
): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const customers = useSelector<IAppState, TCustomerListState>(
    (state: IAppState) => state.customerListState
  );

  useEffect(() => {
    dispatch(getCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (customers.data && customers.data.customers.length === 1) {
      props.history.push(
        `/app/customer/${customers.data.customers[0].TreeNode.ID}`
      );
    }
  }, [customers]);

  return (
    <section>
      <Navbar headerText={trans("page-customer-list-title")} />
      {customers && customers.isFetching ? (
        <Loading text={trans("global-loading")} />
      ) : (
        <CustomerList customers={customers} />
      )}
    </section>
  );
};

export default CustomerListContainer;
