import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { getControlGroup } from "../actions/ControlGroupActions";

// TODO: convert to ts
export const useControlGroup = (controlGroupId) => {
  const dispatch = useDispatch();
  const controlGroup = useSelector((state) => state.controlGroupState);
  
  useEffect(() => {
    dispatch(getControlGroup(controlGroupId))
  }, [])

  return {
    data: controlGroup.data,
    isFetching: controlGroup.isFetching,
  }
};
