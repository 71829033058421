import React from "react";
import Slide from "@material-ui/core/Slide";

import { trans } from "../../../generic/Helpers";

interface IDeclinedContainerProps {
  handleViewTOSClick: any;
}

function DeclinedContainer(props: IDeclinedContainerProps) {
  const { handleViewTOSClick } = props;
  return (
    <Slide direction="up" in={true}>
      <div className="accept-tos">
        <h1 className="accept-tos__title">
          {trans("accept-tos-declined-title")}
        </h1>
        <div className="accept-tos__content">
          <div className="accept-tos__body-text">
            {trans("accept-tos-declined-body-text")}
          </div>
        </div>
        <div className="accept-tos__actions">
          <button
            onClick={handleViewTOSClick}
            className="btn btn-primary accept-tos__accept-button"
          >
            {trans("accept-tos-declined-button")}
          </button>
        </div>
      </div>
    </Slide>
  );
}

export default DeclinedContainer;
