import * as React from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import LoginContainer from "./containers/auth/LoginContainer";
import AuthRoutes from "./AuthRoutes";
import PasswordForgotten from "./components/pages/PasswordForgotten";
import ResetForgottenPassword from "./components/pages/ResetForgottenPassword";

interface IRoutesProps extends RouteComponentProps<any> {}

const Routes: React.FC<IRoutesProps> = (): JSX.Element => {
  return (
    <Switch>
      <Route exact key="login" path={"/"} component={LoginContainer} />
      <Route exact key="requestPasswordReset" path={"/resetPassword/start"} component={PasswordForgotten} />
      <Route exact key="confirmPasswordReset" path={"/resetPassword/confirm/:token"} component={ResetForgottenPassword} />
      <AuthRoutes />
    </Switch>
  );
};

export default withRouter(Routes);
