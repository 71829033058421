import * as React from "react";
import { FormEvent, useEffect, useState } from "react";
import emailIcon from "../../assets/images/icons/email.svg";
import passwordIcon from "../../assets/images/icons/password.svg";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { getUserPermissions, loginUser } from "../../actions/AuthActions";
import { IAuthState } from "../../types/Auth";
import { IAppState } from "../../store/Store";
import { RouteComponentProps, withRouter } from "react-router";
import { trans, userIsAuthenticated } from "../../generic/Helpers";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import { generateUrl } from "../../generic/Requests";
import { Link } from "react-router-dom";

interface ILoginContainerProps extends RouteComponentProps<any> {}

const LoginContainer: React.FC<ILoginContainerProps> = (props): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const dispatch = useDispatch<Dispatch<any>>();
  const auth: IAuthState = useSelector<IAppState, IAuthState>(
    (state: IAppState) => state.authState
  );

  const authenticateUser = async (e: FormEvent) => {
    e.preventDefault();
    await dispatch(
      loginUser(email, password, () => {
        dispatch(getUserPermissions());
      })
    );
  };

  useEffect(() => {
    if (userIsAuthenticated(auth)) {
      return props.history.push(generateUrl());
    }
  });

  return (
    <>
      {auth && auth.isFetching && <Loading />}
      {auth && auth.error && <Alert type={"danger"} text={auth.errorMessage} />}
      <div className="form-wrapper">
        <form className="login-form" onSubmit={authenticateUser}>
          <div className="input-group has-icon">
            <img className="inp-addon" src={emailIcon} alt="Email" />
            <input
              className="inp"
              placeholder="Email address"
              type="text"
              autoComplete="off"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group has-icon">
            <img className="inp-addon" src={passwordIcon} alt="" />
            <input
              className="inp"
              placeholder="Password"
              type="password"
              autoComplete="off"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <button type="submit" className="btn btn-login">
              {trans("auth-login")}
            </button>
          </div>
        </form>
        <div className="login-forgot-container">
          <Link to={"/resetPassword/start"}>
            <span>{trans("menu-forgot-your-password")}</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default withRouter(LoginContainer);
