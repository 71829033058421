import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { deleteSnackbar } from "../reducers/SnackbarsReducer";

import { IAppState } from "../store/Store";
import SnackbarView from "../components/SnackbarsContainer/SnackbarView";

const SnackbarsContainer = (props: any) => {
  const snacks = useSelector((state: IAppState) => state.snackbars);
  const dispatch = useDispatch<any>();
  const deleteSnack = (id: string) => dispatch(deleteSnackbar(id));

  return (
    <div className="snackbars">
      {snacks.map(snack => (
        <SnackbarView
          key={snack.id}
          snackbar={snack}
          deleteSnackbar={deleteSnack}
        />
      ))}
    </div>
  );
};

export default SnackbarsContainer;
