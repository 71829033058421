import * as React from "react";
import {TCustomerState} from "../types/Customer";
import {useSelector} from "react-redux";
import {IAppState} from "../store/Store";
import edocLogo from "../assets/images/edoc.large.png";

interface ILogoContainerProps {
  show: boolean;
}

const Logo: React.FC<ILogoContainerProps> = (props): JSX.Element => {
  const customer: TCustomerState = useSelector<IAppState, TCustomerState>(
      (state: IAppState) => state.customerState
  );

  let logo: string|null = null;

  if (!customer.data) {
    logo = edocLogo;
  } else {
    logo = customer.data.LogoImage;
  }

  if (logo === null) {
    return <></>;
  }

  return (
    <>
      {props.show && (
        <header className="logo-container">
          <img className="logo-image" src={logo} alt="logo" />
        </header>
      )}
    </>
  );
};

export default Logo;
