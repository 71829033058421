import { AxiosResponse } from "axios";
import { TApiTreeNodeEntityInSip, TNodeTypeIdentifier } from "./Tree";
import {
  ControlPointIntervalComparison,
  ControlPointNumberComparison,
  ControlPointType,
  ListViewMode,
  PhotoMode
} from "../generic/Config";

interface TApiControlPointEntityBase {
  NodeTypeIdentifier: TNodeTypeIdentifier.CONTROL_POINT;
  TreeNode: TApiTreeNodeEntityInSip;
  ActionSetID: number;
  ControlType: ControlPointType;
  DropdownListID: number | null;
  Internal: boolean;
  WorkflowStep: number | null;
  PhotoMode: PhotoMode;
  CustomFields: Array<TApiControlPointCustomField>;
}

export interface TApiControlPointCustomField {
  ID: number;
  Name: string;
  Ordering: number;
  Required: boolean;
}

export type TControlPointTypeBase = {
  ControlType: ControlPointType;
  ConditionDetails: TApiControlPointConditionDetails;
};

// ---

export type TControlPointNumberBase = {
  ControlType: ControlPointType.NUMBER;
  ConditionDetails: TApiControlPointConditionNumber;
};

type TApiControlPointNumber = TApiControlPointEntityBase &
  TControlPointNumberBase;

interface TApiControlPointConditionNumber {
  ComparisonOperator: ControlPointNumberComparison;
  ComparisonValue: number;
  MeasurementUnit: string;
}

// ---

export type TControlPointIntervalBase = {
  ControlType: ControlPointType.INTERVAL;
  ConditionDetails: TApiControlPointConditionInterval;
};

type TApiControlPointInterval = TApiControlPointEntityBase &
  TControlPointIntervalBase;

interface TApiControlPointConditionInterval {
  ComparisonType: ControlPointIntervalComparison;
  ComparisonLeft: number;
  ComparisonRight: number;
  MeasurementUnit: string;
}

// ---

export type TControlPointDateBase = {
  ControlType: ControlPointType.DATE;
  ConditionDetails: TApiControlPointConditionDate;
};

type TApiControlPointDate = TApiControlPointEntityBase & TControlPointDateBase;

interface TApiControlPointConditionDate {
  DaysInAdvance: number;
}

// ---

export type TControlPointYesNoBase = {
  ControlType: ControlPointType.YESNO;
  ConditionDetails: TApiControlPointConditionYesNo;
};

type TApiControlPointYesNo = TApiControlPointEntityBase &
  TControlPointYesNoBase;

interface TApiControlPointConditionYesNo {
  ComparisonValue: boolean;
}

// ---

export type TControlPointSelectFromListBase = {
  ControlType: ControlPointType.SELECT_FROM_LIST;
  ConditionDetails: TApiControlPointConditionSelectFromList;
  Audit?: boolean;
};

type TApiControlPointSelectFromList = TApiControlPointEntityBase &
  TControlPointSelectFromListBase;

interface TApiControlPointConditionSelectFromList {
  DropdownID: number;
  ViewMode: ListViewMode;
}

// ---

export type TControlPointTimestampBase = {
  ControlType: ControlPointType.TIMESTAMP;
  ConditionDetails: TApiControlPointConditionTimestamp;
};

type TApiControlPointTimestamp = TApiControlPointEntityBase &
  TControlPointTimestampBase;

interface TApiControlPointConditionTimestamp {
  RequiresNotes: boolean;
}

// ---
export type TControlPointFormBase = {
  ControlType: ControlPointType.NUMBER | ControlPointType.INTERVAL | ControlPointType.DATE | ControlPointType.YESNO | ControlPointType.SELECT_FROM_LIST | ControlPointType.TIMESTAMP;
  ConditionDetails: TApiControlPointConditionSelectFromList;
  Audit?: boolean;
};

// ---

export type TApiControlPointEntity =
  | TApiControlPointNumber
  | TApiControlPointInterval
  | TApiControlPointDate
  | TApiControlPointYesNo
  | TApiControlPointSelectFromList
  | TApiControlPointTimestamp;

export type TApiControlPointConditionDetails =
  | TApiControlPointConditionNumber
  | TApiControlPointConditionInterval
  | TApiControlPointConditionDate
  | TApiControlPointConditionYesNo
  | TApiControlPointConditionSelectFromList
  | TApiControlPointConditionTimestamp;

export interface TOpenInstance {
  ID: number;
  ControlID: number | null;
  CheckpointID: number;
  SipID: number | null;
  ScheduleID: number | null;
  IsReminder: boolean;
  ConditionDetails: TApiControlPointConditionDetails | null;
  ForcedTitle: string | null;
  ForcedNotes: string | null;
  Order: number;
  DueEndTime?: string | null;
  DueStartTime?: string | null;
}

type TControlPointInstanceBase = {
  ID?: number;
  ActionSetID?: number;
  CustomFields?: Array<TApiControlPointCustomField>;
  DropdownListID?: number;
  ForcedTitle?: string | null;
  ForcedNotes?: string | null;
  DueEndTime?: string | null;
  DueStartTime?: string | null;
  Internal?: boolean;
  IsReminder?: boolean;
  PhotoMode?: number;
  ScheduleID?: number;
  TreeNode?: TApiTreeNodeEntityInSip;
  ControlGroupID?: number;
  WorkflowStep?: number | null;
};

export type TControlPointInstance<
  TControlType extends TControlPointTypeBase
> = TControlPointInstanceBase & TControlType;

export interface TReminderInstance {
  controlGroup: number | null;
  controlPoint: number | null;
  endTime: Date | null;
  forcedNotesText: string;
  forcedTitleText: string;
  performStatus: boolean;
  startTime: Date | null;
}

export interface TControlPointData {
  notes: string;
  value: any;
  customFields: any;
  conditionIsTrue: boolean;
  photos?: Array<any>;
  additionalData?: any;
}

export interface TAuditData extends TControlPointData {
  isSameAsLastAudit: boolean;
  sameAsLastAuditNotes: string;
}

export type TControlPointAction = (
  data: TControlPointData,
  photos?: Array<any>
) => void;
export type TControlPointCustomFieldsAction = (
  title: string,
  index: number
) => void;

export enum DismissInstance {
  REQUEST = "DISMISS_INSTANCE_REQUEST",
  SUCCESS = "DISMISS_INSTANCE_SUCCESS",
  FAILED = "DISMISS_INSTANCE_FAILED"
}

export type TDismissInstanceAction =
  | {
      type: DismissInstance.REQUEST;
    }
  | {
      type: DismissInstance.SUCCESS;
      payload: AxiosResponse;
    }
  | {
      type: DismissInstance.FAILED;
      payload: AxiosResponse;
    };
