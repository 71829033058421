import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TSipState } from "../../types/Sip";
import AuditSip from "./controls/AuditSip";
import EmptyPage from "../pages/EmptyPage";
import { trans } from "../../generic/Helpers";
import RegularSip from "./controls/RegularSip";
import SipGeoLock from "./SipGeoLock";

interface ISipBaseProps extends RouteComponentProps<any> {
  sip: TSipState;
}

const SipBase: React.FC<ISipBaseProps> = ({ sip }) => {
  const presentSip = (sip: TSipState) => {
    if (sip.data && sip.data.contents.length === 0) {
      return <EmptyPage text={trans("sip-empty-contents")} />;
    }

    if (sip.data && sip.data.node.Auditable) {
      return <AuditSip sip={sip} />;
    }

    return <RegularSip sip={sip} />;
  };

  return <SipGeoLock sip={sip}>{presentSip(sip)}</SipGeoLock>;
};

export default withRouter(SipBase);
