import { AxiosResponse } from "axios";

export interface IAuditSite {
  ID: number;
  Identifier: string;
  Name: string;
  Address: string;
  City: string;
  Phone: string;
  Email: string;
}

export interface TAuditRecipient {
  ID: number;
  Name: string;
  Email: string;
}

export interface TRecipientState {
  ID?: number;
  name: string;
  email: string;
}

export interface IAuditState {
  readonly isFetching?: boolean;
  readonly data?: {
    sites?: IAuditSite[];
    info?: any;
    controls?: IAuditControlGroup[];
    recipients?: any;
    status?: {
      AuditTime: string,
      UniqueID: string,
      Status: AuditStatus
    };
  };
}

export interface IAuditControlGroupNode {
  ID: number;
  Name: string;
  ParentID: number;
  SipID: number;
  TreeLeft: number;
  TreeRight: number;
}

export interface IAuditControlPointNode {
  ID: number;
  Name: string;
  ParentID: number;
  SipID: number;
  TreeLeft: number;
  TreeRight: number;
  Notes: string;
}

export interface IAuditControlPoint {
  ControlPointNode: IAuditControlPointNode;
  ControlInstance: IAuditControlPointInstance;
}

export interface IAuditControlGroup {
  ControlGroupNode: IAuditControlGroupNode;
  ControlPointStatuses: IAuditControlPoint[];
}

export interface IAuditControlPointInstance {
  ID: number;
  ControlID: number;
  CheckpointID: number | null;
  SipID: number | null;
  ScheduleID: number | null;
  IsReminder: boolean;
  DueStartTime: Date;
  DueEndTime: Date;
  UserInput: string;
  UserNotes: string;
  ForcedTitle: string | null;
  ForcedNotes: string | null;
  SameAsLastAuditNotes: null | string;
}

export const initialAuditState: IAuditState = {
  isFetching: false,
  data: undefined
};

export enum GetAuditSites {
  REQUEST = "GET_AUDIT_SITES_REQUEST",
  SUCCESS = "GET_AUDIT_SITES_SUCCESS",
  FAILED = "GET_AUDIT_SITES_FAILED"
}

export interface IGetAuditSitesAction {
  type: GetAuditSites.REQUEST | GetAuditSites.SUCCESS | GetAuditSites.FAILED;
  payload: AxiosResponse;
}
export enum GetAuditConfig {
  REQUEST = "GET_AUDIT_CONFIG_REQUEST",
  SUCCESS = "GET_AUDIT_CONFIG_SUCCESS",
  FAILED = "GET_AUDIT_CONFIG_FAILED"
}

export interface IGetAuditConfig {
  type: GetAuditConfig.REQUEST | GetAuditConfig.SUCCESS | GetAuditConfig.FAILED;
  payload: AxiosResponse;
}

export enum StartAudit {
  REQUEST = "START_AUDIT_REQUEST",
  SUCCESS = "START_AUDIT_SUCCESS",
  FAILED = "START_AUDIT_FAILED"
}

export interface IStartAuditAction {
  type: StartAudit.REQUEST | StartAudit.SUCCESS | StartAudit.FAILED;
  payload: AxiosResponse;
}

export enum GetAuditControls {
  REQUEST = "GET_AUDIT_CONTROLS_REQUEST",
  SUCCESS = "GET_AUDIT_CONTROLS_SUCCESS",
  FAILED = "GET_AUDIT_CONTROLS_FAILED"
}

export type IGetAuditControlsResponse = {
  controlGroups: Array<IAuditControlGroup>;
};

export type IGetAuditControlsAction =
  | {
      type: GetAuditControls.REQUEST;
    }
  | {
      type: GetAuditControls.SUCCESS;
      payload: AxiosResponse<IGetAuditControlsResponse>;
    }
  | {
      type: GetAuditControls.FAILED;
    };

export enum RestartAuditControls {
  REQUEST = "RESTART_AUDIT_CONTROLS_REQUEST",
  SUCCESS = "RESTART_AUDIT_CONTROLS_SUCCESS",
  FAILED = "RESTART_AUDIT_CONTROLS_FAILED"
}

export interface IRestartAuditControls {
  type:
    | RestartAuditControls.REQUEST
    | RestartAuditControls.SUCCESS
    | RestartAuditControls.FAILED;
  payload: AxiosResponse;
}

export enum CommitAuditControls {
  REQUEST = "COMMIT_AUDIT_CONTROLS_REQUEST",
  SUCCESS = "COMMIT_AUDIT_CONTROLS_SUCCESS",
  FAILED = "COMMIT_AUDIT_CONTROLS_FAILED"
}

export interface ICommitAuditControls {
  type:
    | CommitAuditControls.REQUEST
    | CommitAuditControls.SUCCESS
    | CommitAuditControls.FAILED;
  payload: AxiosResponse;
}

export enum SetAuditNotes {
  REQUEST = "SET_AUDIT_NOTES_REQUEST",
  SUCCESS = "SET_AUDIT_NOTES_SUCCESS",
  FAILED = "SET_AUDIT_NOTES_FAILED"
}

export interface ISetAuditNotes {
  type: SetAuditNotes.REQUEST | SetAuditNotes.SUCCESS | SetAuditNotes.FAILED;
  payload: AxiosResponse;
}

export enum GetAuditRecipients {
  REQUEST = "GET_AUDIT_RECIPIENTS_REQUEST",
  SUCCESS = "GET_AUDIT_RECIPIENTS_SUCCESS",
  FAILED = "GET_AUDIT_RECIPIENTS_FAILED"
}

export interface IGetAuditRecipients {
  type:
    | GetAuditRecipients.REQUEST
    | GetAuditRecipients.SUCCESS
    | GetAuditRecipients.FAILED;
  payload: AxiosResponse;
}

export enum FinishAudit {
  REQUEST = "FINISH_AUDIT_REQUEST",
  SUCCESS = "FINISH_AUDIT_SUCCESS",
  FAILED = "FINISH_AUDIT_FAILED"
}

export interface IFinishAudit {
  type: FinishAudit.REQUEST | FinishAudit.SUCCESS | FinishAudit.FAILED;
  payload: AxiosResponse;
}
export enum GetAuditStatus {
  REQUEST = "GET_AUDIT_STATUS_REQUEST",
  SUCCESS = "GET_AUDIT_STATUS_SUCCESS",
  FAILED = "GET_AUDIT_STATUS_FAILED"
}

export interface IGetAuditStatus {
  type: GetAuditStatus.REQUEST | GetAuditStatus.SUCCESS | GetAuditStatus.FAILED;
  payload: AxiosResponse;
}

export enum SetAdditionalRecipient {
  SUCCESS = "SET_ADDITIONAL_RECIPIENT"
}

export interface ISetAdditionalRecipient {
  type: SetAdditionalRecipient.SUCCESS;
  payload: any;
}

export enum AuditStatus {
  AUDITOR_INITIAL_SETUP = 11,
  AUDITOR_PERFORMING_CONTROLS = 12,
  AUDITOR_REVIEWING_CONTROLS = 13,
  AUDITOR_MAKING_NOTES = 14,
  AUDITOR_SETTING_RESULTS = 15
}

export type AuditActions =
  | IGetAuditSitesAction
  | IStartAuditAction
  | IGetAuditControlsAction
  | ICommitAuditControls
  | ISetAuditNotes
  | IGetAuditRecipients
  | IGetAuditConfig
  | ISetAdditionalRecipient
  | IGetAuditStatus
  | IRestartAuditControls;
