import * as React from "react";
import { RouteComponentProps, Prompt } from "react-router";
import Navbar from "../nav/Navbar";
import { trans } from "../../generic/Helpers";
import { generateUrl } from "../../generic/Requests";
import { FormEvent, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { createReminder } from "../../actions/ControlPointActions";
import { IAppState } from "../../store/Store";
import { useRepeatControlGroupForm } from "../../hooks/useRepeatControlGroupForm";

interface IReminderProps extends RouteComponentProps<any> {}

const Reminder: React.FC<IReminderProps> = (props): JSX.Element => {
  const {
    customerId,
    sipId,
    controlGroupId,
    controlPointId
  } = props.match.params;
  const now = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(now.getDate() + 1);
  const {
    instance,
    instanceRequest,
    data,
    redirectUrl,
    isFromRepeatControlGroup = false
  } = props.location.state;
  const { setFormFields, forms } = useRepeatControlGroupForm();
  const repeatForm = forms[controlPointId];
  const repeatReminderForm =
    isFromRepeatControlGroup && repeatForm && repeatForm.reminder;
  const [reminderFormFields, setReminderFormFields] = useState<any>(
    repeatReminderForm || {
      notes: "",
      title: "",
      startDate: now,
      endDate: tomorrow
    }
  );
  React.useEffect(() => {
    if (isFromRepeatControlGroup && !forms[controlPointId]) {
      props.history.push({
        pathname: generateUrl(customerId, sipId, controlGroupId, `repeat`)
      });
    }
  });

  const setNotes = (notes: any) =>
    setReminderFormFields({ ...reminderFormFields, notes });
  const setTitle = (title: any) =>
    setReminderFormFields({ ...reminderFormFields, title });
  const setStartDate = (startDate: any) =>
    setReminderFormFields({ ...reminderFormFields, startDate });
  const setEndDate = (endDate: any) =>
    setReminderFormFields({ ...reminderFormFields, endDate });

  const dispatch = useDispatch<Dispatch<any>>();

  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(minDate.getDate() + 30);

  const handleFormSubmit = (e: FormEvent) => {
    const reminderFromDeviation =
      customerId && controlGroupId && controlPointId;
    e.preventDefault();
    let reminderInstance = {
      controlGroup: controlPointId ? null : controlGroupId,
      controlPoint: controlPointId ? controlPointId : null,
      endTime: reminderFormFields.endDate
        ? reminderFormFields.endDate.toISOString()
        : null,
      forcedNotesText: reminderFormFields.notes,
      forcedTitleText: reminderFormFields.title,
      performStatus: instanceRequest.performStatus,
      startTime: reminderFormFields.startDate
        ? reminderFormFields.startDate.toISOString()
        : null
    };
    if (isFromRepeatControlGroup) {
      setFormFields(instance && instance.TreeNode && instance.TreeNode.ID, {
        reminder: { ...reminderFormFields },
        completed: true
      });
      return props.history.push({
        pathname: generateUrl(customerId, sipId, controlGroupId, `repeat`)
      });
    }
    if (reminderFromDeviation) {
      dispatch(
        createReminder(reminderInstance, () => {
          if (controlGroupId) {
            props.history.push(generateUrl(customerId, sipId, controlGroupId));
          }
        })
      );
    } else {
      dispatch(
        createReminder(reminderInstance, () => {
          const finalRedirectUrl =
            redirectUrl || generateUrl(customerId, sipId, controlGroupId);
          props.history.push(finalRedirectUrl);
        })
      );
    }
  };

  const handlePrompt = (location: any) => {
    if (isFromRepeatControlGroup) {
      const repeatPathname = generateUrl(
        customerId,
        sipId,
        controlGroupId,
        `repeat`
      );
      if ([repeatPathname].includes(location.pathname)) {
        return true;
      }
      const hasEditedForm =
        isFromRepeatControlGroup &&
        !!forms[controlPointId] &&
        !!forms[controlPointId].createReminder;
      if (hasEditedForm) {
        return trans("repeat-control-group-point-reminder-unfinished");
      }
    }
    return true;
  };
  const createReminderLoader = useSelector<IAppState, any>(
    state => state.loader.createReminder
  );

  return (
    <>
      <Navbar
        headerText={trans("page-create-reminder-title")}
        backLink={() => {
          props.history.push({
            pathname: generateUrl(customerId, sipId, controlGroupId),
            state: {
              instance,
              instanceRequest,
              data
            }
          });
        }}
      />
      <Prompt when message={handlePrompt} />
      <section>
        <form
          className="form-group form-vertical reminder-form"
          onSubmit={handleFormSubmit}
        >
          <div className="text-block">
            <label htmlFor="start">{trans("input-reminder-start-time")}</label>
            <div className="input-group mb-3">
              <ReactDatePicker
                selected={reminderFormFields.startDate}
                onChange={e => setStartDate(e)}
                showTimeSelect={true}
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="time"
                minDate={minDate}
                maxDate={maxDate}
                className="inp"
              />
            </div>
            <label htmlFor="end">{trans("input-reminder-end-time")}</label>
            <div className="input-group mb-3">
              <ReactDatePicker
                selected={reminderFormFields.endDate}
                onChange={e => setEndDate(e)}
                showTimeSelect={true}
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="time"
                minDate={minDate}
                maxDate={maxDate}
                className="inp"
              />
            </div>
          </div>

          <div className="text-block">
            <label htmlFor="title">{trans("input-reminder-title")}</label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="inp"
                id="title"
                value={reminderFormFields.title}
                onChange={e => setTitle(e.target.value)}
                placeholder={trans("input-enter-reminder-title")}
                required={true}
              />
            </div>
            <label htmlFor="notes">{trans("input-reminder-notes")}</label>
            <div className="input-group mb-3">
              <textarea
                name="notes"
                id="notes"
                className="textarea"
                value={reminderFormFields.notes}
                onChange={e => setNotes(e.target.value)}
                placeholder={trans("input-enter-reminder-notes")}
                required={true}
              />
            </div>
          </div>

          <button
            disabled={createReminderLoader.isFetching}
            type="submit"
            className="btn btn-primary btn-login mt-3"
          >
            {trans("input-save-create-reminder")}
          </button>
        </form>
      </section>
    </>
  );
};

export default Reminder;
