import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TControlGroupState } from "../../types/ControlGroup";
import { ControlGroupType } from "../../generic/Config";
import Regular from "./controls/Regular";
import Workflow from "./controls/Workflow";
import Formula from "./controls/Formula";
import EmptyPage from "../pages/EmptyPage";
import { trans } from '../../generic/Helpers';
import { isEmpty } from 'lodash';

interface IControlGroupBaseProps extends RouteComponentProps<any> {
  controlGroup: TControlGroupState;
}

const ControlGroupBase: React.FC<IControlGroupBaseProps> = ({
  controlGroup
}) => {
  if (
    controlGroup.data &&
    isEmpty(controlGroup.data.openInstances) &&
    isEmpty(controlGroup.data.unscheduledControls)
  ) {
    return <EmptyPage text={trans('no-active-control-points')} />;
  }
  switch (controlGroup.data && controlGroup.data.node.CheckpointType) {
    case ControlGroupType.FORMULA:
      return <Formula controlGroup={controlGroup} />;

    case ControlGroupType.WORKFLOW:
      return <Workflow controlGroup={controlGroup} />;

    default:
      return <Regular controlGroup={controlGroup} />;
  }
};

export default withRouter(ControlGroupBase);
