import * as React from "react";
import { trans } from "../../generic/Helpers";
import { Link, RouteComponentProps } from "react-router-dom";
import { generateUrl } from "../../generic/Requests";
import Navbar from "../nav/Navbar";
import { useRequest } from "../../hooks/useRequest";
import Alert from "../Alert";

const Success: React.FC<RouteComponentProps<any>> = ({
  match: {
    params: { customerId }
  }
}): JSX.Element => {
  const { app } = useRequest();
  return (
    <>
      <Navbar headerText={trans("audit-success-page-title")} />
      {app.hasError() && <Alert type={"danger"} text={app.getError()} error={true}/>}
      <div className="text-block">{trans("audit-success-message")}</div>
      <div className="d-flex justify--center">
        <Link
          to={generateUrl(customerId, undefined, undefined)}
          className="btn btn-primary btn-small mt-3"
        >
          {trans("audit-send-emails-and-proceed")}
        </Link>
      </div>
    </>
  );
};

export default Success;
