import * as React from "react";
import Alert from "../Alert";
import { trans } from "../../generic/Helpers";
import { RouteComponentProps, withRouter } from "react-router";

interface ISipGeoLockErrorProps extends RouteComponentProps<any> {
  alertTitle: string;
  errorExplanation?: string;
  canRetry: boolean;
  tryAgain: () => void;
  goBack: () => void;
}

const SipGeoLockError: React.FC<ISipGeoLockErrorProps> = ({
  alertTitle,
  errorExplanation,
  canRetry,
  tryAgain,
  goBack
}) => {
  return (
    <>
      <Alert type={"danger"} text={alertTitle} />
      {errorExplanation && <div className="text-block">{errorExplanation}</div>}
      <div className="actions-bar">
        {canRetry && (
          <div className="actions-bar-item">
            <button
              type="button"
              className="btn btn-small btn-secondary"
              onClick={tryAgain}
            >
              {trans("input-try-again")}
            </button>
          </div>
        )}
        <div className="actions-bar-item">
          <button
            type="button"
            className="btn btn-small btn-primary"
            onClick={goBack}
          >
            {trans("global-go-back")}
          </button>
        </div>
      </div>
    </>
  );
};

export default withRouter(SipGeoLockError);
