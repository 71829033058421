import * as React from "react";
import { isEmpty } from "lodash";
import {
  getLocalStorage,
  keyExists,
  userIsAuthenticated
} from "../../generic/Helpers";
import { IAuthAction, IAuthState } from "../../types/Auth";
import { IAppState } from "../../store/Store";
import LoginContainer from "../../containers/auth/LoginContainer";
import { connect } from "react-redux";
import { AsyncThunkAction } from "../../types/Requests";
import { bindActionCreators } from "redux";
import {
  checkIfTokenIsValid,
  logoutUser,
  getTranslations
} from "../../actions/AuthActions";
import { getCustomer } from "../../actions/CustomerActions";
import { TGetTranslation } from "../../types/Translation";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { TCustomerState, TGetCustomerAction } from "../../types/Customer";
import { config } from "../../generic/Config";

interface IStateProps {
  authState: IAuthState;
}

interface IDispatchProps {
  logoutUser: () => Promise<any>;
  checkIfTokenIsValid: () => AsyncThunkAction<IAuthState, IAuthAction>;
  getCustomer: (
    customerId: number
  ) => AsyncThunkAction<TCustomerState, TGetCustomerAction>;
  getTranslations: (
    langCode: string
  ) => AsyncThunkAction<IAppState, TGetTranslation>;
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps<any>;

export const authenticated = (Component: React.ComponentType) => {
  class requiresAuthentication extends React.Component<IProps> {
    public render() {
      if (keyExists("token") && isEmpty(this.props.authState.data)) {
        this.props.checkIfTokenIsValid();
        if(this.props.authState && this.props.authState.data) {
          this.props.getTranslations(
            this.props.authState.data.Language
          );
        } else {
          this.props.getTranslations(
            keyExists("lang") ? getLocalStorage("lang") : config.DEFAULT_LANG
          );
        }
      }
      const match: any = matchPath(this.props.history.location.pathname, {
        path: "/app/customer/:customerId",
        exact: false,
        strict: false
      });
      if (match && match.params.customerId && !this.props.authState.data) {
        this.props.getCustomer(parseInt(match.params.customerId));
      }
      return userIsAuthenticated(this.props.authState) ? (
        <Component />
      ) : (
        <LoginContainer />
      );
    }
  }

  const mapStateToProps = (state: IAppState): IStateProps => {
    return {
      authState: state.authState
    };
  };

  const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return bindActionCreators(
      { checkIfTokenIsValid, logoutUser, getTranslations, getCustomer },
      dispatch
    );
  };

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(requiresAuthentication)
  );
};
