import { AsyncThunkAction } from "../types/Requests";
import { apiCall, asyncRequest } from "../generic/Requests";
import {
  TGetSipAction,
  TGetSipControlGroupsAction,
  TGetSipListAction,
  TSipListState,
  TSipState,
  Sip,
  SipControlGroups,
  SipList,
  TApiSipList,
  TApiSipControlGroups
} from "../types/Sip";
import {
  GetReportProgress,
  TGetReportProgress,
  TInitReportAction,
  ReportInit
} from "../types/Report";
import { Dispatch } from "redux";
import {
  GeoLock,
  TCircularGeofence,
  TGeoLockAction,
  TGeoPermissionsState,
  TGeoReadingFailureState
} from "../types/GeoLock";
import { validateGeoFencing } from "../generic/GeoFencing";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from "../generic/Helpers";
import { TApiTreeContent } from "../types/Tree";
import { TApiReportTask } from "../types/Task";

export const getSipList = (
  id: number
): AsyncThunkAction<TSipListState, TGetSipListAction> => {
  return asyncRequest(
    SipList,
    apiCall<null, TApiSipList>("GET", `/customer/${id}/sips`, null, true),
    null
  );
};

export const getSip = (
  id: number,
  callback?: (...args: any) => any
): AsyncThunkAction<TSipState, TGetSipAction> => {
  return asyncRequest(
    Sip,
    apiCall<null, TApiTreeContent>("GET", `/tree/${id}`, null, true),
    callback
  );
};

export const getSipControlGroups = (
  id: number
): AsyncThunkAction<TSipState, TGetSipControlGroupsAction> => {
  return asyncRequest(
    SipControlGroups,
    apiCall<null, TApiSipControlGroups>(
      "GET",
      `/sip/${id}/controlGroups`,
      null,
      true
    ),
    null
  );
};

export const initReport = (
  config: any,
  callback?: (...args: any) => any
): AsyncThunkAction<TSipState, TInitReportAction> => {
  return asyncRequest(
    ReportInit,
    apiCall<any, TApiReportTask>(
      "POST",
      `/reporting/folderReport`,
      config,
      true
    ),
    callback
  );
};

export const getReportProgress = (
  query: string | undefined,
  callback?: (...args: any) => any
): AsyncThunkAction<TSipState, TGetReportProgress> => {
  return asyncRequest(
    GetReportProgress,
    apiCall<null, TApiReportTask>(
      "GET",
      `/async/query?id=${query}`,
      null,
      true
    ),
    callback
  );
};

export const clearReportData = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ReportInit.CLEAR
    });
  };
};

export const clearGeolocation = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GeoLock.CLEAR
    });
  };
};

export const checkGeolocationPermissions = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GeoLock.CHECK_PERMISSIONS
    });

    const fallback = () => {
      const saved = checkGeolocationSavedPermissions();
      dispatch({
        type: GeoLock.RESOLVE_PERMISSIONS,
        payload: saved
      });
    };

    if ((navigator as any).permissions) {
      try {
        (navigator as any).permissions
          .query({
            name: "geolocation"
          })
          .then((status: any) => {
            switch (status.state) {
              case "granted":
                dispatch({
                  type: GeoLock.RESOLVE_PERMISSIONS,
                  payload: TGeoPermissionsState.GRANTED
                });
                return;

              case "denied":
                dispatch({
                  type: GeoLock.RESOLVE_PERMISSIONS,
                  payload: TGeoPermissionsState.DENIED
                });
                return;

              case "prompt":
              default:
                dispatch({
                  type: GeoLock.RESOLVE_PERMISSIONS,
                  payload: TGeoPermissionsState.NOT_SET
                });
            }
            return;
          });
      } catch (e) {
        fallback();
      }
    } else {
      fallback();
    }
  };
};

const geolocationSaveKey = "geolocation.permission";

const checkGeolocationSavedPermissions = (): TGeoPermissionsState => {
  const savedValue = getLocalStorage(geolocationSaveKey);
  if (!savedValue) {
    return TGeoPermissionsState.NOT_SET;
  }

  try {
    const parsedValue = JSON.parse(savedValue);

    const { value } = parsedValue;

    switch (value) {
      case TGeoPermissionsState.GRANTED:
      case TGeoPermissionsState.DENIED:
        return value;

      default:
        return TGeoPermissionsState.NOT_SET;
    }
  } catch (e) {
    clearGeolocationPermissions();
    return TGeoPermissionsState.NOT_SET;
  }
};

export const saveGeolocationPermissions = (state: TGeoPermissionsState) => {
  const valueToSave = { value: state };
  setLocalStorage(geolocationSaveKey, JSON.stringify(valueToSave));
};

export const clearGeolocationPermissions = () => {
  removeLocalStorage(geolocationSaveKey);
};

export const startGeolocation = (
  geofence: TCircularGeofence
): AsyncThunkAction<any, TGeoLockAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GeoLock.REQUEST
    });

    if (!navigator.geolocation) {
      dispatch({
        type: GeoLock.FAILURE,
        payload: TGeoReadingFailureState.GEOLOCATION_UNAVAILABLE
      });
      return;
    }

    validateGeoFencing(geofence).then(
      result => {
        dispatch({
          type: GeoLock.SUCCESS,
          payload: result
        });
      },
      err => {
        dispatch({
          type: GeoLock.FAILURE,
          payload: err
        });
      }
    );
  };
};
