import { AxiosResponse } from "axios";

interface TApiDeviationFailureReason {
  ID: number;
  Text: string;
  Ordering: number;
}

interface TApiDeviationRemedyingAction {
  ID: number;
  Text: string;
  Ordering: number;
}

interface TApiDeviationEntity {
  ID: number;
  Name: string;
  FailureReasons: Array<TApiDeviationFailureReason>;
  RemedyingActions: Array<TApiDeviationRemedyingAction>;
}

export interface TApiDeviation {
  deviation: TApiDeviationEntity;
}

export interface TDeviationState {
  readonly isFetching?: boolean;
  readonly data?: TApiDeviation;
}

export const initialDeviationState: TDeviationState = {
  isFetching: false,
  data: undefined
};

export enum GetDeviation {
  REQUEST = "GET_DEVIATION_REQUEST",
  SUCCESS = "GET_DEVIATION_SUCCESS",
  FAILED = "GET_DEVIATION_FAILED"
}

export type TGetDeviationAction = {
  type: GetDeviation.REQUEST;
} | {
  type: GetDeviation.SUCCESS;
  payload: AxiosResponse<TApiDeviation>;
} | {
  type: GetDeviation.FAILED;
  payload: AxiosResponse;
}

export type DeviationActions = TGetDeviationAction;
