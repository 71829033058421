import * as React from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import {getApiResponseFieldError, trans} from "../../generic/Helpers";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { changePassword} from "../../actions/AuthActions";
import InputError from "../inputError";
import Loading from "../Loading";

interface IPasswordChangeProps extends RouteComponentProps<any> {}

const PasswordChange: React.FC<IPasswordChangeProps> = ({history}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();

  const [isLoading, setIsLoading] = useState(false);

  const [currentPasswordValue, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordValue, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");

  const handlePasswordChange = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    const res = dispatch(changePassword(currentPasswordValue, newPasswordValue, confirmNewPassword));
    const loader = (res as any) as Promise<any>;
    return loader.then(() => {
      history.push(`/app`);
    }, (err: any) => {
      if (err && err.errors) {
        const errors = err.errors.children;
        setCurrentPasswordError(getApiResponseFieldError(errors, 'currentPassword'));
        setNewPasswordError(getApiResponseFieldError(errors, 'newPassword'));
        setConfirmNewPasswordError(getApiResponseFieldError(errors, 'confirmation'));
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Navbar
        headerText={trans("page-password-change-title")}
        backLink={() => {
          history.goBack();
        }}
      />
      <section>
        {isLoading && <Loading />}
        <div className="text-block">
          <form className="form-group form-vertical" onSubmit={handlePasswordChange}>
            <div className="text-block">
              <label htmlFor="currentPassword">
                {trans("input-label-current-password")}
              </label>
              <div className="input-group">
              <input
                type="password"
                className="inp"
                id="currentPassword"
                value={currentPasswordValue}
                required={true}
                onChange={e => setCurrentPassword(e.target.value)}
                autoComplete="current-password"
              />
              </div>
              <InputError message={currentPasswordError} />
            </div>
            <div className="text-block">
              <label htmlFor="newPassword">
                {trans("input-label-new-password")}
              </label>
              <div className="input-group">
              <input
                type="password"
                className="inp"
                id="newPassword"
                value={newPasswordValue}
                required={true}
                minLength={4}
                onChange={e => setNewPassword(e.target.value)}
                autoComplete="new-password"
              />
              </div>
              <InputError message={newPasswordError} />
            </div>
            <div className="text-block">
              <label htmlFor="confirmNewPassword">
                {trans("input-label-confirm-new-password")}
              </label>
              <div className="input-group">
              <input
                type="password"
                className="inp"
                id="confirmNewPassword"
                value={confirmNewPassword}
                required={true}
                minLength={4}
                onChange={e => setConfirmNewPassword(e.target.value)}
                autoComplete="new-password"
              />
              </div>
              <InputError message={confirmNewPasswordError} />
            </div>
            <div className="input-group mt-3 d-flex justify--center">
              <button type="submit" className="btn btn-primary btn-small">
                {trans("input-password-change-save")}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default PasswordChange;
