import * as React from "react";
import menuIcon from "../../assets/images/menu.svg";
import backArrow from "../../assets/images/backward_arrow.svg";
import closeIcon from "../../assets/images/close.svg";
import { slide as Menu, State } from "react-burger-menu";
import { useState } from "react";
import MenuContent from "./MenuContent";
import { RouteComponentProps, withRouter } from "react-router";

interface INavbarProps extends RouteComponentProps<any> {
  headerText: string;
  backLink?: any;
  hideBackLink?: boolean;
  hideMenu?: boolean;
}

const Navbar: React.FC<INavbarProps> = (props): JSX.Element => {
  const [menu, openMenu] = useState<boolean>(false);

  const toggleMenu = () => {
    openMenu(!menu);
  };

  const handleMenuExternalChange = (menuState: State) => {
    const isOpen = menuState.isOpen;
    // the menu got closed by something outside our control
    // update our state to match
    if (menu && !isOpen) {
      openMenu(false);
    }
  };

  const handleBack = () => {
    if (typeof props.backLink === "function") {
      return props.backLink();
    }
    props.history.push(props.backLink);
  };

  return (
    <>
      {!props.hideMenu && (
        <Menu
          right
          isOpen={menu}
          pageWrapId={"page-wrapper"}
          outerContainerId={"content"}
          onStateChange={handleMenuExternalChange}
        >
          <MenuContent callback={toggleMenu} />
          <button onClick={toggleMenu} className="menu-toggle-icon">
            <img src={closeIcon} className="close-icon" alt="" />
          </button>
        </Menu>
      )}
      <div className="navbar" id="navbar">
        <div className="back-btn-container">
          {props.backLink && !props.hideBackLink && (
            <button className="btn btn-back" onClick={handleBack}>
              <img src={backArrow} alt="" />
            </button>
          )}
        </div>
        <h1 className="header">{props.headerText}</h1>
        {!props.hideMenu && (
          <button onClick={toggleMenu} className="toggle-menu">
            <img src={menuIcon} alt="Toggle menu" />
          </button>
        )}
      </div>
    </>
  );
};

export default withRouter(Navbar);
