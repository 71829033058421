import {
  AsyncThunkAction,
  CreateReminder,
  IAdHocInstance,
  ICreateReminderAction,
  IInstanceRequest,
  IPerformAdHocAction,
  IPerformInstanceAction,
  PerformAdHoc,
  PerformInstance
} from "../types/Requests";
import { IAppState } from "../store/Store";
import { apiCall, asyncRequest } from "../generic/Requests";
import {
  GetDeviation,
  TApiDeviation,
  TGetDeviationAction
} from "../types/Deviation";
import {
  DismissInstance,
  TDismissInstanceAction,
  TReminderInstance
} from "../types/ControlPoint";

export const performInstance = (
  instance: IInstanceRequest,
  callback?: (...args: any) => any
): AsyncThunkAction<IAppState, IPerformInstanceAction> => {
  return asyncRequest(
    PerformInstance,
    apiCall("POST", `/perform/instance`, instance, true),
    callback
  );
};

export const getDeviation = (
  id: number
): AsyncThunkAction<IAppState, TGetDeviationAction> => {
  return asyncRequest(
    GetDeviation,
    apiCall<null, TApiDeviation>("GET", `/deviation/${id}`, null, true),
    null
  );
};

export const createAdHoc = (
  instance: IAdHocInstance,
  callback?: (...args: any) => any
): AsyncThunkAction<IAppState, IPerformAdHocAction> => {
  return asyncRequest(
    PerformAdHoc,
    apiCall("POST", "/create/adhoc", instance, true),
    callback
  );
};

export const createReminder = (
  instance: TReminderInstance,
  callback?: (...args: any) => any
): AsyncThunkAction<IAppState, ICreateReminderAction> => {
  return asyncRequest(
    CreateReminder,
    apiCall("POST", "/create/reminder", instance, true),
    callback
  );
};

export const dismissInstances = (
  data: {},
  callback?: (...args: any) => any
): AsyncThunkAction<IAppState, TDismissInstanceAction> => {
  return asyncRequest(
    DismissInstance,
    apiCall("POST", `/dismiss/instances`, data, true),
    callback
  );
};
