import { AsyncThunkAction } from "../types/Requests";
import { apiCall, asyncRequest } from "../generic/Requests";
import {
  CommitAuditControls,
  FinishAudit,
  GetAuditConfig,
  GetAuditControls,
  GetAuditRecipients,
  GetAuditSites, GetAuditStatus,
  IAuditState,
  ICommitAuditControls,
  IFinishAudit,
  IGetAuditConfig,
  IGetAuditControlsAction,
  IGetAuditRecipients,
  IGetAuditSitesAction, IGetAuditStatus,
  IRestartAuditControls,
  ISetAuditNotes,
  IStartAuditAction,
  RestartAuditControls,
  SetAdditionalRecipient,
  SetAuditNotes,
  StartAudit
} from '../types/Audit';

export const getAuditSites = (
  id: number,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, IGetAuditSitesAction> => {
  return asyncRequest(
    GetAuditSites,
    apiCall("GET", `/audit/sites/${id}`, null, true),
    callback
  );
};

export const getAuditConfig = (
  id: number,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, IGetAuditConfig> => {
  return asyncRequest(
    GetAuditConfig,
    apiCall("GET", `/audit/config/${id}`, null, true),
    callback
  );
};

export const startAudit = (
  data: {},
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, IStartAuditAction> => {
  return asyncRequest(
    StartAudit,
    apiCall("POST", `/audit/start`, data, true),
    callback
  );
};

export const getAuditControls = (
  id: number,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, IGetAuditControlsAction> => {
  return asyncRequest(
    GetAuditControls,
    apiCall("GET", `/audit/${id}/controls`, null, true),
    callback
  );
};

export const restartAuditControls = (
  data: any,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, IRestartAuditControls> => {
  return asyncRequest(
    RestartAuditControls,
    apiCall("POST", `/audit/controls/restart`, data, true),
    callback
  );
};

export const commitAuditControls = (
  data: any,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, ICommitAuditControls> => {
  return asyncRequest(
    CommitAuditControls,
    apiCall("POST", `/audit/controls/commit`, data, true),
    callback
  );
};

export const sendAuditNotes = (
  data: any,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, ISetAuditNotes> => {
  return asyncRequest(
    SetAuditNotes,
    apiCall("POST", `/audit/notes`, data, true),
    callback
  );
};

export const getAuditRecipients = (
  sipId: number,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, IGetAuditRecipients> => {
  return asyncRequest(
    GetAuditRecipients,
    apiCall("GET", `/audit/recipients/${sipId} `, null, true),
    callback
  );
};

export const finishAudit = (
  data: any,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, IFinishAudit> => {
  return asyncRequest(
    FinishAudit,
    apiCall("POST", `/audit/finish `, data, true),
    callback
  );
};

export const getAuditStatus = (
  auditId: string,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuditState, IGetAuditStatus> => {
  return asyncRequest(
    GetAuditStatus,
    apiCall("GET", `/audit/${auditId}/status `, null, true),
    callback
  );
};

export const addAdditionalRecipient = (data: {}) => {
  return {
    type: SetAdditionalRecipient.SUCCESS,
    payload: data
  };
};
