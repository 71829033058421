import * as React from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import { trans } from "../../generic/Helpers";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {requestPasswordReset} from "../../actions/AuthActions";
import InputError from "../inputError";
import Alert from "../Alert";
import Loading from "../Loading";

interface IPasswordForgottenProps extends RouteComponentProps<any> {}

const PasswordForgotten: React.FC<IPasswordForgottenProps> = ({history}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();

  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const [currentEmailValue, setCurrentEmail] = useState("");
  const [currentEmailError, setEmailError] = useState("");

  const getFieldError = (fieldsInfo: Record<string, {errors?: string[];}>, field: string): string => {
    const fieldInfo = fieldsInfo[field];
    if (!fieldInfo || !fieldInfo.errors) {
      return "";
    }

    return fieldInfo.errors[0] || "";
  };

  const handleSubmission = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    const res = dispatch(requestPasswordReset(currentEmailValue));
    const loader = (res as any) as Promise<any>;
    return loader.then(() => {
      setIsCompleted(true);
    }, (err: any) => {
      if (err && err.errors) {
        const errors = err.errors.children;
        setEmailError(getFieldError(errors, 'email'));
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Navbar
        headerText={trans("page-forgotten-password-title")}
        backLink={() => {
          history.goBack();
        }}
        hideMenu={true}
      />
      <section>
        {isLoading && <Loading />}

        {isCompleted ? (
            <>
              <Alert type={"success"} text={trans("message-forgotten-password-sent")} />
              <div className="text-block">
                {trans("message-forgotten-password-sent-longtext")}
              </div>
            </>
        ) : (<>
            <div className="text-block">
              <form className="form-group form-vertical" onSubmit={handleSubmission}>
                <div className="text-block">
                  <label htmlFor="email">
                    {trans("input-label-user-email")}
                  </label>
                  <div className="input-group">
                  <input
                    type="email"
                    className="inp"
                    id="email"
                    value={currentEmailValue}
                    required={true}
                    onChange={e => setCurrentEmail(e.target.value)}
                    autoComplete="current-password"
                  />
                  </div>
                  <InputError message={currentEmailError} />
                </div>
                <div className="input-group mt-3 d-flex justify--center">
                  <button type="submit" className="btn btn-primary btn-small">
                    {trans("input-forgotten-password-submit")}
                  </button>
                </div>
              </form>
            </div>
            </>
          )}
      </section>
    </>
  );
};

export default PasswordForgotten;
