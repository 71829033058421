import React from "react";

import { trans } from "../../../generic/Helpers";

const CustomInputList = ({
  customFields,
  handleCustomField,
  disabled
}: {
  customFields: Array<any>;
  handleCustomField: any;
  disabled?: boolean;
}) => {
  return (
    <>
      {customFields.length > 0 && (
        <>
          <br />
          <>
            {customFields.map(
              (
                { ID: id, Name: name, Required: required, value = "" },
                index: number
              ) => {
                return (
                  <div
                    key={index}
                    className="input-group mt-3 custom-fields--block"
                  >
                    <label htmlFor={`fld-${id}`}>
                      {name}
                      {required ? "*" : ""}
                    </label>
                    <input
                      value={value}
                      type="text"
                      placeholder={trans("input-tap-to-enter-value")}
                      className="inp"
                      name={name}
                      id={`fld-${id}`}
                      required={required}
                      onChange={handleCustomField(index)}
                      disabled={disabled}
                    />
                  </div>
                );
              }
            )}
          </>
        </>
      )}
    </>
  );
};

export default CustomInputList;
