import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useEffect, useState } from "react";
import { IAppState } from "../store/Store";
import { TSipState } from "../types/Sip";
import Loading from "../components/Loading";
import { getLocalStorage, keyExists, trans } from "../generic/Helpers";
import Navbar from "../components/nav/Navbar";
import { getSip } from "../actions/SipActions";
import SipBase from "../components/sip/SipBase";
import EmptyPage from "../components/pages/EmptyPage";

interface ISipContainerProps extends RouteComponentProps<any> {}

const SipContainer: React.FC<ISipContainerProps> = ({
  match: {
    params: { customerId, sipId }
  },
  history
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const sip = useSelector<IAppState, TSipState>(
    (state: IAppState) => state.sipState
  );
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    dispatch(
      getSip(sipId, () => {
        setLoaded(true);
      })
    );
  }, [dispatch, sipId]);

  useEffect(() => {
    if (loaded && sip.data && sip.data.node.Auditable) {
      if (
        keyExists("currentAudit") &&
        keyExists("currentAuditId") &&
        parseInt(getLocalStorage("currentAuditId")) === parseInt(sipId)
      ) {
        history.push(
          window.location.pathname +
            `/audit/${JSON.parse(getLocalStorage("currentAudit")).UniqueID}`
        );
      }
    }
  }, [loaded, sip.data]);

  if (sip.data && sip.data.node.Auditable && sip.data.node.TreeNode !== sipId) {
    if (
      keyExists("currentAuditId") &&
      parseInt(getLocalStorage("currentAuditId")) !== sip.data.node.TreeNode.ID
    ) {
      return (
        <section>
          <Navbar
            headerText={sip.data ? sip.data.node.TreeNode.Name : "Sip Tree"}
            backLink={`/app/customer/${customerId}`}
          />
          <EmptyPage text={trans("another-audit-is-in-progress")} />
        </section>
      );
    }
  }

  return (
    <section>
      <Navbar
        headerText={sip.data ? sip.data.node.TreeNode.Name : "Sip Tree"}
        backLink={`/app/customer/${customerId}`}
      />
      {(sip && sip.isFetching) || !loaded ? (
        <Loading text={trans("global-loading")} />
      ) : (
        <SipBase sip={sip} />
      )}
    </section>
  );
};

export default SipContainer;
