import { RouteComponentProps, withRouter } from "react-router";
import { TControlGroupState } from "../../../types/ControlGroup";
import * as React from "react";
import {
  TApiControlPointEntity,
  TControlPointInstance, TControlPointTypeBase
} from "../../../types/ControlPoint";
import {
  generateAdHocInstance,
  generateControlPointInstance, getDocumentation,
  hasOpenInstance,
  hasUnscheduledControl
} from "../../../generic/Helpers";
import ControlPointBase from "../../control-point/ControlPointBase";
import ListItem from "../../ListItem";
import { isEmpty, findKey } from "lodash";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { IAdHocInstance } from "../../../types/Requests";
import { getControlGroup } from "../../../actions/ControlGroupActions";

interface IFormulaProps extends RouteComponentProps<any> {
  controlGroup: TControlGroupState;
}

const Formula: React.FC<IFormulaProps> = ({
  controlGroup,
  match: {
    params: { controlGroupId }
  }
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const availableToPerform = (): any => {
    let array: {} = {};
    if (controlGroup.data) {
      controlGroup.data.contents.forEach((controlPoint: TApiControlPointEntity) => {
        if (
          (controlGroup.data?.openInstances[controlPoint.TreeNode.ID]) ||
          (controlGroup.data?.unscheduledControls[controlPoint.TreeNode.ID])
        ) {
          if (controlGroup.data.controlStatuses) {
            array = {
              ...array,
              [controlPoint.TreeNode.ID]: controlGroup.data.controlStatuses[
                controlPoint.TreeNode.ID
              ]
                ? controlGroup.data.controlStatuses[controlPoint.TreeNode.ID]
                : null
            };
          }
        }
      });
    }
    return array;
  };
  const controlStatuses: any =
    controlGroup.data && controlGroup.data?.controlStatuses;
  const availableControls = availableToPerform();

  const isDisabledControl = (controlPoint: TApiControlPointEntity, index: number) => {
    if (controlGroup.data && availableControls) {
      if (isEmpty(availableControls)) {
        return index !== 0;
      } else {
        const firstEnabledControl = findKey(
          availableControls,
          (s: number | null) => {
            return s === null || s === 1;
          }
        );
        if (firstEnabledControl) {
          return controlPoint.TreeNode.ID !== parseInt(firstEnabledControl);
        } else {
          return false;
        }
      }
    }

    return false;
  };
  const isCompletedControl = (controlPoint: TApiControlPointEntity, index: number) => {
    if (!isEmpty(controlStatuses)) {
      return controlStatuses[controlPoint.TreeNode.ID] !== null;
    }
  };
  const content = (
    controlPoint: TApiControlPointEntity,
    instance: TControlPointInstance<TControlPointTypeBase> | IAdHocInstance
  ) => {
    if (hasOpenInstance(controlGroup, controlPoint)) {
      return <ControlPointBase instance={instance} />;
    } else {
      return (
        <ControlPointBase
          performAdHoc={true}
          instance={instance}
          callback={() => dispatch(getControlGroup(controlGroupId))}
        />
      );
    }
  };

  let counter = 0;

  return (
    <div className="item-list">
      {controlGroup.data &&
        controlGroup.data.contents.map(
          (controlPoint: TApiControlPointEntity, index: number) => {
            const isCompleted = isCompletedControl(controlPoint, index);
            const isDisabled = isDisabledControl(controlPoint, index);
            const formulaClassName = isCompleted
              ? "text-success"
              : !isDisabled
              ? "text-info"
              : "text-disabled";
            let instance: TControlPointInstance<TControlPointTypeBase> | IAdHocInstance;
            if (hasOpenInstance(controlGroup, controlPoint)) {
              instance = generateControlPointInstance(
                controlGroup,
                controlGroup.data &&
                  controlGroup.data.openInstances[controlPoint.TreeNode.ID]
              );
            } else {
              instance = generateAdHocInstance(controlPoint);
            }
            if (
              controlStatuses[controlPoint.TreeNode.ID] !== undefined ||
              (isEmpty(controlStatuses) &&
                (hasOpenInstance(controlGroup, controlPoint) ||
                  hasUnscheduledControl(controlGroup, controlPoint)))
            ) {
              const doc = getDocumentation(
                  controlPoint.TreeNode.ID,
                  controlGroup.data?.documentation || {}
              );

              counter++;
              const showDocs = !!doc && !isCompleted && !isDisabled;
              return (
                <ListItem
                  key={index}
                  title={controlPoint.TreeNode.Name}
                  detail={controlPoint.TreeNode.Notes}
                  itemType={"control-point"}
                  id={controlPoint.TreeNode.ID}
                  docs={showDocs}
                  formula={counter}
                  formulaClass={formulaClassName}
                  formulaCompleted={isCompleted}
                  disabled={isDisabled}
                  content={content(controlPoint, instance)}
                  controls={showDocs ? {doc} : {}}
                  settings={showDocs}
                />
              );
            }

            return <></>;
          }
        )}
    </div>
  );
};

export default withRouter(Formula);
