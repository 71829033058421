import { IAuthState } from "../types/Auth";
import { isEmpty } from "lodash";
import { TControlGroupState } from "../types/ControlGroup";
import {
  TApiControlPointEntity,
  TControlPointInstance,
  TControlPointTypeBase,
  TControlPointYesNoBase,
  TOpenInstance
} from "../types/ControlPoint";
import { IAdHocInstance } from "../types/Requests";
import { IListItemControls } from "../components/ListItem";
import { ControlPointType } from "./Config";
import { TApiFolderEntity, TApiSipDirectContent } from "../types/Sip";
import { TApiDocumentationMap, TApiRemainingWorkMap } from "../types/Tree";

export const setLocalStorage = (key: string, value: any): void => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key: string): any => {
  if (keyExists(key)) {
    return localStorage.getItem(key);
  }
};

export const removeLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const keyExists = (key: string): boolean => {
  return !!localStorage.getItem(key);
};

export const trans = (key: string): string => {
  const data = getLocalStorage("trans");
  const translations = JSON.parse(data ? data : "{}");

  return translations[key] ? translations[key] : key;
};

export const userIsAuthenticated = (auth: IAuthState) => {
  const now = new Date();
  return (
    !isEmpty(auth) &&
    auth.data !== undefined &&
    keyExists("token") &&
    auth.data.ExpirationTime > now.toISOString()
  );
};

export const isFolder = (
  node: TApiSipDirectContent | undefined
): node is TApiFolderEntity => {
  return node ? node.NodeTypeIdentifier === "folder" : false;
};

export const getRemainingWork = (
  nodeId: number,
  workList: TApiRemainingWorkMap | undefined
) => {
  if (workList && workList[nodeId] > 0) {
    return workList[nodeId];
  }

  return undefined;
};

export const getDocumentation = (
  nodeId: number,
  docsList: TApiDocumentationMap | undefined
) => {
  if (docsList && docsList[nodeId] > 0) {
    return docsList[nodeId];
  }

  return undefined;
};

export const getControlPointInfo = (
  infoArray: any,
  controlPointId: number
): TApiControlPointEntity => {
  return infoArray[controlPointId];
};

export const getControlPointOpenInstance = (
  infoArray: any,
  controlPointId: number
): TOpenInstance => {
  return infoArray[controlPointId];
};

export const getReminderConditionDetails = () => {
  return {
    DefaultValue: null,
    ComparisonValue: true,
    ControlType: ControlPointType.YESNO
  };
};

export const generateControlPointInstance = (
  controlGroup: TControlGroupState,
  controlPoint: any
): TControlPointInstance<TControlPointTypeBase> => {
  const controlPointInfo = getControlPointInfo(
    controlGroup.data && controlGroup.data.info,
    controlPoint.ControlID
  );
  return {
    ID: controlPoint.ID,
    ConditionDetails: controlPoint.ConditionDetails,
    ForcedTitle: controlPoint.ForcedTitle,
    ForcedNotes: controlPoint.ForcedNotes,
    DueEndTime: controlPoint.DueEndTime,
    DueStartTime: controlPoint.DueStartTime,
    IsReminder: controlPoint.IsReminder,
    ScheduleID: controlPoint.ScheduleID,
    ActionSetID: controlPointInfo.ActionSetID,
    ControlType: controlPointInfo.ControlType,
    CustomFields: controlPointInfo.CustomFields,
    DropdownListID: controlPointInfo.DropdownListID || undefined,
    Internal: controlPointInfo.Internal,
    TreeNode: controlPointInfo.TreeNode,
    PhotoMode: controlPointInfo.PhotoMode,
    WorkflowStep: controlPointInfo.WorkflowStep
  };
};

export const generateStandaloneReminderInstance = (
  instance: TOpenInstance
): TControlPointInstance<TControlPointYesNoBase> => {
  return {
    ID: instance.ID,
    ControlGroupID: instance.CheckpointID,
    ConditionDetails: getReminderConditionDetails(),
    ControlType: ControlPointType.YESNO,
    ForcedTitle: instance.ForcedTitle,
    ForcedNotes: instance.ForcedNotes,
    IsReminder: instance.IsReminder,
    DueEndTime: instance.DueEndTime,
    DueStartTime: instance.DueStartTime
  };
};

export const generateAdHocInstance = (
  instance: TApiControlPointEntity
): IAdHocInstance => {
  const performTime = new Date();
  const startTime = new Date();
  const endTime = new Date();
  endTime.setTime(startTime.getTime() + 2 * 24 * 60 * 60 * 1000);
  return {
    controlPoint: instance.TreeNode && instance.TreeNode.ID,
    endTime,
    performTime: performTime.toISOString(),
    DropdownListID: instance.DropdownListID || undefined,
    startTime,
    ConditionDetails: instance.ConditionDetails,
    CustomFields: instance.CustomFields,
    ControlType: instance.ControlType,
    TreeNode: instance.TreeNode,
    customFields: [],
    PhotoMode: instance.PhotoMode
  };
};

export const generateAuditInstance = (info: any) => {
  return {};
};

export const controlPointControls = (
  controlPoint: TOpenInstance,
  controlGroupId: number,
  docId: number | undefined
): Partial<IListItemControls> => {
  // reminder without an associated control point
  if (!controlPoint.ControlID) {
    return {};
  }

  // reminder of a control point
  if (controlPoint.IsReminder) {
    return {
      doc: docId,
      dismiss: {
        controlGroupId,
        selectedControlPoint: controlPoint.ID,
        redirectUrl: window.location.pathname
      }
    };
  }

  // scheduled instance
  return {
    doc: docId,
    repeatControlPoint: {
      controlPointId: controlPoint.ControlID,
      controlGroupId,
      redirectUrl: window.location.pathname
    },
    dismiss: {
      controlGroupId,
      selectedControlPoint: controlPoint.ID,
      redirectUrl: window.location.pathname
    }
  };
};

export const getApiResponseFieldError = (
  fieldsInfo: Record<string, { errors?: string[] }>,
  field: string
): string => {
  const fieldInfo = fieldsInfo[field];
  if (!fieldInfo || !fieldInfo.errors) {
    return "";
  }

  return fieldInfo.errors[0] || "";
};

export const getFirstOpenInstance = (
  controlGroup: TControlGroupState,
  openInstances: Record<number, TOpenInstance>
) => {
  const keys: string[] = Object.keys(openInstances);
  return (
    controlGroup.data && controlGroup.data.openInstances[parseInt(keys[0])]
  );
};

export const hasOpenInstance = (
  controlGroup: TControlGroupState,
  controlPoint: TApiControlPointEntity
) => {
  return (
    controlGroup.data &&
    controlGroup.data.openInstances[controlPoint.TreeNode.ID]
  );
};
export const hasUnscheduledControl = (
  controlGroup: TControlGroupState,
  controlPoint: TApiControlPointEntity
) => {
  return (
    controlGroup.data &&
    controlGroup.data.unscheduledControls[controlPoint.TreeNode.ID]
  );
};

export const formatAsDate = (input: Date): string =>
  [
    input.getFullYear(),
    `${101 + input.getMonth()}`.substring(1),
    `${100 + input.getDate()}`.substring(1)
  ].join("-");
