import { useSelector } from "react-redux";
import { IAppState } from "../store/Store";

export const useRequest = () => {
  const request: any = useSelector((state: IAppState) => state.request);

  const isLoading = (): boolean => {
    return request && request.loading;
  };

  const isLoaded = (): boolean => {
    return request && request.loaded;
  };

  const hasError = (): boolean => {
    return (request && request.error.length > 0);
  };

  const getError = (): string[] => {
    return request.error;
  };

  return { app: { isLoading, isLoaded, hasError, getError } };
};
