import { Reducer } from "redux";
import {
  DocumentationGet,
  initialDocState,
  TDocActions,
  TDocState
} from "../types/Documentation";
import { endReducer } from "../store/Store";

export const docReducer: Reducer<TDocState, TDocActions> = (
  state = initialDocState,
  action
) => {
  switch (action.type) {
    case DocumentationGet.REQUEST: {
      return {
        isFetching: true
      };
    }

    case DocumentationGet.SUCCESS:
      return {
        isFetching: false,
        data: {
          doc: {
            ...action.payload.data.doc
          },
          filestoreBaseUrl: action.payload.data.filestoreBaseUrl
        }
      };

    case DocumentationGet.FAILED:
      return state;

    default:
      return endReducer(state, action);
  }
};
