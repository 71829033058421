import React from "react";
import PhotoUploader from "./PhotoUploader";
import { trans } from "../../generic/Helpers";
import { PhotoMode } from "../../generic/Config";

interface IPhotoUploaderControl {
  photos: Array<any>;
  isFetchingPhotos: boolean;
  hasErrorPhotos: boolean;
  handleUploadPhotos: Function;
  handleDeletePhoto: Function;
  photoMode: PhotoMode;
  didSubmit: boolean;
  disabled?: boolean;
}

// TODO: fix logic submit
const PhotoUploadeControl: React.FC<IPhotoUploaderControl> = ({
  photos,
  isFetchingPhotos,
  hasErrorPhotos,
  handleUploadPhotos,
  handleDeletePhoto,
  photoMode,
  didSubmit,
  disabled
}): JSX.Element => {
  if (photoMode === PhotoMode.NOT_ALLOWED) {
    return <div />;
  }
  return (
    <div className="photo-uploader__control">
      <PhotoUploader
        photos={photos}
        isFetchingPhotos={isFetchingPhotos}
        hasErrorPhotos={hasErrorPhotos}
        handleUploadPhotos={handleUploadPhotos}
        handleDeletePhoto={handleDeletePhoto}
        disabled={disabled}
      />
      {PhotoMode.REQUIRED === photoMode && didSubmit && photos.length === 0 && (
        <div className="form-error__text">
          {trans("form-picture-upload-required")}
        </div>
      )}
    </div>
  );
};

export default PhotoUploadeControl;
