import * as React from "react";
import passwordIcon from "../../assets/images/icons/password.svg";
import settingsIcon from "../../assets/images/settings.svg";
import homeIcon from "../../assets/images/home.svg";
import helpIcon from "../../assets/images/need_help.svg";
import aboutIcon from "../../assets/images/about.svg";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { trans } from "../../generic/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { IAppState } from "../../store/Store";
import { TCustomerState } from "../../types/Customer";
import { logoutUser } from "../../actions/AuthActions";
import { IAuthState } from "../../types/Auth";

interface IMenuContentProps extends RouteComponentProps<any> {
  callback: () => any;
}

const MenuContent: React.FC<IMenuContentProps> = (props): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const customer: TCustomerState = useSelector<IAppState, TCustomerState>(
    (state: IAppState) => state.customerState
  );
  const auth: IAuthState = useSelector<IAppState, IAuthState>(
    (state: IAppState) => state.authState
  );

  const logout = () => {
    dispatch(logoutUser());
  };

  const handleHomeClick = () => {
    if (props.location.pathname !== "/app") {
      customer.data
        ? props.history.push(`/app/customer/${customer.data.TreeNode.ID}`)
        : props.history.push("/app");
    }
    props.callback();
  };

  return (
    <>
      <div className="user-info-block-container">
        {auth.data && (
          <div className="user-info-block">
            <i className="fas fa-user-circle" />
            <span className="user-info-block-email">{auth.data.Email}</span>
          </div>
        )}
        <div className="user-password-block">
          <Link to={"/app/password/change"} onClick={() => props.callback()}>
            <img src={passwordIcon} className="menu-content--icon" alt="" />
            <span>{trans("menu-change-password")}</span>
          </Link>
        </div>
      </div>
      <div className="menu-links-block">
        <Link to={"#"} onClick={() => handleHomeClick()}>
          <img src={homeIcon} className="menu-content--icon" alt="" />
          <span>{trans("menu-home")}</span>
        </Link>
        <Link to={"/app/settings"} onClick={() => props.callback()}>
          <img src={settingsIcon} className="menu-content--icon" alt="" />
          <span>{trans("menu-settings")}</span>
        </Link>

        <Link to={"/app/about"} onClick={() => props.callback()}>
          <img src={aboutIcon} className="menu-content--icon" alt="" />
          <span>{trans("menu-about")}</span>
        </Link>
        <a
          href={trans("help-external-link")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={helpIcon} className="menu-content--icon" alt="" />
          <span>{trans("menu-need-help")}</span>
        </a>
      </div>
      <div className="menu-logout-block">
        <button onClick={logout} className="btn btn-logout">
          {trans("auth-logout")}
        </button>
      </div>
    </>
  );
};

export default withRouter(MenuContent);
