import React, { useState, useEffect } from "react";

import Notes from "../../shared/NotesSlideInput";
import PhotoUploaderControl from "../../shared/PhotoUploaderControl";
import Title from "./Title";
import CustomInputList from "./CustomInputList";
import Submit from "./Submit";
import SubmitYesNo from "./SubmitYesNo";
import InputType from "./InputType";
import { SameAsLastAudit } from "../../shared/SameAsLastAudit";

import useImagesUpload from "../../../hooks/useImagesUpload";
import { ControlPointType } from "../../../generic/Config";
import getTitleText from "../../../helpers/components/forms/controlPoint/title";
import { validateRequiredPhotos } from "../../../generic/FormValidate";
import updateCustomFields from "../../../helpers/components/forms/controlPoint/updateCustomFields";
import moment from "moment";

function ControlPointForm(props: any) {
  const {
    formFields,
    setFormFields,
    controlPoint,
    onSubmit,
    completed
  } = props;

  const {
    PhotoMode: photoMode,
    ControlType: type,
    ConditionDetails: conditionDetails,
    Audit: audit,
    TreeNode: treeNode,
    DropdownListID: dropdownListId
  } = controlPoint;

  const handleInputChange = (userInput: any) => setFormFields({ userInput });

  // TODO: test this
  const handleSameAsLastAudit = (value: boolean, notes: string): void => {
    return setFormFields({
      isSameAsLastAudit: value,
      sameAsLastAuditNotes: notes
    });
  };

  const handleCustomField = (index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormFields({
      customFields: updateCustomFields(
        formFields.customFields,
        index,
        e.target.value
      )
    });
  };

  const [didSubmit, setDidSubmit] = useState<boolean>(false);
  const [
    photos,
    isFetchingPhotos,
    hasErrorPhotos,
    handleUploadPhotos,
    handleDeletePhoto
  ] = useImagesUpload(formFields.photoMappings || []);
  useEffect(() => {
    setFormFields({
      photoMappings: photos.map(photo => photo)
    });
  }, [photos]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // setting submit state
    setDidSubmit(true);
    // applying validation
    if (!validateRequiredPhotos(photos, photoMode)) {
      return;
    }
    onSubmit(formFields);
  };

  return (
    <div className="control-point-form">
      <div className="item-content">
        <form onSubmit={handleSubmit}>
          <div className="control-point-form__content">
            <Title title={getTitleText(type, conditionDetails)} />
            <InputType
              type={type}
              value={formFields.userInput}
              onChange={handleInputChange}
              dropdownListId={dropdownListId}
              disabled={completed}
              conditionDetails={conditionDetails}
            />
            <Notes
              value={formFields.userNotes}
              setValue={(value: string) => setFormFields({ userNotes: value })}
              disabled={completed}
            />
            {audit && (
              <SameAsLastAudit
                setSameAsLastAudit={handleSameAsLastAudit}
                dialogTitle={
                  controlPoint.ForcedTitle ? controlPoint.ForcedTitle : treeNode ? treeNode.Name : ''
                }
                same={formFields.isSameAsLastAudit}
                notes={formFields.sameAsLastAuditNotes}
                disabled={completed}
              />
            )}
            <CustomInputList
              customFields={formFields.customFields}
              handleCustomField={handleCustomField}
              disabled={completed}
            />
            {/* TODO: rewrite photo uploader, to be standalone with onChange handler */}
            <PhotoUploaderControl
              photos={photos}
              hasErrorPhotos={hasErrorPhotos}
              isFetchingPhotos={isFetchingPhotos}
              handleUploadPhotos={handleUploadPhotos}
              handleDeletePhoto={handleDeletePhoto}
              photoMode={photoMode ? photoMode : 1}
              didSubmit={didSubmit}
              disabled={completed || isFetchingPhotos}
            />
          </div>
          {/* TODO: simplify submit button logic */}
          {ControlPointType.YESNO !== type &&
            ControlPointType.TIMESTAMP !== type && (
              <Submit disabled={isFetchingPhotos} completed={completed} />
            )}
          {ControlPointType.TIMESTAMP === type && (
            <Submit
              buttonText={"input-save-timestamp"}
              completed={completed}
              onClick={() =>
                handleInputChange(moment(new Date()).format("YYYY-MM-DD"))
              }
              disabled={isFetchingPhotos}
            />
          )}
          {ControlPointType.YESNO === type && (
            <SubmitYesNo
              onClick={handleInputChange}
              completed={completed}
              disabled={isFetchingPhotos}
            />
          )}
        </form>
      </div>
    </div>
  );
}

export default ControlPointForm;
