import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { FormEvent, useEffect } from "react";
import { getControlGroup } from "../../actions/ControlGroupActions";
import Navbar from "../nav/Navbar";
import { TControlGroupState } from "../../types/ControlGroup";
import { IAppState } from "../../store/Store";
import Loading from "../Loading";
import { hasUnscheduledControl, trans } from "../../generic/Helpers";
import { TApiControlPointEntity } from "../../types/ControlPoint";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { generateUrl } from "../../generic/Requests";
import EmptyPage from "../pages/EmptyPage";
import {useAdHocPerformTime} from "../../hooks/useAdHocPerformTime";

interface IAdHocFromControlPoint extends RouteComponentProps<any> {}

const AdHocFromControlPoint: React.FC<IAdHocFromControlPoint> = ({
  match: {
    params: { customerId, sipId, controlGroupId }
  },
  location,
  history
}): JSX.Element => {
  const [maxDate] = useAdHocPerformTime();
  const [startDate, setStartDate] = useState<any>(new Date());
  const dispatch = useDispatch<Dispatch<any>>();
  const [selectedControlPoint, setSelectedControlPoint] = useState("");
  const controlGroup: TControlGroupState = useSelector<
    IAppState,
    TControlGroupState
  >((state: IAppState) => state.controlGroupState);
  const { controlGroup: controlGroupLoader } = useSelector(
    (state: IAppState) => state.loader
  );

  useEffect(() => {
    dispatch(getControlGroup(controlGroupId));
  }, [dispatch, controlGroupId]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    history.push({
      pathname: generateUrl(
        customerId,
        sipId,
        controlGroupId,
        `repeat-control-point/${selectedControlPoint}`
      ),
      state: {
        redirectUrl: location.state.redirectUrl,
        startDate
      }
    });
  };

  const eligibleControls: Array<TApiControlPointEntity> = [];

  if (controlGroup && controlGroup.data && controlGroup.data.contents) {
    eligibleControls.push(
      ...controlGroup.data.contents.filter(
        point => !hasUnscheduledControl(controlGroup, point)
      )
    );
  }

  if (!controlGroupLoader.isFetching && eligibleControls.length < 1) {
    return (
      <>
        <Navbar
          headerText={trans("config-adhoc-from-control-point")}
          backLink={() => {
            history.push(location.state.redirectUrl);
          }}
        />
        <EmptyPage text={trans("no-active-control-points")} />
      </>
    );
  }

  return (
    <>
      <Navbar
        headerText={trans("config-adhoc-from-control-point")}
        backLink={() => {
          return history.push(location.state.redirectUrl);
        }}
      />
      {controlGroup && controlGroup.data ? (
        <form onSubmit={handleFormSubmit}>
          <div className="text-block">
            {eligibleControls.map(
              (controlPoint: TApiControlPointEntity, index: number) => {
                return (
                  <div className="input-group pb-1" key={index}>
                    <input
                      type="radio"
                      name="controlPointId"
                      id={"cp-" + index}
                      value={controlPoint.TreeNode.ID}
                      onChange={e => setSelectedControlPoint(e.target.value)}
                      required
                    />
                    <label className="ml-1" htmlFor={"cp-" + index}>
                      {controlPoint.TreeNode.Name}
                    </label>
                  </div>
                );
              }
            )}
          </div>
          <div className="text-block">
            <label htmlFor="start">{trans("input-select-date")}</label>
            <div className="input-group mb-3">
              <ReactDatePicker
                selected={startDate}
                onChange={e => setStartDate(e)}
                showTimeSelect={true}
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="time"
                maxDate={maxDate}
                className="inp"
              />
            </div>
          </div>
          <div className="input-group mt-3 d-flex justify--center">
            <button className="btn btn-small btn-primary">
              {trans("global-ok")}
            </button>
          </div>
        </form>
      ) : (
        <Loading text={trans("global-loading")} />
      )}
    </>
  );
};

export default AdHocFromControlPoint;
