import * as React from "react";
import { TApiSipDirectContent, TSipState } from "../../../types/Sip";
import { RouteComponentProps, withRouter } from "react-router";
import ListItem, { IListItemControls } from "../../ListItem";
import {
  getDocumentation,
  getRemainingWork,
  isFolder
} from "../../../generic/Helpers";
import { ControlGroupType } from "../../../generic/Config";

interface ISipProps extends RouteComponentProps<any> {
  sip: TSipState;
}

const RegularSip: React.FC<ISipProps> = ({
  sip,
  match: {
    params: { customerId, sipId }
  }
}): JSX.Element => {
  const showControls = (
    content: TApiSipDirectContent
  ): Partial<IListItemControls> => {
    const doc = getDocumentation(
      content.TreeNode.ID,
      sip.data && sip.data.documentation
    );

    if (isFolder(content)) {
      return {
        doc,
        report: {
          type: "folder",
          id: content.TreeNode.ID
        }
      };
    }

    if (
      content.CheckpointType === ControlGroupType.REGULAR ||
      content.CheckpointType === ControlGroupType.CONTROLS_LIST
    ) {
      return {
        controlGroupAdhoc: {
          sipId: content.TreeNode.SipID,
          controlGroupId: content.TreeNode.ID,
          redirectUrl: window.location.pathname
        },
        AdHocFromControlPoint: {
          controlGroupId: content.TreeNode.ID,
          redirectUrl: window.location.pathname
        },
        repeat: true,
        doc,
        report: {
          type: "control-group",
          id: content.TreeNode.ID
        },
        dismiss: {
          controlGroupId: content.TreeNode.ID,
          redirectUrl: window.location.pathname
        }
      };
    } else {
      return {
        doc,
        report: {
          type: "control-group",
          id: content.TreeNode.ID
        }
      };
    }
  };
  return (
    <div className="item-list">
      {sip.data &&
        sip.data.contents.map((content: TApiSipDirectContent) => {
          return (
            <ListItem
              key={content.TreeNode.ID}
              title={content.TreeNode.Name}
              url={
                isFolder(content)
                  ? `/app/customer/${customerId}/sip/${content.TreeNode.ID}`
                  : `/app/customer/${customerId}/sip/${sipId}/control-group/${content.TreeNode.ID}`
              }
              itemType={!isFolder(content) ? content.CheckpointType : "folder"}
              count={getRemainingWork(
                content.TreeNode.ID,
                sip.data && sip.data.remainingWork
              )}
              docs={
                !!getDocumentation(
                  content.TreeNode.ID,
                  sip.data && sip.data.documentation
                )
              }
              settings={true}
              controls={showControls(content)}
            />
          );
        })}
    </div>
  );
};

export default withRouter(RegularSip);
