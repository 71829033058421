import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../store/Store";
import { withRouter, RouteComponentProps } from "react-router";

import OfflineError from "../components/OfflineContainer/OfflineError";
import { resetOffLineStatus, TOfflineState } from "../reducers/OfflineReducer";

interface IOfflineContainerProps extends RouteComponentProps<any> {
  children: any;
}

// TODO: check hooks performance, since it re-renders every change compared to class components, or mapStateToProps
const OfflineContainer = ({ children, history }: IOfflineContainerProps) => {
  const { isOffline }: TOfflineState = useSelector(
    (state: IAppState) => state.offline
  );
  const dispatch = useDispatch<any>();
  const resetOffline = () => dispatch(resetOffLineStatus());

  if (isOffline) {
    return (
      <div className="offline">
        <OfflineError beforeRefresh={resetOffline} />
      </div>
    );
  }
  return <>{children}</>;
};

export default withRouter(OfflineContainer);
