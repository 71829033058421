import * as React from "react";
import { TApiCustomerEntity, TCustomerListState } from "../../types/Customer";
import ListItem from "../ListItem";

interface ICustomerListProps {
  customers: TCustomerListState;
}

const CustomerList: React.FC<ICustomerListProps> = (props): JSX.Element => {
  const { customers } = props;
  return (
    <div className="item-list">
      {customers.data &&
        customers.data.customers.map((customer: TApiCustomerEntity) => {
          return (
            <ListItem
              key={customer.TreeNode.ID}
              url={`/app/customer/${customer.TreeNode.ID}`}
              title={customer.TreeNode.Name}
            />
          );
        })}
    </div>
  );
};

export default CustomerList;
