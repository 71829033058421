import { uniqueId } from "lodash";

export enum SnackbarTypes {
  SUCCESS = "success",
  ERROR = "error"
}

export enum actionTypes {
  ADD = "SNACKBARS_ADD_SNACKBAR",
  DELETE = "SNACKBARS_DELETE_SNACKBAR"
}

// TODO: fix files naming, such as suffix Reducer as we have folders for that

export const addSnackbar = ({
  title,
  body = "",
  type = SnackbarTypes.SUCCESS,
  duration = 5000
}: {
  title: string;
  body?: string;
  type?: string;
  duration?: number;
}) => ({
  type: actionTypes.ADD,
  payload: {
    snackbar: {
      id: uniqueId("snackbar"),
      title,
      body,
      type,
      duration
    }
  }
});

export const deleteSnackbar = (snackbarId: string) => ({
  type: actionTypes.DELETE,
  payload: { id: snackbarId }
});

export type TSnackbar = {
  id: string;
  title: string;
  body: string;
  duration?: number;
  type?: SnackbarTypes;
};

export type TSnackbarsState = Array<TSnackbar>;

const initialState: TSnackbarsState = [];

export const snackbarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.ADD:
      return [action.payload.snackbar, ...state];
    case actionTypes.DELETE:
      return [...state.filter(snackbar => snackbar.id !== action.payload.id)];
    default:
      return state;
  }
};
