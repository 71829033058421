import { AxiosResponse } from "axios";
import { TApiControlPointEntity, TOpenInstance } from "./ControlPoint";
import {
  TApiDocumentationMap,
  TApiTreeNodeEntityInSip,
  TNodeTypeIdentifier
} from "./Tree";
import {
  ControlGroupType,
  PhotoMode,
  TrafficLightsScale
} from "../generic/Config";

export interface TApiControlGroupEntity {
  CheckpointType: ControlGroupType;
  FailAllRemaining: boolean;
  NodeTypeIdentifier: TNodeTypeIdentifier.CONTROL_GROUP;
  TrafficLights: null | TApiTrafficLightsConfig;
  TreeNode: TApiTreeNodeEntityInSip;
  PhotoMode?: PhotoMode;
}

export interface TApiTrafficLightsConfig {
  Scale: TrafficLightsScale;
  Colors: Array<TApiTrafficLightColor>;
}

export interface TApiTrafficLightColor {
  StartTime: number;
  Color: string;
}

export interface TApiControlGroupContent {
  node: TApiControlGroupEntity;
  contents: Array<TApiControlPointEntity>;
  openInstances: Array<any>;
  unscheduledControls: Array<number>;
  documentation: TApiDocumentationMap;
  controlStatuses: Record<number, number | null>;
}

export interface TControlGroup {
  contents: Array<TApiControlPointEntity>;
  info: Record<number, TApiControlPointEntity>;
  controlStatuses?: Record<number, number | null>;
  documentation: TApiDocumentationMap;
  node: TApiControlGroupEntity;
  openInstances: Record<number, TOpenInstance>;
  unscheduledControls: Record<number, number>;
}

export interface TControlGroupState {
  readonly isFetching?: boolean;
  readonly dropdown?: any;
  readonly data?: TControlGroup;
}

export const initialControlGroupState: TControlGroupState = {
  isFetching: false,
  data: undefined
};

export enum ControlGroup {
  REQUEST = "CONTROL_GROUP_REQUEST",
  SUCCESS = "CONTROL_GROUP_SUCCESS",
  FAILED = "CONTROL_GROUP_FAILED"
}

export type TGetControlGroupAction =
  | {
      type: ControlGroup.REQUEST;
    }
  | {
      type: ControlGroup.SUCCESS;
      payload: AxiosResponse<TApiControlGroupContent>;
    }
  | {
      type: ControlGroup.FAILED;
      payload: AxiosResponse;
    };

export type ControlGroupActions = TGetControlGroupAction;
