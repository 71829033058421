import {Reducer} from "redux";
import {DeviationActions, GetDeviation, initialDeviationState, TDeviationState} from "../types/Deviation";
import {endReducer} from "../store/Store";

export const deviationReducer: Reducer<TDeviationState, DeviationActions> = (
  state = initialDeviationState,
  action
) => {
  switch (action.type) {
    case GetDeviation.REQUEST: {
      return {
        isFetching: true
      };
    }

    case GetDeviation.SUCCESS:
      return {
        isFetching: false,
        data: action.payload.data
      };

    case GetDeviation.FAILED:
      return state;

    default:
      return endReducer(state, action);
  }
};
