import React from "react";
import iconPencil from "../../../assets/images/icons/icon-pencil.svg";
import { trans } from "../../../generic/Helpers";

const NumberInput = ({
  value,
  onChange,
  disabled
}: {
  value: any;
  onChange: any;
  disabled?: boolean;
}) => (
  <div className="form-control">
    <div className="form-control__icons">
      <img src={iconPencil} className="inp-addon" alt="" />
    </div>
    <input
      className="form-control__input inp"
      type="text"
      step="any"
      placeholder={trans("input-tap-to-enter-value")}
      value={value}
      onChange={onChange}
      required={true}
      disabled={disabled}
    />
    <div className="form-control__actions" />
  </div>
);

export default NumberInput;
