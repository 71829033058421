import { each } from "lodash";

const linkReplacements = [
  {
    pattern: /<img ([^>]*)src="(.+?)"/g,
    replacer: (filestore: string) => {
      return (inp: string, attrs: string, url: string): string => {
        if (url.indexOf(filestore) === 0) {
          return inp;
        }
        return "<img " + attrs + 'src="' + new URL(url, filestore).href + '"';
      };
    }
  },
  {
    pattern: /<a ([^>]*)href="(https?:\/\/|\/)doc\//g,
    replacer: () => {
      return (inp: string, attrs: string): string => {
        return '<a data-internal-link="true" ' + attrs + 'href="/app/doc/';
      };
    }
  },
  {
    pattern: /<a ([^>]*)href="(https?:\/\/|\/)filestore\//g,
    replacer: (filestore: string) => {
      return (inp: string, attrs: string): string => {
        return (
          "<a " + attrs + ' target="_blank" href="' + new URL("filestore/", filestore)
        );
      };
    }
  }
];

export function fixLinksInDocs(
  this: void,
  input: string,
  filestoreBase: string
): string {
  each(linkReplacements, findRepl => {
    input = input.replace(findRepl.pattern, findRepl.replacer(filestoreBase));
  });
  return input;
}
