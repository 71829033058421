import * as React from "react";
import { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  TControlPointAction,
  TControlPointData
} from "../../types/ControlPoint";
import { IAdHocInstance, IInstanceRequest } from "../../types/Requests";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getControlGroup } from "../../actions/ControlGroupActions";
import {
  createAdHoc,
  performInstance
} from "../../actions/ControlPointActions";
import { generateUrl } from "../../generic/Requests";
import Loading from "../Loading";
import { trans } from "../../generic/Helpers";
import DefaultControlPointForm from "./controls/DefaultControlPointForm";
import { getRequestUserInputAndCalculationValues } from "../../helpers/components/repeat";
import { isEmpty } from "lodash";

interface IControlProps extends RouteComponentProps<any> {
  instance: any;
  performAdHoc?: boolean;
  repeatable?: boolean;
  audit?: boolean;
  params?: {
    redirectUrl?: string;
    startDate?: Date;
  };
  callback?: (...args: any) => any;
}

const ControlPointBase: React.FC<IControlProps> = (props): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [loading, setLoading] = useState<boolean>(false);
  const { customerId, sipId, controlGroupId } = props.match.params;
  const { instance } = props;
  let performTime = new Date();
  if (props.params && props.params.startDate) {
    performTime = props.params.startDate;
  }

  const formInstanceRequest = (data: TControlPointData): IInstanceRequest => {
    return {
      customFields: data.customFields,
      instance: instance.ID,
      performStatus: data.conditionIsTrue,
      performTime: performTime.toISOString(),
      userNotes: data.notes,
      photoMappings: data.photos
        ? data.photos.map(photo => ({ photo: photo.photoId }))
        : [],
      ...getRequestUserInputAndCalculationValues(
        data.value,
        instance.ControlType
      )
    };
  };

  const performNormalInstance = (data: TControlPointData) => {
    const instanceRequest: IInstanceRequest = formInstanceRequest(data);
    if (data.conditionIsTrue) {
      dispatch(
        performInstance(instanceRequest, () => {
          return dispatch(getControlGroup(controlGroupId));
        })
      );
    } else {
      props.history.push({
        pathname: generateUrl(
          customerId,
          sipId,
          controlGroupId,
          instance.TreeNode ? `deviation/${instance.TreeNode.ID}` : `deviation`
        ),
        state: {
          instance,
          instanceRequest,
          data
        }
      });
    }
  };

  const performAdHocInstance = (data: TControlPointData) => {
    const adHocInstance: IAdHocInstance = {
      customFields: data.customFields,
      controlPoint: instance.TreeNode && instance.TreeNode.ID,
      endTime: performTime.toISOString(),
      performStatus: data.conditionIsTrue,
      performTime: performTime.toISOString(),
      startTime: performTime.toISOString(),
      userNotes: data.notes,
      photoMappings: data.photos
        ? data.photos.map(photo => ({ photo: photo.photoId }))
        : [],
      ...getRequestUserInputAndCalculationValues(
        data.value,
        instance.ControlType
      )
    };
    if (data.conditionIsTrue) {
      dispatch(
        createAdHoc(adHocInstance, () => {
          if (typeof props.callback === "function") {
            props.callback();
          }
          if (props.params && props.params.redirectUrl) {
            props.history.push(props.params.redirectUrl);
          }
        })
      );
    } else {
      const instanceRequest: IInstanceRequest = formInstanceRequest(data);
      props.history.push({
        pathname: generateUrl(
          customerId,
          sipId,
          controlGroupId,
          `deviation/${instance.TreeNode.ID}`
        ),
        state: {
          instance,
          instanceRequest,
          data,
          adHocInstance,
          isAdHoc: true
        }
      });
    }
  };

  const performRepeatableInstance = (data: TControlPointData) => {
    // TODO: remove from view logic
    // handles control group form updates
    if (typeof props.callback === "function") {
      return props.callback(instance, data);
    }
  };

  const performAuditInstance = (data: TControlPointData) => {
    if (typeof props.callback === "function") {
      return props.callback(instance, data);
    }
  };

  const handleControlPointAction: TControlPointAction = (
    data: TControlPointData
  ) => {
    if (props.performAdHoc) {
      setLoading(true);
      return performAdHocInstance(data);
    }
    if (props.repeatable) {
      return performRepeatableInstance(data);
    }
    if (props.audit) {
      return performAuditInstance(data);
    }

    setLoading(true);
    return performNormalInstance(data);
  };

  if (loading) {
    return (
      <div className="item-content">
        <Loading text={trans("performing-control-point")} />
      </div>
    );
  }

  if (!isEmpty(props.instance)) {
    return (
      <DefaultControlPointForm
        handleControlPointAction={handleControlPointAction}
        instance={props.instance}
      />
    );
  }

  return <></>;
};

export default withRouter(ControlPointBase);
