import { ControlPointType } from "../../../../generic/Config";
import moment from "moment";

export const getNumberPerformStatus = (
  conditionDetails: any,
  userInput: any
) => {
  let conditionIsTrue = false;
  const parsedValue = parseFloat(userInput);
  const comparisonOperator =
    conditionDetails && conditionDetails.ComparisonOperator;
  const comparisonValue = conditionDetails && conditionDetails.ComparisonValue;

  switch (comparisonOperator) {
    case "M":
      conditionIsTrue =
        comparisonValue !== undefined && parsedValue > comparisonValue;
      break;

    case "ME":
      conditionIsTrue =
        comparisonValue !== undefined && parsedValue >= comparisonValue;
      break;

    case "E":
      conditionIsTrue =
        comparisonValue !== undefined && parsedValue === comparisonValue;
      break;

    case "LE":
      conditionIsTrue =
        comparisonValue !== undefined && parsedValue <= comparisonValue;
      break;

    case "L":
      conditionIsTrue =
        comparisonValue !== undefined && parsedValue < comparisonValue;
      break;
  }
  return conditionIsTrue;
};

export const getIntervalPerformStatus = (
  conditionDetails: any,
  userInput: any
) => {
  let conditionIsTrue = false;
  const comparisonRight = conditionDetails && conditionDetails.ComparisonRight;
  const comparisonLeft = conditionDetails && conditionDetails.ComparisonLeft;
  const comparisonType = conditionDetails && conditionDetails.ComparisonType;

  const parsedValue = parseFloat(userInput);
  if (comparisonType === "inclusive") {
    conditionIsTrue =
      comparisonLeft !== undefined &&
      comparisonRight !== undefined &&
      parsedValue >= comparisonLeft &&
      parsedValue <= comparisonRight;
  } else {
    conditionIsTrue =
      comparisonLeft !== undefined &&
      comparisonRight !== undefined &&
      parsedValue > comparisonLeft &&
      parsedValue < comparisonRight;
  }
  return conditionIsTrue;
};

export const getDatePerformStatus = (conditionDetails: any, userInput: any) => {
  const now = new Date();
  const selectedDate = moment(userInput).toDate();
  const diffTime = selectedDate ? selectedDate.getTime() - now.getTime() : 0;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays >= conditionDetails.DaysInAdvance
};

export const getYesNoPerformStatus = (
  conditionDetails: any,
  userInput: any
) => {
  return userInput === conditionDetails.ComparisonValue;
};

const getPerformStatus = (
  type: ControlPointType,
  conditionDetails: any,
  userInput: any
) => {
  switch (type) {
    case ControlPointType.NUMBER:
      return getNumberPerformStatus(conditionDetails, userInput);
    case ControlPointType.INTERVAL:
      return getIntervalPerformStatus(conditionDetails, userInput);
    case ControlPointType.DATE:
      return getDatePerformStatus(conditionDetails, userInput);
    case ControlPointType.YESNO:
      return getYesNoPerformStatus(conditionDetails, userInput);
    case ControlPointType.SELECT_FROM_LIST:
    case ControlPointType.TIMESTAMP:
    default:
      return true;
  }
};

export default getPerformStatus;

/*
 controlPointForm
 deviationForm
 reminderForm

 controlPointForm - userInput, userNotes, customFields, photoMappings

 onSubmit check if these values are correct, if they are pass them

*/
