import {useEffect, useState} from "react";
import {find, get, sortBy} from "lodash";
import moment from "moment";
import {TrafficLightsScale} from "../generic/Config";
import {TApiTrafficLightsConfig} from "../types/ControlGroup";

const getTrafficLightColor = (
  TrafficLights: TApiTrafficLightsConfig,
  remainingSeconds: number,
  totalSeconds: number
): string => {
  const sortedColors = sortBy(TrafficLights.Colors, "[StartTime]", "[asc]");
  switch (TrafficLights.Scale) {
    case TrafficLightsScale.FIXED_TIME:
      const FixedColor = find(
          sortedColors,
          ({StartTime}) => remainingSeconds <= StartTime
      );
      return get(FixedColor, "Color", "");

    case TrafficLightsScale.PERCENTAGE_OPEN_TIME:
      const PercentColor = find(
          sortedColors,
          ({StartTime}) => Math.floor(remainingSeconds * 100 / totalSeconds) <= StartTime
      );
      return get(PercentColor, "Color", "");
  }
};

const useControlInstanceTime = (
  dueEndTime: string | undefined,
  dueStartTime: string | undefined,
  TrafficLights: TApiTrafficLightsConfig | null
) => {
  const [remainingDays, setRemainingDays] = useState<number>(0);
  const [remainingHours, setRemainingHours] = useState<number>(0);
  const [remainingMinutes, setRemainingMinutes] = useState<string>("");
  const [color, setColor] = useState<string>("");
  const [hasEnded, setHasEnded] = useState<boolean>(false);
  useEffect(() => {
    const showTime = () => {
      const diff = moment(dueEndTime).diff(new Date());
      const duration = moment.duration(diff);
      const totalDiff = moment(dueEndTime).diff(dueStartTime);
      const totalDuration = moment.duration(totalDiff);
      setHasEnded(diff <= 0);

      setRemainingDays(Math.floor(duration.asDays()));
      setRemainingHours(Math.floor(duration.asHours()) % 24);
      setRemainingMinutes(moment.utc(diff).format("mm"));

      if (TrafficLights) {
        setColor(
            getTrafficLightColor(
                TrafficLights,
                duration.asSeconds(),
                totalDuration.asSeconds()
            )
        );
      }
    };
    showTime();
    const refresh = setInterval(showTime, 10000);
    return () => clearInterval(refresh);
  }, []);
  return [remainingDays, remainingHours, remainingMinutes, color, hasEnded];
};

export default useControlInstanceTime;
