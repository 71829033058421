import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../store/Store";
import { AxiosResponse } from "axios";
import { TApiControlPointConditionDetails } from "./ControlPoint";
import { TApiTreeNodeEntityInSip} from "./Tree";

export type AsyncThunkAction<T, U> = ThunkAction<
  Promise<any>,
  T,
  IAppState,
  Action<U>
>;

export interface IInstanceRequest {
  customFields: any;
  instance: number;
  performStatus: boolean;
  performTime: string;
  userInput: string | number;
  userNotes: string;
  controlPoint?: number;
  endTime?: Date;
  startTime?: Date;
  photoMappings?: Array<any> // TODO: add photoMappings
}

export interface IAdHocInstance {
  sip?: number | null;
  controlGroup?: number | null;
  controlPoint?: number | null;
  endTime?: string | Date;
  forcedNotesText?: string;
  startTime?: string | Date;
  userInput?: string | number;
  userNotes?: string;
  performStatus?: boolean;
  performTime?: string;
  ConditionDetails?: TApiControlPointConditionDetails;
  ControlType?: number | undefined;
  TreeNode?: TApiTreeNodeEntityInSip;
  DropdownListID?: number;
  customFields: any;
  CustomFields?: any;
  photoMappings?: Array<any>,
  PhotoMode?: number,
}

export interface IAuditInstance extends IAdHocInstance{
  isSameAsLastAudit: boolean,
  partOfBatch: string,
  sameAsLastAuditNotes: string,
}

export enum PerformInstance {
  REQUEST = "PERFORM_INSTANCE_REQUEST",
  SUCCESS = "PERFORM_INSTANCE_SUCCESS",
  FAILED = "PERFORM_INSTANCE_FAILED"
}

export interface IPerformInstanceAction {
  type:
    | PerformInstance.REQUEST
    | PerformInstance.SUCCESS
    | PerformInstance.FAILED;
  payload: AxiosResponse;
}

export enum PerformAdHoc {
  REQUEST = "PERFORM_ADHOC_REQUEST",
  SUCCESS = "PERFORM_ADHOC_SUCCESS",
  FAILED = "PERFORM_ADHOC_FAILED"
}

export interface IPerformAdHocAction {
  type: PerformAdHoc.REQUEST | PerformAdHoc.SUCCESS | PerformAdHoc.FAILED;
  payload: AxiosResponse;
}

export enum CreateReminder {
  REQUEST = "CREATE_REMINDER_REQUEST",
  SUCCESS = "CREATE_REMINDER_SUCCESS",
  FAILED = "CREATE_REMINDER_FAILED"
}

export interface ICreateReminderAction {
  type: CreateReminder.REQUEST | CreateReminder.SUCCESS | CreateReminder.FAILED;
  payload: AxiosResponse;
}
