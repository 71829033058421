export enum TGeoReadingSuccessState {
  OUT_OF_RANGE = "out_of_range",
  WITHIN_RANGE = "within_range",
  RANGE_NOT_SET = "range_not_set"
}

export enum TGeoReadingFailureState {
  PERMISSION_DENIED = "permission_denied",
  GEOLOCATION_UNAVAILABLE = "geolocation_unavailable",
  POSITION_UNAVAILABLE = "position_unavailable",
  TIMEOUT = "timeout"
}

export enum TGeoPermissionsState {
    NOT_SET = "not_set",
    GRANTED = "granted",
    DENIED = "denied",
}

export type TGeoReadingState =
  | TGeoReadingSuccessState
  | TGeoReadingFailureState;

export type TGeoLockState =
  | {
      status: undefined;
    }
  | {
      status: "checkingPermissions";
    }
  | {
      status: "checkedPermissions";
      permission: TGeoPermissionsState;
    }
  | {
      status: "querying";
    }
  | {
      status: "queried";
      queriedState: TGeoReadingSuccessState | null;
    }
  | {
      status: "queryFailed";
      error: TGeoReadingFailureState | string;
    };

export const initialGeoLockState: TGeoLockState = {
  status: undefined
};

export enum GeoLock {
  CLEAR = "GEOLOCK_CLEAR",
  CHECK_PERMISSIONS = "GEOLOCK_CHECK_PERMISSIONS",
  RESOLVE_PERMISSIONS = "GEOLOCK_RESOLVE_PERMISSIONS",
  REQUEST = "GEOLOCK_REQUEST",
  SUCCESS = "GEOLOCK_SUCCESS",
  FAILURE = "GEOLOCK_FAILURE"
}

export type TGeoLockAction =
  | {
      type: GeoLock.CLEAR;
    }
  | {
      type: GeoLock.CHECK_PERMISSIONS;
    }
  | {
      type: GeoLock.RESOLVE_PERMISSIONS;
      payload: TGeoPermissionsState;
    }
  | {
      type: GeoLock.REQUEST;
    }
  | {
      type: GeoLock.SUCCESS;
      payload: TGeoReadingSuccessState;
    }
  | {
      type: GeoLock.FAILURE;
      payload: TGeoReadingFailureState;
    };

export type GeoLockActions = TGeoLockAction;

export interface TGeoPosition {
  latitude: number;
  longitude: number;
}

export interface TCircularGeofence {
  center: TGeoPosition;
  radius: number;
}
