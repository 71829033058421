import * as React from "react";
import { trans } from "../../generic/Helpers";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

interface SameAsLastAuditProps {
  dialogTitle: string | undefined;
  setSameAsLastAudit: (value: boolean, notes: string) => void;
  disabled?: boolean;
  notes?: string;
  same?: boolean;
}

export const SameAsLastAudit: React.FC<SameAsLastAuditProps> = props => {
  const {
    disabled = false,
    notes: auditNotes = "",
    same: auditSame = false
  } = props;

  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = React.useState(auditNotes);
  const [same, setSame] = React.useState(auditSame);

  const handleDialogForm = (saved: boolean) => {
    setOpen(false);
    setSame(saved);
    if (!saved) {
      setNotes("");
    }
    props.setSameAsLastAudit(saved, notes);
  };

  const btnClass = (same: boolean) => {
    let className = "btn btn-small ml-3";
    if (same) {
      className = className + " btn-inverse";
    } else {
      className = className + " btn-primary";
    }

    return className;
  };

  return (
    <div className="form-control d-flex align-items--center">
      <span>{trans("same-issue-as-in-the-last-audit")}</span>
      <button
        disabled={disabled}
        type="button"
        className={btnClass(same)}
        onClick={() => {
          setOpen(true);
        }}
      >
        {same && (
          <span className="mr-1">
            <i className="fas fa-check" />
          </span>
        )}
        {trans("global-yes")}
      </button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="notes"
            label="Notes"
            type="text"
            value={notes}
            onChange={e => setNotes(e.target.value)}
            placeholder={trans("same-as-last-audit-input-placeholder")}
            fullWidth
          />
        </DialogContent>
        <DialogActions className="mx-3">
          <button
            className="btn btn-inverse"
            onClick={() => handleDialogForm(false)}
          >
            {trans("global-cancel")}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleDialogForm(true)}
          >
            {trans("global-save")}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
