import * as React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import { getLocalStorage, trans } from "../../generic/Helpers";
import {
  AuditStatus,
  IAuditControlGroup,
  IAuditControlPoint,
  IAuditState
} from "../../types/Audit";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/Store";
import {
  commitAuditControls,
  getAuditControls,
  getAuditStatus,
  restartAuditControls
} from "../../actions/AuditActions";
import Loading from "../Loading";
import { generateUrl } from "../../generic/Requests";
import { getDropdown } from "../../actions/ControlGroupActions";
import { getSip } from "../../actions/SipActions";
import { TApiSipDirectContent, TSipState } from "../../types/Sip";
import { filter } from "lodash";
import { useRequest } from "../../hooks/useRequest";
import Alert from "../Alert";
import { clearErrors } from "../../actions/AuthActions";

interface IReviewProps extends RouteComponentProps<any> {}

const Review: React.FC<IReviewProps> = ({
  match: {
    params: { customerId, sipId, auditId }
  },
  history
}): JSX.Element => {
  const audit: IAuditState = useSelector<IAppState, IAuditState>(
    (state: IAppState) => state.audit
  );
  const sip = useSelector<IAppState, TSipState>(
    (state: IAppState) => state.sipState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const auditData = {
    audit: auditId
  };
  const dispatch = useDispatch();
  const auditConfig = JSON.parse(getLocalStorage("currentAuditConfig"));
  const { app } = useRequest();

  useEffect(() => {
    if (app.hasError()) {
      setLoading(false);
    }
  }, [app]);

  const restartAudit = () => {
    setLoading(true);
    dispatch(clearErrors());
    dispatch(
      restartAuditControls(auditData, () => {
        setLoading(false);
        dispatch(
          getAuditControls(auditId, () => {
            return history.push(
              generateUrl(customerId, sipId, undefined, `audit/${auditId}`)
            );
          })
        );
      })
    );
  };

  useEffect(() => {
    dispatch(getDropdown(auditConfig.AnswersListID));
    dispatch(getSip(sipId));
    dispatch(getAuditStatus(auditId));
  }, [dispatch]);

  const commitAudit = () => {
    setLoading(true);
    dispatch(clearErrors());
    if (
      audit.data &&
      audit.data.status &&
      audit.data?.status?.Status === AuditStatus.AUDITOR_MAKING_NOTES
    ) {
      return history.push(
        generateUrl(customerId, sipId, undefined, `audit/${auditId}/notes`)
      );
    }
    dispatch(
      commitAuditControls(auditData, () => {
        setLoading(false);
        return history.push(
          generateUrl(customerId, sipId, undefined, `audit/${auditId}/notes`)
        );
      })
    );
  };

  useEffect(() => {
    dispatch(getAuditControls(auditId));
  }, [dispatch, auditId]);

  const getFolderContent = (
    controls: IAuditControlGroup[] | undefined,
    folderId: number
  ): IAuditControlGroup[] => {
    return filter(controls, (c: IAuditControlGroup) => {
      return c.ControlGroupNode.ParentID === folderId;
    });
  };

  return (
    <>
      <Navbar headerText={trans("audit-review-page-title")} />
      {(audit.isFetching || loading) && !app.hasError() && (
        <Loading text={trans("global-loading")} />
      )}
      {app.hasError() && (
        <Alert type={"danger"} text={app.getError()} error={true} />
      )}
      {audit.data && audit.data.controls && (
        <>
          <div className="text-block">
            {sip.data &&
              sip.data.contents.map(
                (content: TApiSipDirectContent, index: number) => {
                  if (audit.data) {
                    return (
                      <div key={index}>
                        <h1>{content.TreeNode.Name}</h1>
                        {getFolderContent(
                          audit.data.controls,
                          content.TreeNode.ID
                        ).map((controlGroup: IAuditControlGroup) => {
                          return (
                            <div key={controlGroup.ControlGroupNode.ID}>
                              <h3 className="mt-1">
                                {controlGroup.ControlGroupNode.Name}
                              </h3>
                              <div className="mb-3">
                                {controlGroup.ControlPointStatuses.map(
                                  (controlPoint: IAuditControlPoint) => {
                                    if (controlPoint.ControlInstance !== null) {
                                      return (
                                        <div
                                          key={controlPoint.ControlPointNode.ID}
                                        >
                                          <hr />
                                          <div className="d-flex justify--between py-1">
                                            <div className="d-flex direction--column">
                                              {
                                                controlPoint.ControlPointNode
                                                  .Name
                                              }
                                              {controlPoint.ControlInstance
                                                .SameAsLastAuditNotes && (
                                                <>
                                                  <small className="ml-2 text-secondary">
                                                    <span>
                                                      {trans(
                                                        "audit-review-same-as-last-audit-label"
                                                      )}
                                                      :{" "}
                                                    </span>
                                                    <i>
                                                      {
                                                        controlPoint
                                                          .ControlInstance
                                                          .SameAsLastAuditNotes
                                                      }
                                                    </i>
                                                  </small>
                                                </>
                                              )}
                                              {controlPoint.ControlInstance
                                                .UserNotes && (
                                                <>
                                                  <small className="ml-2 text-secondary">
                                                    <i>
                                                      {
                                                        controlPoint
                                                          .ControlInstance
                                                          .UserNotes
                                                      }
                                                    </i>
                                                  </small>
                                                </>
                                              )}
                                            </div>
                                            <div className="text-right">
                                              <b>
                                                {
                                                  controlPoint.ControlInstance
                                                    .UserInput
                                                }
                                              </b>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }

                                    return false;
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }

                  return false;
                }
              )}
          </div>
          <div className="d-flex">
            <button className="btn btn-inverse mr-1" onClick={restartAudit}>
              {trans("restart-controls")}
            </button>
            <button className="btn btn-primary ml-1" onClick={commitAudit}>
              {trans("proceed-to-add-notes")}
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Review;
