import * as React from "react";
import { useDispatch } from "react-redux";
import { clearErrors } from "../actions/AuthActions";
import { trans } from "../generic/Helpers";

interface IAlertProps {
  type: "success" | "danger";
  text: string | any[] | undefined;
  error?: boolean;
  closeable?: boolean;
}

const Alert: React.FC<IAlertProps> = (props): JSX.Element => {
  const { text } = props;
  const dispatch = useDispatch();

  const clear = () => {
    dispatch(clearErrors());
  };

  if (typeof text === "string") {
    return (
      <div className={`alert alert-${props.type}`}>
        <p className="text-center">
          {props.error && trans("client-error-message")}
        </p>
        {props.text}
        {props.closeable && (
          <span className="close-icon" onClick={clear}>
            x
          </span>
        )}
      </div>
    );
  }

  if (Array.isArray(text)) {
    return (
      <div className={`alert alert-${props.type}`}>
        <p className="text-center">
          {props.error && trans("client-error-message")}
        </p>
        <ul>
          <li>{text[text.length - 1].message}</li>
        </ul>
        {props.closeable && (
          <span className="close-icon" onClick={clear}>
            x
          </span>
        )}
      </div>
    );
  }

  return <></>;
};

export default Alert;
