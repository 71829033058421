import React from "react";
import useControlInstanceTime from "../../../hooks/useControlInstanceTime";
import moment from "moment";
import TimeView from "./TimeView";
import {TApiTrafficLightsConfig} from "../../../types/ControlGroup";

interface IControlInstanceTimeProps {
  dueStartTime?: string;
  dueEndTime?: string;
  TrafficLights: TApiTrafficLightsConfig | null;
}

const ControlInstanceTime = ({
  dueStartTime,
  dueEndTime,
  TrafficLights
}: IControlInstanceTimeProps) => {
  const [
    remainingDays,
    remainingHours,
    remainingMinutes,
    color,
    hasEnded,
  ] = useControlInstanceTime(dueEndTime, dueStartTime, TrafficLights);
  const hasStarted = moment(dueStartTime).diff(new Date()) < 0;

  if (!hasStarted) {
    return <div />;
  }
  return (
    <TimeView
      days={remainingDays}
      hours={remainingHours}
      minutes={remainingMinutes}
      color={color}
      hasEnded={hasEnded}
    />
  );
};

export default ControlInstanceTime;
