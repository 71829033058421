import { Reducer } from "redux";
import {
  AuthActions,
  Authenticate,
  CheckAuthPermission,
  GetLanguages,
  IAuthState,
  initialAuthState
} from "../types/Auth";
import { removeLocalStorage, setLocalStorage } from "../generic/Helpers";
import { GetReportProgress, ReportInit } from "../types/Report";

export const authReducer: Reducer<IAuthState, AuthActions> = (
  state = initialAuthState,
  action
) => {
  switch (action.type) {
    case Authenticate.REQUEST: {
      return {
        isFetching: true
      };
    }

    case Authenticate.SUCCESS: {
      setLocalStorage("token", action.payload.data.Token);
      return {
        data: action.payload.data
      };
    }

    case Authenticate.FAILED: {
      return {
        error: true,
        errorMessage: "" // action.payload.data.error this broke the app as no erro message was present
      };
    }

    case Authenticate.AUTH_CHECK: {
      return {
        ...state,
        authCheck: true
      };
    }

    case Authenticate.LOGOUT:
      removeLocalStorage("token");
      return state;

    case CheckAuthPermission.SUCCESS:
      return {
        ...state,
        permissions: action.payload.data.role
      };

    case GetLanguages.SUCCESS:
      return {
        ...state,
        languages: action.payload.data.languages
      };

    case Authenticate.SET_LANG:
      setLocalStorage("lang", action.payload);
      return {
        ...state,
        lang: action.payload
      };

    case CheckAuthPermission.FAILED:
      removeLocalStorage("token");
      return state;

    case ReportInit.SUCCESS:
      return {
        ...state,
        reportGuid: action.payload.data.operation.UniqueIdentifier
      };

    case GetReportProgress.SUCCESS:
      return {
        ...state,
        reportProgress: action.payload.data.operation.Progress,
        reportUrl: action.payload.data.operation.Command.ReportFragments[0].url
      };

    case ReportInit.CLEAR:
      return {
        ...state,
        reportGuid: undefined,
        reportProgress: undefined,
        reportUrl: undefined
      };

    default:
      return state;
  }
};
