import { combineReducers } from "redux";

import { PerformAdHoc, CreateReminder } from "../types/Requests";
import { ReportInit } from "../types/Report";
import { DismissInstance } from "../types/ControlPoint";
import { ControlGroup } from "../types/ControlGroup";

// TODO: move all loading states here
// adHocPost: [
//   [PerformAdHoc.REQUEST], isFetching = true, hasFetched = false
//   [PerformAdHoc.FAILED, PerformAdHoc.SUCCESS] isFetching = false, hasFeched = true
// ],
const loaders = {
  adHocPost: [
    [PerformAdHoc.REQUEST],
    [PerformAdHoc.FAILED, PerformAdHoc.SUCCESS]
  ],
  // TODO: replace controlGroupReducer isFetching with loader.controlGroup
  controlGroup: [
    [ControlGroup.REQUEST],
    [ControlGroup.FAILED, ControlGroup.SUCCESS]
  ],
  reportInit: [[ReportInit.REQUEST], [ReportInit.SUCCESS, ReportInit.FAILED]],
  createReminder: [
    [CreateReminder.REQUEST],
    [CreateReminder.FAILED, CreateReminder.SUCCESS]
  ],
  dismissInstance: [
    [DismissInstance.REQUEST],
    [DismissInstance.FAILED, DismissInstance.SUCCESS]
  ]
};

const getReducer = ([isFetching, hasFetched]: Array<Array<string>>) => {
  const initialState = {
    hasFetched: false,
    isFetching: false
  };
  return (state = initialState, action: any) => {
    if (isFetching.includes(action.type)) {
      return { ...state, isFetching: true };
    }
    if (hasFetched.includes(action.type)) {
      return { hasFetched: true, isFetching: false };
    }
    return state;
  };
};

export default function loaderReducer() {
  const reducers: any = {}; // TODO: add types
  Object.entries(loaders).forEach(([key, val]) => {
    reducers[key] = getReducer(val);
  });
  return combineReducers(reducers);
}
