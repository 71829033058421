import React from "react";

import { ControlPointType } from "../../../generic/Config";
import NumberInput from "./NumberInput";
import DateInput from "./DateInput";
import ChooseFromList from "./ChooseFromList";
import moment from "moment";

// TODO: add prop-types
// adding prop types will require changing switch statement to if
// because some props are mandatory for children components, but not parent
const InputType = ({
  type,
  value,
  onChange,
  dropdownListId,
  disabled = false,
  conditionDetails: { ViewMode: viewMode }
}: {
  type: ControlPointType;
  value: any;
  onChange: any;
  dropdownListId: number | null;
  disabled?: boolean;
  conditionDetails: {
    ViewMode?: string;
  };
}) => {
  switch (type) {
    case ControlPointType.SELECT_FROM_LIST:
      return (
        <ChooseFromList
          value={value}
          onChange={onChange}
          dropdownListId={dropdownListId}
          disabled={disabled}
          viewMode={viewMode}
        />
      );
    case ControlPointType.DATE:
      return (
        <DateInput
          value={moment(value || new Date()).toDate()}
          onChange={(val: any) => onChange(moment(val).format("YYYY-MM-DD"))}
          disabled={disabled}
        />
      );
    case ControlPointType.YESNO:
    case ControlPointType.TIMESTAMP:
      return null;
    case ControlPointType.NUMBER:
    case ControlPointType.INTERVAL:
    default:
      return (
        <NumberInput
          value={value}
          onChange={(e: any) => onChange(e.target.value)}
          disabled={disabled}
        />
      );
  }
};

export default InputType;
