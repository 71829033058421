import { Reducer } from "redux";
import { sortBy } from "lodash";
import {
  CustomerList,
  TCustomerListState,
  initialCustomerListState,
  TGetCustomerListAction
} from "../types/Customer";
import {endReducer} from "../store/Store";

export const customerListReducer: Reducer<
  TCustomerListState,
  TGetCustomerListAction
> = (state = initialCustomerListState, action) => {
  switch (action.type) {
    case CustomerList.REQUEST: {
      return {
        isFetching: true
      };
    }

    case CustomerList.SUCCESS: {
      return {
        isFetching: false,
        data: {
          ...action.payload.data,
          customers: sortBy(
            action.payload.data.customers,
            (customer) => {
              return customer.TreeNode.Name.toLocaleLowerCase();
            }
          )
        }
      };
    }

    case CustomerList.FAILED:
      // TODO: what happens to the error info?
      return state;

    default:
      return endReducer(state, action);
  }
};
