import { AxiosResponse } from "axios";

export interface TApiDocEntity {
  ID: number;
  Text: string;
  Title: string;
}

export interface TApiDocumentation {
  doc: TApiDocEntity;
  filestoreBaseUrl: string;
}

export interface TDocState {
  readonly isFetching?: boolean;
  readonly data?: TApiDocumentation;
}

export const initialDocState: TDocState = {
  isFetching: false,
  data: undefined
};

export enum DocumentationGet {
  REQUEST = "DOC_GET_REQUEST",
  SUCCESS = "DOC_GET_SUCCESS",
  FAILED = "DOC_GET_FAILED"
}

export type TGetDocumentationAction  = {
  type: DocumentationGet.REQUEST;
} | {
  type: DocumentationGet.SUCCESS;
  payload: AxiosResponse<TApiDocumentation>;
} | {
  type: DocumentationGet.FAILED;
  payload: AxiosResponse;
}

export type TDocActions = TGetDocumentationAction;
