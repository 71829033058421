import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import BaseError from "./BaseError";

interface IOfflineErrorProps extends RouteComponentProps<any> {
  beforeRefresh?: Function;
}

// TODO: add translations, figure out if number values are working
// TODO: case before fetching translations
function OfflineError({ beforeRefresh, history }: IOfflineErrorProps) {
  const [remainingSeconds, setRemainingSeconds] = useState(15);
  const handleRefreshClick = () => {
    if (beforeRefresh) {
      beforeRefresh();
    }
    history.go(0);
  };
  useEffect(() => {
    const refresh = () => {
      if (remainingSeconds <= 1) {
        if (beforeRefresh) {
          beforeRefresh();
        }
        history.go(0);
      }
      setRemainingSeconds(remainingSeconds - 1);
    };
    setTimeout(refresh, 1000);
  }, [remainingSeconds]);
  return (
    <BaseError
      title={`Offline`}
      info={`You appear to be offline, refreshing the page in ${remainingSeconds} seconds`}
      buttonText={`refresh`}
      handleClick={handleRefreshClick}
    />
  );
}

export default withRouter(OfflineError);
