import * as React from "react";
import {
  TApiControlPointEntity,
  TControlPointInstance, TControlPointTypeBase
} from "../../../../types/ControlPoint";
import {
  controlPointControls,
  generateControlPointInstance,
  getControlPointInfo,
  getControlPointOpenInstance,
  getDocumentation
} from "../../../../generic/Helpers";
import ListItem from "../../../ListItem";
import ControlPointBase from "../../../control-point/ControlPointBase";
import { TControlGroupState } from "../../../../types/ControlGroup";
import { RouteComponentProps, withRouter } from "react-router";

interface IOpenInstanceProps extends RouteComponentProps<any> {
  controlGroup: TControlGroupState;
  controlPoint: TApiControlPointEntity;
  openByDefault?: boolean;
  hideContextMenu?: boolean;
}

const OpenInstance: React.FC<IOpenInstanceProps> = ({
  controlGroup,
  controlPoint,
  openByDefault,
  hideContextMenu,
  match: {
    params: { controlGroupId }
  }
}): JSX.Element => {
  const instance: TControlPointInstance<TControlPointTypeBase> = generateControlPointInstance(
    controlGroup,
    controlGroup.data &&
      controlGroup.data.openInstances[controlPoint.TreeNode.ID]
  );
  const controlPointInfo = getControlPointInfo(
    controlGroup.data && controlGroup.data.info,
    controlPoint.TreeNode.ID
  );
  const controlPointOpenInstance = getControlPointOpenInstance(
    controlGroup.data && controlGroup.data.openInstances,
    controlPoint.TreeNode.ID
  );

  const doc = getDocumentation(
    controlPoint.TreeNode.ID,
    controlGroup.data ? controlGroup.data.documentation : {}
  );

  return (
    <ListItem
      key={controlPoint.TreeNode.ID}
      title={
        controlPointOpenInstance.IsReminder
          ? controlPointOpenInstance.ForcedTitle
          : controlPointInfo.TreeNode.Name
      }
      detail={
        !controlPointOpenInstance.ForcedNotes
          ? controlPointInfo.TreeNode.Notes
          : controlPointOpenInstance.ForcedNotes
      }
      itemType={"control-point"}
      id={controlPoint.TreeNode.ID}
      settings={!hideContextMenu}
      docs={!!doc}
      openByDefault={openByDefault}
      controls={controlPointControls(
        controlPointOpenInstance,
        controlGroupId,
        doc
      )}
      content={<ControlPointBase instance={instance} />}
    />
  );
};

export default withRouter(OpenInstance);
