import React from "react";
import { trans } from "../../../generic/Helpers";

const Submit = ({
  buttonText = "global-ok",
  completed,
  onClick,
  disabled = false,
}: {
  buttonText?: string;
  completed?: boolean;
  onClick?: any;
  disabled?: boolean;
}) => (
  <div className="control-point-form__button input-group mt-3 d-flex justify--center">
    <button
      disabled={disabled}
      onClick={onClick ? onClick : null}
      className={`btn btn-small ${completed ? "btn-inverse" : "btn-primary"}`}
    >
      {trans(completed ? "global-edit" : buttonText)}
    </button>
  </div>
);

export default Submit;
