import { useEffect, useRef } from "react";

const useScrollToElement = (condition: boolean, triggers: Array<any>) => {
  const itemRef = useRef<HTMLInputElement>(null);
  const scrollToElement = () => {
    if (itemRef && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };
  useEffect(() => {
    if (condition) {
      scrollToElement();
    }
  }, triggers);
  return [itemRef];
};

export default useScrollToElement;
