import axios, { Method } from "axios";
import { getLocalStorage } from "./Helpers";
import { config } from "./Config";
import { ActionCreator, Dispatch } from "redux";
import { AxiosResponse, AxiosError } from "axios";
import { AsyncThunkAction } from "../types/Requests";
import { logoutUser } from "../actions/AuthActions";

export const apiCall = async <TRequestData = any, TResponse extends {} = {}>(
  method: Method,
  url: string,
  data: TRequestData | null = {} as TRequestData,
  authorized: boolean = false,
  params: any = {}
): Promise<AxiosResponse<TResponse>> => {
  return await axios.request<TResponse>({
    data,
    headers: {
      Authorization: authorized ? "Bearer " + getLocalStorage("token") : "",
      "Content-Type": "application/json"
    },
    method,
    params,
    url: `${config.BASE_API_URL}${url}`
  });
};

type TAsyncRequestAction = {
  REQUEST: string;
  SUCCESS: string;
  FAILED: string;
};

export const asyncRequest: ActionCreator<AsyncThunkAction<any, any>> = <
  TResponse = any
>(
  actionType: TAsyncRequestAction,
  request: Promise<AxiosResponse<TResponse>>,
  successCallback?: () => any,
  action?: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.REQUEST,
      action
    });
    await request
      .then(res => {
        dispatch({
          payload: res,
          type: actionType.SUCCESS,
          action
        });
      })
      .then(() => {
        if (typeof successCallback === "function") {
          return successCallback();
        }
      })
      .catch((err: AxiosError) => {
        switch (err.code) {
          case "401":
            dispatch(logoutUser());
            break;

          default:
            dispatch({
              payload: { errorResponse: err.response },
              type: actionType.FAILED,
              action
            });
        }
      });
  };
};

export const generateUrl = (
  customerId?: number,
  sipId?: number,
  controlGroupId?: number,
  additional?: string
): string => {
  let url: string = "/app";

  if (customerId) {
    url = url + `/customer/${customerId}`;
  }

  if (sipId) {
    url = url + `/sip/${sipId}`;
  }

  if (controlGroupId) {
    url = url + `/control-group/${controlGroupId}`;
  }

  if (additional) {
    url = url + `/${additional}`;
  }

  return url;
};
