import * as React from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import { trans } from "../../generic/Helpers";
import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { createAdHoc } from "../../actions/ControlPointActions";
import { IAdHocInstance } from "../../types/Requests";
import { generateUrl } from "../../generic/Requests";
import { IAppState } from "../../store/Store";
import ReactDatePicker from "react-datepicker";
import { useAdHocPerformTime } from "../../hooks/useAdHocPerformTime";

interface IAdHocProps extends RouteComponentProps<any> {}

const AdHoc: React.FC<IAdHocProps> = ({
  match: {
    params: { customerId, sipId, controlGroupId }
  },
  history,
  location
}): JSX.Element => {
  const [maxDate] = useAdHocPerformTime();
  const [startDate, setStartDate] = useState<any>(
    location.state.startDate ? location.state.startDate : new Date()
  );
  const adHocLoader = useSelector<IAppState, any>(
    (state: IAppState) => state.loader.adHocPost
  );
  const [notes, setNotes] = useState("");
  const [reminder, setReminder] = useState(false);
  const dispatch = useDispatch<Dispatch<any>>();

  const adHocType: "sip" | "control-group" = controlGroupId
    ? "control-group"
    : "sip";

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const start = new Date();
    const end = new Date();
    end.setTime(end.getTime() + 24 * 60 * 60 * 1000);
    const instance: IAdHocInstance = {
      sip: controlGroupId ? null : sipId,
      controlGroup: controlGroupId,
      controlPoint: null,
      customFields: [],
      endTime: end.toISOString(),
      userNotes: notes,
      startTime: start.toISOString(),
      performTime: startDate.toISOString()
    };
    dispatch(
      createAdHoc(instance, () => {
        if (!reminder) {
          location.state.redirectUrl
            ? history.push(location.state.redirectUrl)
            : history.goBack();
        } else {
          const pathname = generateUrl(
            customerId,
            sipId,
            controlGroupId,
            `reminder`
          );

          history.push({
            pathname: pathname,
            state: {
              instanceRequest: instance,
              redirectUrl: location.state.redirectUrl
            }
          });
        }
      })
    );
  };

  return (
    <>
      <Navbar
        headerText={trans("page-adhoc-title")}
        backLink={() => {
          history.goBack();
        }}
      />
      <section>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="notes" className="text-center">
            {trans("text-adhoc-usage")}
          </label>
          <div className="input-group">
            <textarea
              className="textarea mt-1"
              id="notes"
              name="info"
              placeholder={trans("input-notes")}
              value={notes}
              onChange={e => setNotes(e.target.value)}
              required={true}
            />
          </div>
          {!location.state.startDate && (
            <div className="text-block mt-3">
              <label htmlFor="start">{trans("input-select-date")}</label>
              <div className="input-group mb-3">
                <ReactDatePicker
                  selected={startDate}
                  onChange={e => setStartDate(e)}
                  showTimeSelect={true}
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  maxDate={maxDate}
                  className="inp"
                />
              </div>
            </div>
          )}
          {adHocType !== "sip" && (
            <div className="input-group mt-5 mb-5">
              <input
                type="checkbox"
                id="reminder"
                className="checkbox"
                checked={reminder}
                onChange={() => setReminder(!reminder)}
              />
              <label htmlFor="reminder">{trans("input-create-reminder")}</label>
            </div>
          )}

          <button
            disabled={adHocLoader.isFetching}
            type="submit"
            className="btn btn-primary btn-login mt-3"
          >
            {trans("input-save")}
          </button>
        </form>
      </section>
    </>
  );
};

export default AdHoc;
