import React, { useState, useRef } from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";

import iconPencil from "../../assets/images/icons/icon-pencil.svg";
import { trans } from "../../generic/Helpers";

interface NotesSlideInputProps {
  disabled?: boolean;
  value: any;
  setValue: any;
}

export default function NotesSlideInput(props: NotesSlideInputProps) {
  const { value, setValue } = props;
  const [showInput, setShowInput] = useState(!!value);
  const [showButton, setShowButton] = useState(!value);
  const notesRef = useRef<HTMLTextAreaElement>(null);

  const handleShowNotesClick = () => {
    setShowInput(true);
    setShowButton(false);
    setValue("");
    setTimeout(() => {
      if (notesRef && notesRef.current) {
        notesRef.current.focus();
      }
    }, 200);
  };
  const handleCloseNotesClick = () => {
    setShowInput(false);
    setTimeout(() => setShowButton(true), 200);
  };

  return (
    <div className="notes-slide d-flex direction--column align-items--center mt-3">
      {showButton && (
          <button
            disabled={props.disabled}
            className="btn btn-small btn-inverse"
            onClick={handleShowNotesClick}
            type="button"
          >
            {trans("notes-slide-add-notes-button")}
          </button>
      )}
      {showInput && (
        <Fade in={showInput}>
          <div className="form-control">
            <div className="form-control__icons">
              <img src={iconPencil} className="inp-addon" alt="" />
            </div>
            <textarea
              ref={notesRef}
              className="form-control__input textarea"
              placeholder={trans("input-tap-to-enter-notes")}
              value={value}
              onChange={e => setValue(e.target.value)}
              disabled={props.disabled}
            />
            <div className="form-control__actions">
              <IconButton onClick={handleCloseNotesClick}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </Fade>
      )}
    </div>
  );
}
