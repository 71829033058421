import * as React from "react";
import { RouteComponentProps, Prompt } from "react-router";
import Navbar from "../nav/Navbar";
import { generateUrl } from "../../generic/Requests";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { FormEvent, useEffect, useState } from "react";
import {
  createAdHoc,
  getDeviation,
  performInstance
} from "../../actions/ControlPointActions";
import { trans } from "../../generic/Helpers";
import { IAppState } from "../../store/Store";
import { TDeviationState } from "../../types/Deviation";
import Loading from "../Loading";
import { getControlGroup } from "../../actions/ControlGroupActions";
import { TControlGroupState } from "../../types/ControlGroup";
import { ControlGroupType } from "../../generic/Config";
import { getSip } from "../../actions/SipActions";
import { TSipState } from "../../types/Sip";
import { useRepeatControlGroupForm } from "../../hooks/useRepeatControlGroupForm";

interface IDeviationProps extends RouteComponentProps<any> {}

// TODO: rewrite deviation
const Deviation: React.FC<IDeviationProps> = (props): JSX.Element => {
  const {
    customerId,
    sipId,
    controlGroupId,
    controlPointId
  } = props.match.params;
  const {
    instance,
    data,
    instanceRequest,
    isFromRepeatControlGroup = false
  } = props.location.state;
  const devs: TDeviationState = useSelector<IAppState, TDeviationState>(
    (state: IAppState) => state.deviation
  );
  const sip: TSipState = useSelector<IAppState, TSipState>(
    (state: IAppState) => state.sipState
  );
  const controlGroup: TControlGroupState = useSelector<
    IAppState,
    TControlGroupState
  >((state: IAppState) => state.controlGroupState);
  const dispatch = useDispatch<Dispatch<any>>();

  // use repeat control saved values if it exists
  // TODO: add proper state handling
  const { forms = {}, setFormFields } = useRepeatControlGroupForm();
  const {
    deviations: deviationsForm = [{ failureReason: "", remedyAction: "" }],
    createReminder = false
  } = forms[controlPointId] || {};

  const [deviations, setDeviations] = useState(deviationsForm);
  const [reminder, setReminder] = useState<boolean>(createReminder);
  useEffect(() => {
    if (
      isFromRepeatControlGroup &&
      (!forms[controlPointId] ||
        (!forms[controlPointId].userInput &&
          typeof forms[controlPointId].userInput !== typeof false))
    ) {
      props.history.push({
        pathname: generateUrl(customerId, sipId, controlGroupId, `repeat`)
      });
    }
  });

  useEffect(() => {
    if (instance.ActionSetID) {
      dispatch(getDeviation(instance.ActionSetID));
    }
  }, [dispatch, instance.ActionSetID]);

  useEffect(() => {
    dispatch(getControlGroup(controlGroupId));
  }, [dispatch, controlGroupId]);

  useEffect(() => {
    dispatch(getSip(sipId));
  }, [dispatch, sipId]);

  const handleDeviationInfo = (index: number, key: string) => (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const array: any = [...deviations];
    array[index][key] = e.target.value;

    setDeviations(array);
  };

  const removeDeviationBlock = (index: number) => (e: FormEvent) => {
    e.preventDefault();
    const array = [...deviations];
    array.splice(index, 1);
    setDeviations(array);
  };

  const addNewDeviationBlock = (e: FormEvent) => {
    e.preventDefault();
    setDeviations([...deviations, { failureReason: "", remedyAction: "" }]);
  };

  const performControlPointAction = (e: FormEvent) => {
    e.preventDefault();

    if (isFromRepeatControlGroup) {
      if (reminder) {
        setFormFields(instance.TreeNode.ID, {
          deviations,
          createReminder: true,
          completed: false
        });
        return props.history.push({
          pathname: generateUrl(
            customerId,
            sipId,
            controlGroupId,
            controlPointId ? `reminder/${controlPointId}` : `reminder`
          ),
          state: {
            instance,
            isFromRepeatControlGroup: true,
            instanceRequest
          }
        });
      }
      setFormFields(instance.TreeNode.ID, {
        deviations,
        createReminder: false,
        completed: true
      });
      return props.history.push({
        pathname: generateUrl(customerId, sipId, controlGroupId, `repeat`)
      });
    }

    const callback = () => {
      if (!reminder) {
        return props.history.push(
          generateUrl(customerId, sipId, controlGroupId)
        );
      } else {
        const pathname = generateUrl(
          customerId,
          sipId,
          controlGroupId,
          controlPointId ? `reminder/${controlPointId}` : `reminder`
        );
        return props.history.push({
          pathname: pathname,
          state: {
            instance,
            instanceRequest,
            data
          }
        });
      }
    };

    if (props.location.state.isAdHoc) {
      const instanceToSend = {
        ...props.location.state.adHocInstance,
        deviations
      };
      dispatch(createAdHoc(instanceToSend, callback));
    } else {
      const instanceToSend = {
        ...instanceRequest,
        deviations
      };
      dispatch(performInstance(instanceToSend, callback));
    }
  };

  const displayReminderCheckbox = () => {
    return (
      sip.data &&
      !sip.data.node.Auditable &&
      controlGroup.data &&
      controlGroup.data.node.CheckpointType !== ControlGroupType.FORMULA &&
      controlGroup.data.node.CheckpointType !== ControlGroupType.WORKFLOW
    );
  };

  const handlePrompt = (location: any) => {
    if (isFromRepeatControlGroup) {
      const repeatPathname = generateUrl(
        customerId,
        sipId,
        controlGroupId,
        `repeat`
      );
      const reminderRepeatPathname = generateUrl(
        customerId,
        sipId,
        controlGroupId,
        controlPointId ? `reminder/${controlPointId}` : `reminder`
      );
      if (
        [repeatPathname, reminderRepeatPathname].includes(location.pathname)
      ) {
        return true;
      }
      const hasEditedForm =
        isFromRepeatControlGroup &&
        !!forms[controlPointId] &&
        !!forms[controlPointId].userInput;
      if (hasEditedForm) {
        return trans("repeat-control-group-point-deviation-unfinished");
      }
    }
    return true;
  };

  return (
    <>
      <Navbar
        headerText={trans("page-deviation-title")}
        backLink={() => {
          props.history.push(generateUrl(customerId, sipId, controlGroupId));
        }}
      />
      <Prompt when message={handlePrompt} />
      {sip.isFetching || controlGroup.isFetching ? (
        <Loading text={trans("global-loading")} />
      ) : (
        <section>
          <form
            method="POST"
            className="form-group form-vertical"
            onSubmit={performControlPointAction}
          >
            {deviations.map((deviation: any, index: number) => {
              return (
                <div key={index} className="text-block">
                  <div className="input-group direction--column align-items--start">
                    <label htmlFor={"failure-reason-" + index}>
                      {trans("input-failure-reason")}
                    </label>
                    {devs && devs.isFetching ? (
                      <Loading text={trans("global-loading")} />
                    ) : (
                      devs.data &&
                      devs.data.deviation && (
                        <select
                          onChange={handleDeviationInfo(index, "failureReason")}
                          className="mt-2 mb-2"
                        >
                          <option value="">
                            {trans("input-select-failure-reason")}
                          </option>
                          {devs.data.deviation.FailureReasons.map(
                            (reason: any) => {
                              return (
                                <option key={reason.ID} value={reason.Text}>
                                  {reason.Text}
                                </option>
                              );
                            }
                          )}
                        </select>
                      )
                    )}

                    <textarea
                      name={"failure-reason-" + index}
                      id={"failure-reason-" + index}
                      value={deviation.failureReason}
                      className="textarea"
                      placeholder={trans("input-tap-to-add-notes")}
                      onChange={handleDeviationInfo(index, "failureReason")}
                      required={true}
                    />
                  </div>
                  <div className="input-group direction--column align-items--start mt-3">
                    <label htmlFor={"remedying-action-" + index}>
                      {trans("input-remedying-action")}
                    </label>
                    {devs && devs.isFetching ? (
                      <Loading text={trans("global-loading")} />
                    ) : (
                      devs.data &&
                      devs.data.deviation && (
                        <select
                          onChange={handleDeviationInfo(index, "remedyAction")}
                          className="mt-2 mb-2"
                        >
                          <option value="">
                            {trans("input-select-remedying-action")}
                          </option>
                          {devs.data.deviation.RemedyingActions.map(
                            (reason: any) => {
                              return (
                                <option key={reason.ID} value={reason.Text}>
                                  {reason.Text}
                                </option>
                              );
                            }
                          )}
                        </select>
                      )
                    )}
                    <textarea
                      name={"remedying-action-" + index}
                      id={"remedying-action-" + index}
                      value={deviation.remedyAction}
                      className="textarea"
                      placeholder={trans("input-tap-to-add-notes")}
                      onChange={handleDeviationInfo(index, "remedyAction")}
                      required={true}
                    />
                  </div>
                  {index > 0 && (
                    <div className="input-group direction--column align-items--end mt-3">
                      <button
                        onClick={removeDeviationBlock(index)}
                        className="btn btn-primary btn-small"
                      >
                        {trans("global-remove")}
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
            <button
              onClick={addNewDeviationBlock}
              className="btn btn-primary btn-small mb-3"
            >
              {trans("input-add-deviation")}
            </button>
            <div className="text-block">
              {displayReminderCheckbox() && (
                <div className="input-group">
                  <input
                    checked={reminder}
                    type="checkbox"
                    id="create-reminder"
                    onChange={() => setReminder(!reminder)}
                  />
                  <label htmlFor="create-reminder">
                    {trans("input-create-reminder")}
                  </label>
                </div>
              )}
              <div className="input-group mt-3 d-flex justify--center">
                <button type="submit" className="btn btn-primary btn-small">
                  {trans("input-save")}
                </button>
              </div>
            </div>
          </form>
        </section>
      )}
    </>
  );
};

export default Deviation;
