import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDropdown } from "../actions/ControlGroupActions";

// TODO: convert to ts
export const useDropdown = dropdownListId => {
  const dispatch = useDispatch();
  const { dropdown = {} } = useSelector(state => state.controlGroupState);

  useEffect(() => {
    if (dropdownListId && !dropdown[dropdownListId]) {
      dispatch(getDropdown(dropdownListId));
    }
  }, []);

  return dropdown[dropdownListId] || {};
};
