import { AsyncThunkAction } from "../types/Requests";
import {
  Authenticate,
  ChangePassword,
  CheckAuthPermission,
  Errors,
  GetLanguages,
  IAuthAction,
  IAuthState,
  IGetLanguagesAction,
  ResetPassword,
  TApiLanguageList
} from "../types/Auth";
import { apiCall, asyncRequest } from "../generic/Requests";
import { Dispatch } from "redux";
import { config } from "../generic/Config";
import {
  getLocalStorage,
  keyExists,
  removeLocalStorage
} from "../generic/Helpers";
import axios from "axios";
import { GetTranslations, TApiTranslationData } from "../types/Translation";

export const loginUser = (
  email: string,
  password: string,
  callback?: (...args: any) => any
): AsyncThunkAction<IAuthState, IAuthAction> => {
  return asyncRequest(
    Authenticate,
    apiCall("POST", "/auth/login", { password, username: email }),
    callback
  );
};

export const logoutUser = (): any => {
  apiCall("POST", "/auth/logout", null, true);
  removeLocalStorage("token");
  removeLocalStorage("currentAuditConfig");
  removeLocalStorage("currentAudit");
  removeLocalStorage("currentAuditId");
  removeLocalStorage("lang");
  removeLocalStorage("trans");
  window.location.replace(`${process.env.PUBLIC_URL}/`);
};

export const checkIfTokenIsValid = (): AsyncThunkAction<
  IAuthState,
  IAuthAction
> => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: Authenticate.AUTH_CHECK
    });
    await axios
      .get(`${config.BASE_API_URL}/auth/query`, {
        params: { key: getLocalStorage("token") }
      })
      .then((res: any) => {
        dispatch({
          payload: res,
          type: Authenticate.SUCCESS
        });
        dispatch(getUserPermissions());
        dispatch(
          getTranslations(
            keyExists("lang") ? getLocalStorage("lang") : config.DEFAULT_LANG
          )
        );
      })
      .catch(err => {
        if (err && err.reponse && err.response.status) {
          switch (String(err.response.status)) {
            case "404":
            case "401":
              dispatch(logoutUser());
              break;

            default:
              dispatch({
                payload: { errorResponse: err.response },
                type: Authenticate.FAILED
              });
          }
        }
        // TODO: check if some cases might return no error.response object
        // handling unexpected behaviour, need to test
        return dispatch(logoutUser());
      });
  };
};

export const getUserPermissions = (): any => {
  return asyncRequest(
    CheckAuthPermission,
    apiCall("GET", "/auth/permissions", {}, true),
    null
  );
};

export const getTranslations = (
  languageCode: string = config.DEFAULT_LANG
): any => {
  return asyncRequest(
    GetTranslations,
    apiCall<null, TApiTranslationData>(
      "GET",
      `/l10n/translations/${languageCode}`,
      null,
      false
    ),
    null
  );
};

export const getLanguages = (): AsyncThunkAction<
  IAuthState,
  IGetLanguagesAction
> => {
  return asyncRequest(
    GetLanguages,
    apiCall<null, TApiLanguageList>("GET", "/l10n/languages", null, true),
    null
  );
};

export const setLanguage = (languageCode: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      payload: languageCode,
      type: Authenticate.SET_LANG
    });
  };
};

export const clearErrors = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: Errors.CLEAR_ERRORS
    });
  };
};

export const changePassword = (
  currentPassword: string,
  newPassword: string,
  confirmation: string
): AsyncThunkAction<IAuthState, IAuthAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ChangePassword.REQUEST
    });
    return await apiCall(
      "post",
      `/auth/changePassword`,
      {
        currentPassword,
        newPassword,
        confirmation
      },
      true
    ).then(
      (res: any) => {
        dispatch({
          payload: { data: res.data.Token },
          type: Authenticate.SUCCESS
        });
        return res;
      },
      (err: any) => {
        return Promise.reject(err.response.data);
      }
    );
  };
};

export const requestPasswordReset = (
  email: string
): AsyncThunkAction<IAuthState, IAuthAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ResetPassword.REQUEST
    });
    return await apiCall(
      "post",
      `/auth/resetPassword/start`,
      {
        email
      },
      false
    ).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        return Promise.reject(err.response.data);
      }
    );
  };
};

export const getPasswordResetStatus = (
  token: string
): AsyncThunkAction<IAuthState, IAuthAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ResetPassword.QUERY
    });
    return await apiCall("get", `/auth/resetPassword`, {}, false, {
      token
    }).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        return Promise.reject(err.response.data);
      }
    );
  };
};

export const confirmPasswordReset = (
  token: string,
  newPassword: string,
  confirmation: string
): AsyncThunkAction<IAuthState, IAuthAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ResetPassword.CONFIRM
    });
    return await apiCall(
      "post",
      `/auth/resetPassword`,
      {
        token,
        newPassword,
        confirmation
      },
      false
    ).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        return Promise.reject(err.response.data);
      }
    );
  };
};
