import { ControlPointType } from "../../../../generic/Config";
import { trans } from "../../../../generic/Helpers";

export const getNumberTitleText = (conditionDetails: any) => {
  let informationText = "";
  const comparisonOperator =
    conditionDetails && conditionDetails.ComparisonOperator;
  const comparisonValue = conditionDetails && conditionDetails.ComparisonValue;
  const measurementUnit = conditionDetails && conditionDetails.MeasurementUnit;

  switch (comparisonOperator) {
    case "M":
      informationText = `${trans(
        "value-must-be-more-than"
      )} ${comparisonValue}${measurementUnit}`;
      break;

    case "ME":
      informationText = `${trans(
        "value-must-be-more-or-equal-to"
      )} ${comparisonValue}${measurementUnit}`;
      break;

    case "E":
      informationText = `${trans(
        "value-must-be-equal-to"
      )} ${comparisonValue}${measurementUnit}`;
      break;

    case "LE":
      informationText = `${trans(
        "value-must-be-less-or-equal-to"
      )} ${comparisonValue}${measurementUnit}`;
      break;

    case "L":
      informationText = `${trans(
        "value-must-be-less-than"
      )} ${comparisonValue}${measurementUnit}`;
      break;
  }
  return informationText;
};

export const getIntervalTitleText = (conditionDetails: any) => {
  return `${trans("value-must-be-between")} ${conditionDetails &&
    conditionDetails.ComparisonLeft} ${trans(
    "global-and"
  )} ${conditionDetails &&
    conditionDetails.ComparisonRight} (${conditionDetails &&
    conditionDetails.ComparisonType})`;
};

export const getDateTitleText = (conditionDetails: any) => {
  return `${trans("date-must-be")} ${conditionDetails &&
    conditionDetails.DaysInAdvance} ${trans("days-in-advance")}`;
};

export const getYesNoTitleText = (conditionDetails: any) => {
  return `${trans("selected-value-must-be")} ${
    conditionDetails && conditionDetails.ComparisonValue ? trans("global-yes") : trans("global-no")
  }`;
};

export const getTimestampTitleText = () => {
  return `${new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, "-")}`;
};

const getTitleText = (type: ControlPointType, conditionDetails: any) => {
  if (type === ControlPointType.NUMBER) {
    return getNumberTitleText(conditionDetails);
  }
  if (type === ControlPointType.INTERVAL) {
    return getIntervalTitleText(conditionDetails);
  }
  if (type === ControlPointType.DATE) {
    return getDateTitleText(conditionDetails);
  }
  if (type === ControlPointType.YESNO) {
    return getYesNoTitleText(conditionDetails);
  }
  if (type === ControlPointType.SELECT_FROM_LIST) {
    return "";
  }
  if (type === ControlPointType.TIMESTAMP) {
    return getTimestampTitleText();
  }
  return `NO_TITLE_FOR_TYPE_${type}`;
};

export default getTitleText;
