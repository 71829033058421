import { Reducer } from "redux";
import {initialSipState, TSipState, Sip, TGetSipAction} from "../types/Sip";
import { sortBy } from "lodash";
import {endReducer} from "../store/Store";

export const sipReducer: Reducer<TSipState, TGetSipAction> = (
  state = initialSipState,
  action
) => {
  switch (action.type) {
    case Sip.REQUEST: {
      return {
        isFetching: true
      };
    }

    case Sip.SUCCESS:
      return {
        isFetching: false,
        data: {
          ...action.payload.data,
          contents: sortBy(
            action.payload.data.contents,
            v => v.TreeNode.TreeLeft
          )
        }
      };

      case Sip.FAILED:
          return state;

      default:
          return endReducer(state, action);
  }
};
