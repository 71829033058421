import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { Dispatch } from "redux";

import { IAppState } from "../../../store/Store";
import AcceptContainer from "./AcceptContainer";
import DeclinedContainer from "./DeclinedContainer";
import { apiCall } from "../../../generic/Requests";
import { checkIfTokenIsValid } from "../../../actions/AuthActions";

const requiresAcceptTOS = (Component: React.ComponentType) => {
  const RequireTOS: React.FC = () => {
    const dispatch = useDispatch<Dispatch<any>>();
    const auth = useSelector<IAppState, any>(state => state.authState);
    const acceptedTOS = get(auth, "data.AcceptedTermsOfUse");
    const [hasDeclined, setHasDeclined] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);

    const handleDeclineClick = () => setHasDeclined(true);
    const handleViewTOSClick = () => setHasDeclined(false);
    const handleAcceptClick = async () => {
      setIsFetching(true);
      try {
        await apiCall("POST", "/auth/acceptTermsOfUse", {}, true);
        dispatch(checkIfTokenIsValid());
      } catch (e) {
        setIsFetching(false);
        setHasFailed(true);
      }
    };

    if (acceptedTOS) {
      return <Component />;
    }
    if (hasDeclined) {
      return <DeclinedContainer handleViewTOSClick={handleViewTOSClick} />;
    }
    return (
      <AcceptContainer
        handleDeclineClick={handleDeclineClick}
        handleAcceptClick={handleAcceptClick}
        isFetching={isFetching}
        hasFailed={hasFailed}
      />
    );
  };
  return RequireTOS;
};

export default requiresAcceptTOS;
