import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useEffect } from "react";
import { IAppState } from "../store/Store";
import Loading from "../components/Loading";
import { isFolder, trans } from "../generic/Helpers";
import Navbar from "../components/nav/Navbar";
import { getControlGroup } from "../actions/ControlGroupActions";
import { TControlGroupState } from "../types/ControlGroup";
import ControlGroupBase from "../components/control-group/ControlGroupBase";
import { generateUrl } from "../generic/Requests";

interface IControlGroupContainerProps extends RouteComponentProps<any> {}

// TODO: this seems to be used for presenting folders, not just control groups
const ControlGroupContainer: React.FC<IControlGroupContainerProps> = ({
  match: {
    params: { customerId, sipId, controlGroupId }
  },
  history
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const controlGroup = useSelector<IAppState, TControlGroupState>(
    (state: IAppState) => state.controlGroupState
  );

  useEffect(() => {
    dispatch(getControlGroup(controlGroupId));
  }, [dispatch, controlGroupId]);

  return (
    <section>
      <Navbar
        headerText={
          controlGroup.data
            ? controlGroup.data.node.TreeNode.Name
            : "Control Group"
        }
        backLink={
          isFolder(controlGroup.data && controlGroup.data.node)
            ? history.push(
                generateUrl(
                  customerId,
                  sipId,
                  controlGroup.data && controlGroup.data.node.TreeNode.ParentID
                )
              )
            : `/app/customer/${customerId}/sip/${sipId}`
        }
      />
      {controlGroup && controlGroup.isFetching ? (
        <Loading text={trans("global-loading")} />
      ) : (
        <ControlGroupBase controlGroup={controlGroup} />
      )}
    </section>
  );
};

export default ControlGroupContainer;
