import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./assets/styles/css/App.css";
import ErrorBoundary from "./ErrorBoundary";
import Logo from "./components/Logo";
import OfflineContainer from "./containers/OfflineContainer";
import SnackbarsContainer from "./containers/SnackbarsContainer";
import Routes from "./Routes";
import { IAuthState } from "./types/Auth";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "./store/Store";
import Loading from "./components/Loading";
import EdocLogo from "./components/EdocLogo";
import {
  getLocalStorage,
  keyExists,
  setLocalStorage,
  trans
} from "./generic/Helpers";
import { Dispatch } from "redux";
import { getTranslations } from "./actions/AuthActions";
import { TTranslationState } from "./types/Translation";
import { config } from "./generic/Config";
import * as Sentry from "@sentry/browser";

if (process.env.REACT_APP_SENTRY_CONFIG) {
  const options = JSON.parse(process.env.REACT_APP_SENTRY_CONFIG) || {};
  Sentry.init(options);
}

const App: React.FC = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();

  useEffect(() => {
    dispatch(
      getTranslations(
        keyExists("lang") ? getLocalStorage("lang") : config.DEFAULT_LANG
      )
    );
  }, [dispatch]);

  const translations: TTranslationState = useSelector<
    IAppState,
    TTranslationState
  >((state: IAppState) => state.translations);

  const auth: IAuthState = useSelector<IAppState, IAuthState>(
    (state: IAppState) => state.authState
  );

  useEffect(() => {
    const userLanguage = auth.data && auth.data.Language;
    if (userLanguage) {
      setLocalStorage("lang", userLanguage);
      dispatch(getTranslations(userLanguage));
    }
  }, [auth.data]);

  const loadingTranslations = !translations || translations.isFetching;
  return (
    <ErrorBoundary>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router basename={process.env.PUBLIC_URL}>
          <div className="page-wrapper">
            <Logo show={true} />
            <main className="content">
              <OfflineContainer>
                {loadingTranslations || (auth && auth.authCheck) ? (
                  <Loading
                    text={
                      loadingTranslations
                        ? "Loading application"
                        : trans("global-loading")
                    }
                  />
                ) : (
                  <Routes />
                )}
                <EdocLogo />
                <a
                  href={trans("help-external-link")}
                  className="icon-help"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </OfflineContainer>
              <SnackbarsContainer />
            </main>
          </div>
        </Router>
      </MuiPickersUtilsProvider>
    </ErrorBoundary>
  );
};

export default App;
