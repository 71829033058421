import * as React from "react";
import { TDocState } from "../../types/Documentation";
import { fixLinksInDocs } from "../../generic/DocLinkFixer";
import { RouteComponentProps, withRouter } from "react-router";

interface ILoadingProps extends RouteComponentProps<any> {
  doc: TDocState;
}

const Doc: React.FC<ILoadingProps> = (props): JSX.Element => {
  const { doc } = props;

  const contentClickHandler = (e: any) => {
    const targetLink = e.target.closest("a");
    if (targetLink) {
      if (targetLink.getAttribute("data-internal-link") === "true") {
        const targetLinkUrl = targetLink.getAttribute("href");
        e.preventDefault();
        props.history.push(targetLinkUrl.toString());
      }
    }

    return false;
  };

  const documentation = doc.data ? doc.data.doc : null;

  return (
    <div className="text-block">
      {doc.data && documentation && (
        <>
          <h1>{documentation.Title}</h1>
          <div
            onClick={contentClickHandler}
            dangerouslySetInnerHTML={{
              __html: fixLinksInDocs(
                documentation.Text,
                doc.data.filestoreBaseUrl
              )
            }}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(Doc);
