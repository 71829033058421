import React, { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import Slide from "@material-ui/core/Slide";

import { TSnackbar, SnackbarTypes } from "../../reducers/SnackbarsReducer";
import { trans } from "../../generic/Helpers";

interface ISnackbarViewProps {
  snackbar: TSnackbar;
  deleteSnackbar: Function;
}

const SnackbarView = ({ snackbar, deleteSnackbar }: ISnackbarViewProps) => {
  useEffect(() => {
    const timeout = setTimeout(
      () => deleteSnackbar(snackbar.id),
      snackbar.duration
    );
    return () => {
      clearTimeout(timeout);
    };
  });

  const handleIconClick = (snackbarId: string) => () =>
    deleteSnackbar(snackbarId);
  return (
    <Slide direction="left" in>
      <div className={`snackbar snackbar--${snackbar.type}`}>
        <div className="snackbar__content">
          <h4 className="snackbar__title">{trans(snackbar.title)}</h4>
          <div className="snackbar__body">{trans(snackbar.body)}</div>
        </div>
        <div className="snackbar__actions">
          <IconButton
            className={snackbar.type}
            onClick={handleIconClick(snackbar.id)}
            aria-label="close"
          >
            {snackbar.type === SnackbarTypes.SUCCESS && <CheckIcon />}
            {snackbar.type === SnackbarTypes.ERROR && <ErrorIcon />}
          </IconButton>
        </div>
      </div>
    </Slide>
  );
};

export default SnackbarView;
