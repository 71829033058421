import { Reducer } from "redux";
import {
  Customer,
  TCustomerState,
  initialCustomerState,
  TGetCustomerAction
} from "../types/Customer";
import {endReducer} from "../store/Store";

export const customerReducer: Reducer<TCustomerState, TGetCustomerAction> = (
  state = initialCustomerState,
  action
) => {
  switch (action.type) {
    case Customer.REQUEST: {
      return {
        isFetching: true
      };
    }

    case Customer.SUCCESS:
      return {
        isFetching: false,
        data: action.payload.data
      };


    case Customer.FAILED:
      // TODO: what happens to the error info?
      return state;

    default:
      return endReducer(state, action);
  }
};
