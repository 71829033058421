import * as React from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import { trans } from "../../generic/Helpers";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  getPasswordResetStatus,
} from "../../actions/AuthActions";
import Alert from "../Alert";
import Loading from "../Loading";
import {useEffect} from "react";
import ForgottenPasswordChangeForm from "../ForgottenPasswordChangeForm";
import {Link} from "react-router-dom";

interface IResetForgottenPasswordProps extends RouteComponentProps<any> {}

const ResetForgottenPassword: React.FC<IResetForgottenPasswordProps> = ({
                                                                          match: {
                                                                            params: { token }
                                                                          },
                                                                          history
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();

  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState<boolean|null>(null);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    const res = dispatch(getPasswordResetStatus(token));
    const loader = (res as any) as Promise<any>;
    loader.then((token) => {
      setIsValidToken(true);
    }, (err: any) => {
      setIsValidToken(false);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch, token]);

  const onComplete = () => {
    setIsCompleted(true);
  };

  return (
    <>
      <Navbar
        headerText={trans("page-reset-forgotten-password-title")}
        backLink={() => {
          history.goBack();
        }}
        hideMenu={true}
      />
      <section>
        {isLoading && <Loading />}

        {isValidToken === false ? (
            <>
              <Alert type={"danger"} text={trans("message-forgotten-password-token-not-valid")}/>
              <div className="text-block">
                {trans("message-forgotten-password-token-longtext")}
              </div>
            </>
        ) : isCompleted ? (
            <>
              <Alert type={"success"} text={trans("message-forgotten-password-changed")}/>
              <div className="text-block">
                {trans("message-forgotten-password-changed-longtext")}
              </div>
            <Link to={"/app"}>
                <button type="submit" className="btn btn-primary btn-small">
                    {trans("auth-login")}
                </button>
            </Link>
            </>
        ) : (
            <ForgottenPasswordChangeForm token={token}
                                         onComplete={onComplete}
                                         setIsLoading={setIsLoading}/>
        )
        }
      </section>
    </>
  );
};

export default ResetForgottenPassword;
