import {Reducer} from "redux";
import {GetTranslations, initialTranslationState, TGetTranslation, TTranslationState} from "../types/Translation";
import {setLocalStorage} from "../generic/Helpers";
import {endReducer} from "../store/Store";

export const translationReducer: Reducer<TTranslationState, TGetTranslation> = (
  state = initialTranslationState,
  action
) => {
  switch (action.type) {
    case GetTranslations.REQUEST: {
      return {
        isFetching: true
      };
    }

    case GetTranslations.SUCCESS: {
      setLocalStorage(
        "trans",
        JSON.stringify(action.payload.data.translations)
      );
      return {
        isFetching: false,
        data: action.payload.data.translations
      };
    }

    case GetTranslations.FAILED:
      return state;

    default:
      return endReducer(state, action);
  }
};
