const updateCustomFields = (
  customFields: Array<any>,
  index: number,
  value: any
) =>
  customFields.map((field: any, i: number) => ({
    ...field,
    value: index === i ? value : field.value
  }));

export default updateCustomFields;
