import { Reducer } from "redux";
import { chain, sortBy } from "lodash";
import {
  AuditActions,
  GetAuditConfig,
  GetAuditControls,
  GetAuditRecipients,
  GetAuditSites,
  GetAuditStatus,
  IAuditControlGroup,
  IAuditState,
  initialAuditState,
  SetAdditionalRecipient,
  StartAudit
} from "../types/Audit";
import { setLocalStorage } from "../generic/Helpers";

export const auditReducer: Reducer<IAuditState, AuditActions> = (
  state = initialAuditState,
  action
) => {
  switch (action.type) {
    case GetAuditStatus.SUCCESS:
      return {
        isFetching: false,
        data: {
          ...state.data,
          status: action.payload.data.audit
        }
      };

    case GetAuditSites.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GetAuditSites.SUCCESS: {
      return {
        isFetching: false,
        data: {
          ...state.data,
          sites: action.payload.data.sites
        }
      };
    }

    case GetAuditConfig.SUCCESS: {
      setLocalStorage(
        "currentAuditConfig",
        JSON.stringify(action.payload.data.configuration)
      );
      return {
        ...state,
        isFetching: false
      };
    }

    case StartAudit.SUCCESS: {
      setLocalStorage(
        "currentAudit",
        JSON.stringify(action.payload.data.audit)
      );
      return {
        isFetching: false,
        data: {
          info: action.payload.data.audit
        }
      };
    }

    case GetAuditControls.REQUEST:
      return {
        isFetching: true
      };

    case GetAuditControls.SUCCESS:
      return {
        isFetching: false,
        data: {
          ...state.data,
          controls: chain(action.payload.data.controlGroups)
            .sortBy((v: IAuditControlGroup) => {
              return v.ControlGroupNode.TreeLeft;
            })
            .map((v: IAuditControlGroup) => {
              return {
                ...v,
                ControlPointStatuses: sortBy(
                  v.ControlPointStatuses,
                  cp => cp.ControlPointNode.TreeLeft
                )
              };
            })
            .value()
        }
      };

    case GetAuditRecipients.SUCCESS:
      return {
        isFetching: false,
        data: {
          ...state.data,
          recipients: action.payload.data.recipients
        }
      };

    case SetAdditionalRecipient.SUCCESS:
      const oldValue = state.data && state.data.recipients;
      return {
        isFetching: false,
        data: {
          ...state.data,
          recipients: [...oldValue, action.payload]
        }
      };

    default:
      return state;
  }
};
