import {Reducer} from "redux";
import {
  TGetSipControlGroupsAction,
  initialSipControlGroupsState,
  SipControlGroups,
  TSipControlGroupsState
} from "../types/Sip";
import {endReducer} from "../store/Store";

export const sipControlGroupsReducer: Reducer<
  TSipControlGroupsState,
  TGetSipControlGroupsAction
> = (state = initialSipControlGroupsState, action) => {
  switch (action.type) {
    case SipControlGroups.REQUEST: {
      return {
        isFetching: true
      };
    }

    case SipControlGroups.SUCCESS:
      return {
        isFetching: false,
        data: {
          ...action.payload.data
        }
      };

    case SipControlGroups.FAILED:
      return state;

    default:
      return endReducer(state, action);
  }
};
