import { AxiosResponse } from "axios";
import { IReportActions } from "./Report";

export interface IAuth {
  ExpirationTime: string;
  Token: string;
  Email: string;
  FullName: string;
  AcceptedTermsOfUse: boolean;
  Language: string;
  loading?: boolean;
  error?: string;
  message?: string;
  checkingAuth?: boolean;
  lang?: string;
  Permissions?: IUserPermissions;
}

export interface IUserPermissions {
  canViewClientReports: boolean;
  id: number;
  name: string;
}

export interface IAuthState {
  readonly isFetching?: boolean;
  readonly authCheck?: boolean;
  readonly error?: boolean;
  readonly errorMessage?: string;
  readonly data?: IAuth;
  readonly permissions?: any;
  readonly languages?: any;
  readonly lang?: any;
  readonly reportGuid?: string;
  readonly reportProgress?: number;
  readonly reportUrl?: string;
}

export const initialAuthState: IAuthState = {
  isFetching: false,
  data: undefined
};

export enum Authenticate {
  REQUEST = "AUTH_REQUEST",
  SUCCESS = "AUTH_SUCCESS",
  FAILED = "AUTH_FAILED",
  AUTH_CHECK = "AUTH_CHECK",
  LOGOUT = "USER_LOGOUT",
  SET_LANG = "SET_USER_LANGUAGE"
}

export enum Logout {
  REQUEST = "LOGOUT_REQUEST",
  SUCCESS = "LOGOUT_SUCCESS",
  FAILED = "LOGOUT_FAILED"
}

export interface IAuthAction {
  type:
    | Authenticate.REQUEST
    | Authenticate.SUCCESS
    | Authenticate.FAILED
    | Authenticate.AUTH_CHECK
    | Authenticate.SET_LANG
    | Authenticate.LOGOUT;
  payload: AxiosResponse;
}

export enum CheckAuthPermission {
  REQUEST = "CHECK_AUTH_PERMISSION_REQUEST",
  SUCCESS = "CHECK_AUTH_PERMISSION_SUCCESS",
  FAILED = "CHECK_AUTH_PERMISSION_FAILED"
}

export interface ICheckAuthAction {
  type:
    | CheckAuthPermission.REQUEST
    | CheckAuthPermission.SUCCESS
    | CheckAuthPermission.FAILED;
  payload: AxiosResponse;
}

export enum ChangePassword {
  REQUEST = "CHANGE_PASSWORD_REQUEST",
  SUCCESS = "CHANGE_PASSWORD_SUCCESS",
  FAILED = "CHANGE_PASSWORD_FAILED"
}

export interface IChangePasswordAction {
  type: ChangePassword.REQUEST | ChangePassword.SUCCESS | ChangePassword.FAILED;
  payload: AxiosResponse;
}

export enum ResetPassword {
  REQUEST = "RESET_PASSWORD_REQUEST",
  QUERY = "RESET_PASSWORD_QUERY",
  CONFIRM = "RESET_PASSWORD_CONFIRM"
}

export interface IResetPasswordAction {
  type: ResetPassword.REQUEST | ResetPassword.QUERY | ResetPassword.CONFIRM;
  payload: AxiosResponse;
}

export type TLanguage =
  | "en_gb"
  | "da_dk"
  | "sv_se"
  | "is_is"
  | "de_de"
  | "nl_nl"
  | "et_ee";

export interface ILanguage {
  code: string;
  fullName: string;
}

export interface TApiLanguageList {
  languages: Array<ILanguage>;
}

export enum GetLanguages {
  REQUEST = "GET_LANGUAGES_REQUEST",
  SUCCESS = "GET_LANGUAGES_SUCCESS",
  FAILED = "GET_LANGUAGES_FAILED"
}

export interface IGetLanguagesAction {
  type: GetLanguages.REQUEST | GetLanguages.SUCCESS | GetLanguages.FAILED;
  payload: AxiosResponse;
}

export enum Errors {
  CLEAR_ERRORS = "CLEAR_ERRORS_SUCCESS"
}

export type AuthActions =
  | IAuthAction
  | ICheckAuthAction
  | IChangePasswordAction
  | IResetPasswordAction
  | IGetLanguagesAction
  | IReportActions;
