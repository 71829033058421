import React from "react";

import iconPencil from "../../../assets/images/icons/icon-pencil.svg";
import { trans } from "../../../generic/Helpers";
import { TApiDropdownListItem } from "../../../types/Dropdown";
import { useDropdown } from "../../../hooks/useDropdown";
import Loading from "../../Loading";
import { ListViewMode } from "../../../generic/Config";
import { findIndex } from "lodash";
import { isNumber } from "util";

const hasItemSelected = (
  items: Array<TApiDropdownListItem>,
  item: TApiDropdownListItem
) => findIndex(items, ["ID", item.ID]) > -1;

const ChooseFromList = ({
  value: selectedListItems = null,
  onChange,
  disabled = false,
  dropdownListId,
  viewMode
}: any) => {
  const { ListItems: listItems } = useDropdown(dropdownListId);
  if (!listItems) {
    return <Loading />;
  }
  const handleCheckboxChange = (listItem: TApiDropdownListItem) => () => {
    // storing checkbox values as string 'value1,value2'
    if (hasItemSelected(selectedListItems, listItem)) {
      return onChange(
        selectedListItems.filter(
          (item: TApiDropdownListItem) => item.ID !== listItem.ID
        )
      );
    }
    return onChange([...selectedListItems, listItem]);
  };

  const handleDropdownChange = (e: any) => {
    if (e.target && e.target.value) {
      onChange([listItems[e.target.value]]);
    }
  };

  const handleChange = (listItem: TApiDropdownListItem) => () =>
    onChange([listItem]);

  // TODO: think of a better solution to seperate concerns
  const value =
    selectedListItems &&
    selectedListItems.length > 0 &&
    selectedListItems[0].Text;

  const index =
    selectedListItems &&
    selectedListItems.length > 0 &&
    findIndex(listItems, ["ID", selectedListItems[0].ID]);

  return (
    <div className="form-control">
      <div className="form-control__icons">
        <img src={iconPencil} className="inp-addon" alt="" />
      </div>
      {viewMode === ListViewMode.RADIO && (
        <div className="text-block">
          {listItems.map((item: TApiDropdownListItem) => {
            return (
              <div className="input-group pb-1" key={item.ID}>
                <input
                  type="radio"
                  name="choose-from-list"
                  id={"cg-" + item.ID}
                  value={item.ID}
                  onChange={handleChange(item)}
                  required
                  disabled={disabled}
                />
                <label className="ml-1" htmlFor={"cg-" + item.ID}>
                  {item.Text}
                </label>
              </div>
            );
          })}
        </div>
      )}
      {viewMode === ListViewMode.DROPDOWN && (
        <select
          className="form-control__input mt-2 mb-2"
          required
          value={isNumber(index) ? index : -1}
          disabled={disabled}
          onChange={handleDropdownChange}
        >
          <option value="">{trans("input-select-action-from-dropdown")}</option>
          {listItems.map((item: TApiDropdownListItem, index: number) => {
            return (
              <option key={item.ID} value={index}>
                {item.Text}
              </option>
            );
          })}
        </select>
      )}
      {viewMode === ListViewMode.CHECKBOX && (
        <div className="text-block">
          {listItems.map((item: TApiDropdownListItem) => {
            return (
              <div className="input-group pb-1" key={item.ID}>
                <input
                  type="checkbox"
                  name="controlGroupId"
                  id={"cg-" + item.ID}
                  value={item.ID}
                  onChange={handleCheckboxChange(item)}
                  checked={hasItemSelected(selectedListItems, item)}
                  required={!value}
                  disabled={disabled}
                />
                <label className="ml-1" htmlFor={"cg-" + item.ID}>
                  {item.Text}
                </label>
              </div>
            );
          })}
        </div>
      )}
      <div className="form-control__actions" />
    </div>
  );
};

export default ChooseFromList;
