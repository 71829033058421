import { RouteComponentProps, withRouter } from "react-router";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { IAppState } from "../store/Store";
import { TDocState } from "../types/Documentation";
import { useEffect } from "react";
import { getDocumentation } from "../actions/ControlGroupActions";
import Navbar from "../components/nav/Navbar";
import Loading from "../components/Loading";
import Doc from "../components/pages/Doc";
import {trans} from "../generic/Helpers";

interface ISipContainerProps extends RouteComponentProps<any> {}

const DocContainer: React.FC<ISipContainerProps> = ({
  match: {
    params: { docId }
  },
  history
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const doc: TDocState = useSelector<IAppState, TDocState>(
    (state: IAppState) => state.documentation
  );

  useEffect(() => {
    dispatch(getDocumentation(docId));
  }, [dispatch, docId]);

  return (
    <section>
      <Navbar
        headerText={trans("page-documentation-title")}
        backLink={() => {
          history.goBack();
        }}
      />
      {doc && doc.isFetching ? <Loading /> : <Doc doc={doc} />}
    </section>
  );
};

export default withRouter(DocContainer);
