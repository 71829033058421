import getPerformStatus from "../../../helpers/components/forms/controlPoint/getPerformStatus";
import { useEffect } from "react";
import { ControlPointType } from "../../../generic/Config";
import {
  createReminder,
  createAdHoc
} from "../../../actions/ControlPointActions";
import { setForms } from "../../../reducers/RepeatControlGroupReducer";
import { TApiDropdownListItem } from "../../../types/Dropdown";
import moment from "moment";

type RepeatControlFormType = {
  controlPoint: string | number;
  customFields: Array<{ Name: string; value: string }>;
  performStatus: boolean;
  performTime: string;
  startTime: Date;
  endTime: Date;
  userInput: string | number;
  inputCalculationValue?: string | number;
  userNotes: string | number;
  photoMappings: Array<{ photoId: string }>;
  deviations:
    | undefined
    | Array<{ failureReason: string; remedyAction: string }>;
  createReminder: undefined | boolean;
  reminder:
    | undefined
    | {
        notes: string | number;
        title: string | number;
        startDate: string;
        endDate: string;
      };
  completed: undefined | boolean;
};

// TODO: move to common instance request helpers
export const getRequestUserInputAndCalculationValues = (
  userInput: any,
  type: ControlPointType
) => {
  switch (type) {
    case ControlPointType.NUMBER:
    case ControlPointType.INTERVAL:
      return {
        userInput,
        inputCalculationValue: parseFloat(userInput)
      };
    case ControlPointType.SELECT_FROM_LIST: {
      return {
        userInput: userInput
          .map((item: TApiDropdownListItem) => item.Text)
          .join(","),
        inputCalculationValue:
          userInput && userInput.length > 0 && userInput[0].ID
      };
    }
    case ControlPointType.DATE:
    case ControlPointType.TIMESTAMP:
    case ControlPointType.YESNO:
      return { userInput };
  }
};

export const getRepeatControlPointForm = (
  id: string | number,
  form: {
    userInput: string | number;
    userNotes: string | number;
    customFields: Array<{ Name: string; value: string }>;
    photoMappings: Array<{ photoId: string }>;
    deviations:
      | undefined
      | Array<{ failureReason: string; remedyAction: string }>;
    createReminder: undefined | boolean;
    reminder:
      | undefined
      | {
          notes: string | number;
          title: string | number;
          startDate: string;
          endDate: string;
        };
  },
  controlPoint: {
    ConditionDetails: any;
    ControlType: ControlPointType;
  }
): RepeatControlFormType => {
  const {
    ConditionDetails: conditionDetails,
    ControlType: type
  } = controlPoint;
  const { userInput } = form;
  const performStatus = getPerformStatus(type, conditionDetails, userInput);

  const startTime = new Date();
  const endTime = new Date();
  endTime.setTime(startTime.getTime() + 2 * 24 * 60 * 60 * 1000);

  return {
    ...form,
    controlPoint: id,
    performStatus,
    endTime,
    startTime,
    performTime: startTime.toISOString(),
    completed: performStatus,
    ...getRequestUserInputAndCalculationValues(
      userInput,
      controlPoint.ControlType
    )
  };
};

export const createControlPointRequestForm = (
  repeatControlPointForm: RepeatControlFormType
): {
  controlPoint: string | number;
  customFields: Array<{ title: string; value: string }>;
  performStatus: boolean;
  performTime: string;
  startTime: Date;
  endTime: Date;
  userInput: string | number;
  inputCalculationValue?: string | number;
  userNotes: string | number;
  photoMappings: Array<{ photo: string }>;
  deviations:
    | undefined
    | Array<{ failureReason: string; remedyAction: string }>;
} => ({
  controlPoint: repeatControlPointForm.controlPoint,
  customFields: repeatControlPointForm.customFields.map(
    ({ Name: title, value }: any) => ({ title, value })
  ),
  performStatus: repeatControlPointForm.performStatus,
  performTime: repeatControlPointForm.performTime,
  startTime: repeatControlPointForm.startTime,
  endTime: repeatControlPointForm.endTime,
  userInput: repeatControlPointForm.userInput,
  inputCalculationValue: repeatControlPointForm.inputCalculationValue,
  userNotes: repeatControlPointForm.userNotes,
  photoMappings: repeatControlPointForm.photoMappings.map(photo => ({
    photo: photo.photoId
  })),
  deviations: repeatControlPointForm.performStatus
    ? undefined
    : repeatControlPointForm.deviations
});

// NOTE: we aren't using custom type object as these differ
// per request and value? doesn't help much so instead
// strict types are defined
export const createReminderRequestForm = (repeatControlPointForm: {
  reminder: {
    notes: string | number;
    title: string | number;
    startDate: string;
    endDate: string;
  };
  controlPoint: string | number;
  performStatus: boolean;
}): {
  controlGroup: null;
  controlPoint: number | string;
  forcedNotesText: string | number;
  forcedTitleText: string | number;
  performStatus: boolean;
  startTime: string;
  endTime: string;
} => {
  const { reminder } = repeatControlPointForm;
  return {
    controlGroup: null,
    controlPoint: repeatControlPointForm.controlPoint,
    forcedNotesText: reminder.notes,
    forcedTitleText: reminder.title,
    performStatus: repeatControlPointForm.performStatus,
    startTime: reminder.startDate,
    endTime: reminder.endDate
  };
};

export const useSetRepeatFormsFromControlGroup = (
  controlGroupId: any,
  controlGroup: any,
  ids: any,
  dispatch: any
) => {
  useEffect(() => {
    const ctrlGrpId =
      controlGroup.data &&
      controlGroup.data.node &&
      controlGroup.data.node.TreeNode &&
      controlGroup.data.node.TreeNode.ID;
    const controlGroupInfo =
      (controlGroup && controlGroup.data && controlGroup.data.info) || {};
    const controlInfoKeys = Object.keys(controlGroupInfo);
    const isCorrectForm =
      ids.length > 0 &&
      ids.every((key: string) => controlInfoKeys.includes(`${key}`));

    if (
      `${ctrlGrpId}` === `${controlGroupId}` &&
      controlGroup &&
      controlGroup.data &&
      controlGroup.data.contents &&
      !isCorrectForm
    ) {
      const forms: { [k: string]: any } = {};
      for (const controlPoint of controlGroup.data.contents) {
        if (controlPoint.TreeNode && controlPoint.TreeNode.ID) {
          const id = controlPoint.TreeNode.ID;
          forms[id] = {
            userInput:
              controlPoint.ControlType === ControlPointType.DATE
                ? moment(new Date()).format("YYYY-MM-DD")
                : "",
            userNotes: "",
            photoMappings: "",
            customFields: controlPoint.CustomFields
          };
        }
      }
      dispatch(setForms(forms));
    }
  }, [controlGroup]);
};

export const getControlPointRequests = (ids: any, forms: any) => {
  const controlPointRequests = ids
    .filter((id: string | number) => forms[id].completed)
    .map((id: string | number) => createControlPointRequestForm(forms[id]))
    .map((form: any) => createAdHoc(form));

  const controlPointReminderRequests = ids
    .filter(
      (id: string | number) =>
        forms[id].createReminder &&
        forms[id].completed &&
        !forms[id].performStatus
    )
    .map((id: string | number) => createReminderRequestForm(forms[id]))
    .map((form: any) => createReminder(form));

  return [...controlPointRequests, ...controlPointReminderRequests];
};
