import { AxiosResponse } from "axios";

export interface TApiDropdownListItem {
  ID: number;
  Text: string;
  Ordering: number;
  CalculationValue: number|null;
}

export interface TApiDropdownEntity {
  ID: number;
  ListItems: Array<TApiDropdownListItem>;
  Name: string;
}

export interface TApiDropdown {
  dropdown: TApiDropdownEntity;
}

export enum GetDropdown {
  REQUEST = "DROPDOWN_REQUEST",
  SUCCESS = "DROPDOWN_SUCCESS",
  FAILED = "DROPDOWN_FAILED"
}

export type TGetDropdownAction = {
  type: GetDropdown.REQUEST;
} | {
  type: GetDropdown.SUCCESS;
  payload: AxiosResponse<TApiDropdown>;
} | {
  type: GetDropdown.FAILED;
  payload: AxiosResponse;
}

export type DropdownActions = TGetDropdownAction;
