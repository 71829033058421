import { Customer, CustomerList } from "../types/Customer";
import { Authenticate } from "../types/Auth";
import { GetTranslations } from "../types/Translation";
import { Sip, SipList } from "../types/Sip";
import { ControlGroup } from "../types/ControlGroup";

const failedRequests = [
  GetTranslations.FAILED,
  Authenticate.FAILED,
  Customer.FAILED,
  CustomerList.FAILED,
  Sip.FAILED,
  SipList.FAILED,
  ControlGroup.FAILED
];

enum actionTypes {
  RESET = "OFFLINE_STATUS_RESET"
}

// TODO: move to appropriate place or keep using this pattern
export const resetOffLineStatus = () => ({
  type: actionTypes.RESET
});

export type TOfflineState = {
  isOffline: boolean;
};

const initialState: TOfflineState = { isOffline: false };

const isOfflineError = (errorResponse: { status?: string }) =>
  !errorResponse ||
  errorResponse.status === "408" ||
  errorResponse.status === "ECONNABORTED";

export const offlineReducer = (state = initialState, action: any) => {
  if (failedRequests.includes(action.type)) {
    const { errorResponse = undefined } = action.payload;
    if (isOfflineError(errorResponse)) {
      return { isOffline: true };
    }
  }
  return state;
};
