import { useState } from "react";
import { map, get } from "lodash";

import { apiCall } from "../generic/Requests";
import { TApiPhotosUploadedResponse } from "../types/Photos";
import { IAppState } from "../store/Store";
import { TControlGroupState } from "../types/ControlGroup";
import { useSelector } from "react-redux";

const getImageData = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve({ filename: file.name, base64Content: reader.result });
    reader.onerror = () => reject(reader.result);
    reader.readAsDataURL(file);
  });

const convertFilesToImageData = async (files: Array<File>) => {
  return await Promise.all(files.map(async file => await getImageData(file)));
};

const saveImages = async (sip: any, uploadedImages: any) =>
  apiCall<any, TApiPhotosUploadedResponse>(
    "POST",
    "/photos",
    {
      sip,
      photos: uploadedImages
    },
    true
  );

function useImagesUpload(defaultPhotos: any = []): [
  Array<any>,
  boolean,
  boolean,
  (e: any) => void,
  (photoId: string) => () => void
] {
  const controlGroupState: TControlGroupState = useSelector<
    IAppState,
    TControlGroupState
  >((state: IAppState) => state.controlGroupState);
  const SipId = get(controlGroupState, `data.node.TreeNode.SipID`, '');
  // TODO: make proper property passing
  const [images, setImages] = useState<Array<any>>(defaultPhotos);
  const [isFetching, setIsFetching] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleUploadImages = async (e: any) => {
    e.preventDefault();
    if (!e.target || !e.target.files || e.target.files.length === 0) {
      return;
    }
    setIsFetching(true);
    setHasError(false);
    try {
      const uploadedImages: any = await convertFilesToImageData(
        Array.from(e.target.files)
      );
      const response = await saveImages(SipId, uploadedImages);
      const { photos } = response.data;
      const savedImages = map(photos, (photo, i: number) => ({
        ...photo,
        filename: uploadedImages[i].filename
      }));

      setImages([...savedImages, ...images]);
    } catch {
      setIsFetching(false);
    }
    setIsFetching(false);
  };

  const handleDeleteImage = (photoId: string) => () => {
    setImages(images.filter(image => image.photoId !== photoId));
  };

  return [images, isFetching, hasError, handleUploadImages, handleDeleteImage];
}

export default useImagesUpload;
