import { AsyncThunkAction } from "../types/Requests";
import {
  Customer,
  CustomerList,
  TGetCustomerAction,
  TGetCustomerListAction,
  TCustomerListState,
  TCustomerState,
  TApiCustomerEntity,
  TApiCustomerList
} from "../types/Customer";
import { apiCall, asyncRequest } from "../generic/Requests";

export const getCustomerList = (
  callback?: (...args: any) => any
): AsyncThunkAction<TCustomerListState, TGetCustomerListAction> => {
  return asyncRequest(
    CustomerList,
    apiCall<null, TApiCustomerList>("GET", "/", null, true),
    callback
  );
};

export const getCustomer = (
  customerId: number
): AsyncThunkAction<TCustomerState, TGetCustomerAction> => {
  return asyncRequest(
    Customer,
    apiCall<null, TApiCustomerEntity>(
      "GET",
      `/customer/${customerId}`,
      null,
      true
    ),
    null
  );
};
