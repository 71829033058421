import React, { useRef } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";

import Loading from "../Loading";
import ImageCard from "./ImageCard";
import { trans } from "../../generic/Helpers";

interface IPhotoUploader {
  photos: Array<any>;
  isFetchingPhotos: boolean;
  hasErrorPhotos: boolean;
  handleUploadPhotos: any;
  handleDeletePhoto: any;
  disabled?: boolean;
}

export default function PhotoUploader(props: IPhotoUploader) {
  const {
    photos,
    isFetchingPhotos,
    handleUploadPhotos,
    handleDeletePhoto,
    disabled
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const hasPhotos = photos.length > 0 ? true : null;
  const handleButtonClick = (e: any) => {
    e.preventDefault();
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const _handleDeletePhoto = (id: string) => () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
    handleDeletePhoto(id)();
  };

  return (
    <div className="photo-uploader">
      {isFetchingPhotos && (
        <Loading text={trans("photos-upload-loader-text")} />
      )}
      <List component="div" role="list">
        <ListItem
          aria-haspopup="true"
          role="listitem"
          className="photo__content"
        >
          {hasPhotos && !isFetchingPhotos && (
            <>
              <ListItemText primary={trans("photos-uploader-title")} />
              <div className="photo__list">
                <Grid spacing={1} container>
                  {photos.map((photo: any, i: any) => (
                    <Grid key={i} item xs={6} sm={4} md={3}>
                      <ImageCard
                        src={photo.thumbUrl}
                        handleDeleteClick={_handleDeletePhoto(photo.photoId)}
                        title={photo.filename}
                        disabled={disabled}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleButtonClick}
            disabled={disabled}
          >
            {trans("photos-upload-button-add")}
          </button>
        </ListItem>
      </List>
      <input
        ref={inputRef}
        onChange={handleUploadPhotos}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        multiple
        tabIndex={-1}
      />
    </div>
  );
}
