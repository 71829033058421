import * as React from "react";
import { trans } from "../../generic/Helpers";
import { RouteComponentProps, withRouter } from "react-router";

interface IEmptyPageProps extends RouteComponentProps<any> {
  text?: string;
  goBack?: boolean;
}

const EmptyPage: React.FC<IEmptyPageProps> = (props): JSX.Element => {
  return (
    <>
      <p className="text-block">{props.text}</p>
      {props.goBack && (
        <div className="input-group mt-3 d-flex justify--center">
          <button
            type="submit"
            className="btn btn-primary btn-small"
            onClick={props.history.goBack}
          >
            {trans("global-go-back")}
          </button>
        </div>
      )}
    </>
  );
};

export default withRouter(EmptyPage);
