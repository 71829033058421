import * as React from "react";
import {getApiResponseFieldError, trans} from "../generic/Helpers";
import InputError from "./inputError";
import {useState} from "react";
import {FormEvent} from "react";
import {confirmPasswordReset} from "../actions/AuthActions";
import {useDispatch} from "react-redux";
import {Dispatch} from "redux";

interface IForgottenPasswordChangeProps {
    token: string;
    setIsLoading: (isLoading: boolean) => void;
    onComplete: () => void;
}

const ForgottenPasswordChangeForm: React.FC<IForgottenPasswordChangeProps> = ({
    token,
    setIsLoading,
    onComplete
}): JSX.Element => {
    const dispatch = useDispatch<Dispatch<any>>();

    const [newPasswordValue, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");

    const handleSubmission = async (e: FormEvent) => {
        setIsLoading(true);
        e.preventDefault();
        const res = dispatch(confirmPasswordReset(token, newPasswordValue, confirmNewPassword));
        const loader = (res as any) as Promise<any>;
        return loader.then(() => {
            onComplete();
        }, (err: any) => {
            if (err && err.errors) {
                const errors = err.errors.children;
                setNewPasswordError(getApiResponseFieldError(errors, 'newPassword'));
                setConfirmNewPasswordError(getApiResponseFieldError(errors, 'confirmation'));
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return <>
        <div className="text-block">
            <form className="form-group form-vertical" onSubmit={handleSubmission}>
                <div className="text-block">
                    <label htmlFor="newPassword">
                        {trans("input-label-new-password")}
                    </label>
                    <div className="input-group">
                        <input
                            type="password"
                            className="inp"
                            id="newPassword"
                            value={newPasswordValue}
                            required={true}
                            minLength={4}
                            onChange={e => setNewPassword(e.target.value)}
                            autoComplete="new-password"
                        />
                    </div>
                    <InputError message={newPasswordError} />
                </div>
                <div className="text-block">
                    <label htmlFor="confirmNewPassword">
                        {trans("input-label-confirm-new-password")}
                    </label>
                    <div className="input-group">
                        <input
                            type="password"
                            className="inp"
                            id="confirmNewPassword"
                            value={confirmNewPassword}
                            required={true}
                            minLength={4}
                            onChange={e => setConfirmNewPassword(e.target.value)}
                            autoComplete="new-password"
                        />
                    </div>
                    <InputError message={confirmNewPasswordError} />
                </div>
                <div className="input-group mt-3 d-flex justify--center">
                    <button type="submit" className="btn btn-primary btn-small">
                        {trans("input-forgotten-password-change")}
                    </button>
                </div>
            </form>
        </div>
    </>;
}

export default ForgottenPasswordChangeForm;
