import * as React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { get } from "lodash";
import { trans } from "../generic/Helpers";
import control_1 from "../assets/images/icons/control_1.svg";
import control_2 from "../assets/images/icons/control_2.svg";
import control_3 from "../assets/images/icons/control_3.svg";
import control_4 from "../assets/images/icons/control_4.svg";
import control_folder from "../assets/images/icons/icon-folder.svg";
import control_point from "../assets/images/icons/icon-control-point.svg";
import { IAuthState } from "../types/Auth";
import { useSelector } from "react-redux";
import { IAppState } from "../store/Store";
import { TCustomerState } from "../types/Customer";
import useScrollToElement from "../hooks/useScrollToElement";
import ControlInstanceTime from "./shared/ControlInstanceTime";
import { TControlGroupState } from "../types/ControlGroup";

export interface IListItemControls {
  sipAdhoc?: {
    sipId: number;
    redirectUrl?: string;
  };
  controlGroupAdhoc?: {
    sipId: number;
    controlGroupId: number;
    redirectUrl?: string;
  };
  AdHocFromControlGroup?: {
    sipId: number;
    redirectUrl?: string;
  };
  AdHocFromControlPoint?: {
    controlGroupId: number;
    redirectUrl?: string;
  };
  repeatControlPoint?: {
    controlPointId: number;
    controlGroupId: number;
    redirectUrl?: string;
  };
  dismiss?: {
    controlGroupId: number;
    selectedControlPoint?: number;
    redirectUrl?: string;
  };
  repeat?: boolean;
  doc?: number;
  report?: {
    type: string;
    id: number;
  };
}

interface IListItemProps {
  title?: string | null;
  detail?: string;
  url?: string;
  docs?: boolean;
  itemType?: number | string;
  count?: number;
  settings?: boolean;
  controls?: IListItemControls | null;
  content?: any;
  id?: number;
  openByDefault?: boolean;
  closeContent?: boolean;
  event?: (...args: any) => any;
  formula?: number;
  formulaClass?: string;
  formulaCompleted?: boolean;
  disabled?: boolean;
  gps?: boolean;
}

const ListItem: React.FC<IListItemProps> = (props): JSX.Element => {
  const {
    title,
    controls,
    detail,
    url,
    formula,
    docs,
    itemType,
    count,
    settings,
    formulaCompleted,
    content = {},
    openByDefault = false,
    closeContent,
    disabled,
    formulaClass,
    gps
  } = props;

  let image;
  switch (itemType) {
    case 2:
      image = control_2;
      break;

    case 3:
      image = control_3;
      break;

    case 4:
      image = control_4;
      break;

    case "folder":
      image = control_folder;
      break;

    case "control-point":
      image = control_point;
      break;

    default:
      image = control_1;
      break;
  }

  const [open, setOpen] = useState<boolean>(false);

  const [showContent, setShowContent] = useState<boolean>(
    openByDefault ? openByDefault : false
  );

  const isMobile = useMediaQuery("only screen and (max-width: 760px)");
  const [listItemRef] = useScrollToElement(
    (open || showContent) && !openByDefault && isMobile,
    [open, showContent]
  );
  const parseUrl = (url: string | undefined) => {
    if (url) {
      return url.replace("/client", "");
    }

    return "/";
  };

  const dismissUrl =
    controls && controls.dismiss
      ? controls.dismiss.selectedControlPoint
        ? `/app/dismiss/${controls.dismiss.controlGroupId}/selected/${controls.dismiss.selectedControlPoint}`
        : `/app/dismiss/${controls.dismiss.controlGroupId}`
      : null;

  const generateTitleClass = (): string => {
    let title = "title";
    if (formula) {
      title = title + " has-number";
    }
    if (detail) {
      title = title + " has-details";
    }

    return title;
  };

  const auth: IAuthState = useSelector<IAppState, IAuthState>(
    (state: IAppState) => state.authState
  );

  const controlGroupState: TControlGroupState = useSelector<
    IAppState,
    TControlGroupState
  >((state: IAppState) => state.controlGroupState);

  const { props: { instance } = { instance: {} } } = content;
  const TrafficLights = get(controlGroupState, "data.node.TrafficLights");
  const dueStartTime = instance.DueStartTime;
  const dueEndTime = instance.DueEndTime;

  const canSeeReports =
    auth && auth.permissions && auth.permissions.canViewClientReports;

  const customerState: TCustomerState = useSelector<IAppState, TCustomerState>(
    (state: IAppState) => state.customerState
  );

  const customerId = customerState.data ? customerState.data.TreeNode.ID : null;

  return (
    <>
      <div
        ref={listItemRef}
        className={
          formulaCompleted
            ? "item item-completed"
            : disabled
            ? "item item-disabled"
            : "item"
        }
      >
        <div className="item-top-row">
          <div className="disabled-item-overlay" />
          {formula && (
            <div
              className={
                formulaClass ? formulaClass + " item-number" : "item-number"
              }
            >
              <span>{trans("formula-step")}</span>
              <span>{formula}</span>
            </div>
          )}
          {url ? (
            <Link to={url} className={generateTitleClass()}>
              {title}
            </Link>
          ) : content ? (
            <div
              onClick={() => setShowContent(!showContent)}
              className={generateTitleClass()}
            >
              {title}
            </div>
          ) : (
            <div className={generateTitleClass()}>{title}</div>
          )}
          {dueEndTime && (
            <ControlInstanceTime
              dueStartTime={dueStartTime}
              dueEndTime={dueEndTime}
              TrafficLights={TrafficLights}
            />
          )}
          {!dueEndTime && <div className="count">{count || ""}</div>}
          <div className="info">{docs && <span>!</span>}</div>
          {typeof itemType !== "undefined" && (
            <div className="icon">
              <img src={image} alt="control type" />
            </div>
          )}
          {typeof gps !== "undefined" && (
            <div className="icon-gps">
              {gps && <i className="fas fa-globe-americas" />}
            </div>
          )}
          <div className="dropdown">
            {settings && !formulaCompleted && (
              <i className="fas fa-ellipsis-v" onClick={() => setOpen(!open)} />
            )}
          </div>
        </div>
        {detail && (
          <div className={formula ? "item-details has-number" : "item-details"}>
            <small>{detail}</small>
          </div>
        )}
      </div>
      {(open || showContent) && !closeContent && (
        <div className="item-slidedown">
          {open && (
            <>
              {controls && (
                <div className="list-item-controls">
                  {controls.sipAdhoc && (
                    <Link
                      to={{
                        pathname: `/app/customer/${customerId}/sip/${controls.sipAdhoc.sipId}/ad-hoc`,
                        state: {
                          redirectUrl: parseUrl(controls.sipAdhoc.redirectUrl)
                        }
                      }}
                    >
                      <i className="fas fa-plus-circle" />
                      <span>{trans("config-adhoc")}</span>
                    </Link>
                  )}
                  {controls.controlGroupAdhoc && (
                    <Link
                      to={{
                        pathname: `/app/customer/${customerId}/sip/${controls.controlGroupAdhoc.sipId}/control-group/${controls.controlGroupAdhoc.controlGroupId}/ad-hoc`,
                        state: {
                          redirectUrl: parseUrl(
                            controls.controlGroupAdhoc.redirectUrl
                          )
                        }
                      }}
                    >
                      <i className="fas fa-plus-circle" />
                      <span>{trans("config-adhoc")}</span>
                    </Link>
                  )}
                  {controls.AdHocFromControlGroup && (
                    <Link
                      to={{
                        pathname: `${parseUrl(
                          window.location.pathname
                        )}/ad-hoc-from-control-group/${
                          controls.AdHocFromControlGroup.sipId
                        }`,
                        state: {
                          redirectUrl: parseUrl(
                            controls.AdHocFromControlGroup.redirectUrl
                          )
                        }
                      }}
                    >
                      <i className="fas fa-plus-circle" />
                      <span>{trans("config-adhoc-from-control-group")}</span>
                    </Link>
                  )}
                  {controls.AdHocFromControlPoint && (
                    <Link
                      to={{
                        pathname: `${parseUrl(
                          window.location.pathname
                        )}/ad-hoc-from-control-point/${
                          controls.AdHocFromControlPoint.controlGroupId
                        }`,
                        state: {
                          redirectUrl: parseUrl(
                            controls.AdHocFromControlPoint.redirectUrl
                          )
                        }
                      }}
                    >
                      <i className="fas fa-plus-circle" />
                      <span>{trans("config-adhoc-from-control-point")}</span>
                    </Link>
                  )}
                  {controls.repeatControlPoint && (
                    <Link
                      to={{
                        pathname: `${parseUrl(
                          window.location.pathname
                        )}/repeat-control-point/${
                          controls.repeatControlPoint.controlPointId
                        }`,
                        state: {
                          redirectUrl: parseUrl(
                            controls.repeatControlPoint.redirectUrl
                          )
                        }
                      }}
                    >
                      <i className="fas fa-redo" />
                      <span>{trans("config-repeat-control-point")}</span>
                    </Link>
                  )}
                  {controls.dismiss && (
                    <Link
                      to={{
                        pathname: `${dismissUrl}`,
                        state: {
                          redirectUrl: parseUrl(controls.dismiss.redirectUrl)
                        }
                      }}
                    >
                      <i className="far fa-times-circle" />
                      <span>{trans("config-dismiss")}</span>
                    </Link>
                  )}
                  {controls.repeat && (
                    <Link to={`${url}/repeat`}>
                      <i className="fas fa-redo" />
                      <span>{trans("config-repeat-checkpoint")}</span>
                    </Link>
                  )}
                  {controls.doc && (
                    <Link to={`/app/doc/${controls.doc}`}>
                      <i className="fas fa-info-circle" />
                      <span>{trans("config-docs")}</span>
                    </Link>
                  )}
                  {canSeeReports && controls.report && (
                    <Link
                      to={`/app/report/${controls.report.type}/${controls.report.id}`}
                    >
                      <i className="fas fa-align-justify" />
                      <span>{trans("config-report")}</span>
                    </Link>
                  )}
                </div>
              )}
            </>
          )}
          {content && showContent && content}
        </div>
      )}
    </>
  );
};

export default ListItem;
