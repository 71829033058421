import * as React from "react";
import error_face from "./assets/images/error_face.svg";
import { trans } from "./generic/Helpers";
import * as Sentry from "@sentry/browser";

interface IErrorBoundaryState {
  hasError: boolean;
  eventId: string | null;
}

class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
      eventId: null
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (Sentry.getCurrentHub().getClient()) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="error-container">
            <h1>{trans("global-error")}</h1>
            <div className="error-text">
              <img src={error_face} alt="error" />
              <p>{trans("error-page-something-went-wrong")}</p>
              <p>{trans("error-page-sorry-about-that")}</p>
            </div>
            <div className="error-button">
              <a
                href={`${process.env.PUBLIC_URL}/app`}
                className="btn btn-login"
              >
                {trans("global-ok")}
              </a>
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
