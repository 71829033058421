import * as React from "react";
import { RouteComponentProps } from "react-router";
import Navbar from "../nav/Navbar";
import { getLocalStorage, keyExists, trans } from "../../generic/Helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  getLanguages,
  getTranslations,
  setLanguage
} from "../../actions/AuthActions";
import { IAuthState, ILanguage } from "../../types/Auth";
import { IAppState } from "../../store/Store";

interface ISettingsProps extends RouteComponentProps<any> {}

const Settings: React.FC<ISettingsProps> = (props): JSX.Element => {
  const auth: IAuthState = useSelector<IAppState, IAuthState>(
    (state: IAppState) => state.authState
  );
  const dispatch = useDispatch<Dispatch<any>>();

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  const changeLanguage = (languageCode: string) => {
    dispatch(setLanguage(languageCode));
    dispatch(getTranslations(languageCode));
  };

  const languagesList = [
    { code: "en_gb", fullName: "English (EN_GB)" },
    { code: "da_dk", fullName: "Dansk (DA-DK)" },
    { code: "sv_se", fullName: "Svenska (SE-SWE)" },
    { code: "is_is", fullName: "Icelandic (IS-IS)" },
    { code: "de_de", fullName: "German (DE_DE)" },
    { code: "nl_nl", fullName: "Nederlands (NL-NL)" },
    { code: "et_ee", fullName: "Estonian (ET_EE)" }
  ];

  return (
    <>
      <Navbar
        headerText={trans("page-settings-title")}
        backLink={() => {
          props.history.goBack();
        }}
      />
      <section>
        <div className="text-block">
          <>
            {languagesList.map((lang: ILanguage) => {
              const isActive =
                (auth.lang && auth.lang === lang.code) ||
                (!keyExists("lang") && lang.code === "en_gb") ||
                (keyExists("lang") && getLocalStorage("lang") === lang.code);
              return (
                <div
                  className={isActive ? "lang-block active" : "lang-block"}
                  key={lang.code}
                >
                  <div className="lang-icon">
                    <i
                      className="fa fa-flag"
                      style={{ color: isActive ? "#4091cf" : "#7dcfed" }}
                    />
                  </div>
                  <button
                    onClick={() => changeLanguage(lang.code)}
                    className="btn btn-secondary text-left"
                  >
                    {lang.fullName}
                  </button>
                </div>
              );
            })}
          </>
        </div>
      </section>
    </>
  );
};

export default Settings;
