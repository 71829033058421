import * as React from "react";
import { trans } from "../../generic/Helpers";
import Navbar from "../nav/Navbar";
import { RouteComponentProps, withRouter } from "react-router";

interface IAboutProps extends RouteComponentProps<any> {}

const About: React.FC<IAboutProps> = (props): JSX.Element => {
  return (
    <>
      <Navbar
        headerText={trans("page-about-title")}
        backLink={() => {
          props.history.goBack();
        }}
      />
      <section className="about">
        <div className="text-block">
          <p>{trans("page-about-text")}</p>
        </div>
      </section>
    </>
  );
};

export default withRouter(About);
