function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}

// reference: https://en.wikipedia.org/wiki/Great-circle_distance
// returns distance in meters
export function getDistanceBetweenLatLon(lat1: number, lon1: number, lat2: number, lon2: number): number {
  // Radius of the earth in m
  const R = 6371000;

  // central angle between two points
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
  ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in m
  return R * c;
}
