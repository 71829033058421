import * as React from "react";
import { RouteComponentProps } from "react-router";
import { getLocalStorage, trans } from "../../generic/Helpers";
import Navbar from "../nav/Navbar";
import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendAuditNotes } from "../../actions/AuditActions";
import { generateUrl } from "../../generic/Requests";
import { useEffect } from "react";
import { getDropdown } from "../../actions/ControlGroupActions";
import { getSip } from "../../actions/SipActions";
import { IAppState } from "../../store/Store";
import { TApiSipDirectContent, TSipState } from "../../types/Sip";
import { useRequest } from "../../hooks/useRequest";
import Alert from "../Alert";
import { clearErrors } from "../../actions/AuthActions";

interface INotesProps extends RouteComponentProps<any> {}

const Notes: React.FC<INotesProps> = ({
  match: {
    params: { customerId, sipId, auditId }
  },
  history
}): JSX.Element => {
  const [notes, setNotes] = useState<any>([]);
  const dispatch = useDispatch();
  const auditConfig = JSON.parse(getLocalStorage("currentAuditConfig"));
  const { app } = useRequest();

  const sip = useSelector<IAppState, TSipState>(
    (state: IAppState) => state.sipState
  );

  const handleForm = (e: FormEvent) => {
    e.preventDefault();
    const auditData = {
      audit: auditId,
      notes
    };
    dispatch(clearErrors());
    dispatch(
      sendAuditNotes(auditData, () => {
        return history.push(
          generateUrl(customerId, sipId, undefined, `audit/${auditId}/results`)
        );
      })
    );
  };

  useEffect(() => {
    dispatch(getDropdown(auditConfig.AnswersListID));
    dispatch(getSip(sipId));
  }, [dispatch]);

  const handleNotesChange = (id: number, value: string, index: number) => {
    const data = {
      treeNode: id,
      note: value
    };
    const array = [...notes];
    array[index] = data;
    setNotes(array);
  };

  return (
    <>
      <Navbar headerText={trans("audit-notes-page-title")} />
      {app.hasError() && (
        <Alert type={"danger"} text={app.getError()} error={true} />
      )}
      {auditConfig.SeparateNotesForFolders ? (
        <form className="text-block" onSubmit={handleForm}>
          {sip.data &&
            sip.data.contents.map(
              (content: TApiSipDirectContent, index: number) => {
                return (
                  <div key={index} className="mb-2">
                    <label htmlFor="notes" className="text-center">
                      {content.TreeNode.Name}
                    </label>
                    <div className="input-group">
                      <textarea
                        className="textarea mt-1"
                        id="notes"
                        name="info"
                        placeholder={trans("input-notes")}
                        onChange={e =>
                          handleNotesChange(
                            content.TreeNode.ID,
                            e.target.value,
                            index
                          )
                        }
                      />
                    </div>
                  </div>
                );
              }
            )}
          <button type="submit" className="btn btn-primary btn-login mt-3">
            {trans("input-save")}
          </button>
        </form>
      ) : (
        <form onSubmit={handleForm}>
          <div className="text-block">
            <label htmlFor="notes" className="text-center">
              {trans("general-audit-notes")}
            </label>
            <div className="input-group">
              <textarea
                className="textarea mt-1"
                id="notes"
                name="info"
                placeholder={trans("input-notes")}
                onChange={e => handleNotesChange(sipId, e.target.value, 0)}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-login mt-3">
            {trans("input-save")}
          </button>
        </form>
      )}
    </>
  );
};

export default Notes;
