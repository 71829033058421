import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TSipState } from "../../../types/Sip";
import { trans } from "../../../generic/Helpers";
import { Link } from "react-router-dom";

interface IAuditProps extends RouteComponentProps<any> {
  sip: TSipState;
}

const AuditSip: React.FC<IAuditProps> = ({
  match: {
    params: { customerId, sipId }
  }
}): JSX.Element => {
  return (
    <>
      <div className="text-block">{trans("audit-start-message")}</div>
      <div className="input-group mt-3 d-flex justify--center">
        <Link
          to={`/app/customer/${customerId}/sip/${sipId}/audit`}
          className="btn btn-small btn-primary"
        >
          {trans("global-ok")}
        </Link>
      </div>
    </>
  );
};

export default withRouter(AuditSip);
