import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useEffect } from "react";
import { getControlGroup } from "../../actions/ControlGroupActions";
import Navbar from "../nav/Navbar";
import { IAppState } from "../../store/Store";
import { TControlGroupState } from "../../types/ControlGroup";
import ControlPointBase from "../control-point/ControlPointBase";
import ListItem from "../ListItem";
import {
  TControlPointInstance,
  TControlPointTypeBase,
  TOpenInstance
} from "../../types/ControlPoint";
import {
  generateControlPointInstance,
  getControlPointInfo,
  trans,
  getDocumentation
} from "../../generic/Helpers";
import { find } from "lodash";
import Loading from "../Loading";
import { useAdHocPerformTime } from "../../hooks/useAdHocPerformTime";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";

interface IRepeatControlPoint extends RouteComponentProps<any> {}

const RepeatControlPoint: React.FC<IRepeatControlPoint> = ({
  match: {
    params: { controlGroupId, controlPointId }
  },
  location,
  history
}): JSX.Element => {
  const [maxDate] = useAdHocPerformTime();
  const [startDate, setStartDate] = useState<any>(
    location.state.startDate ? location.state.startDate : new Date()
  );
  const [dateSelected, setDateSelected] = useState<any>(!!location.state.startDate);
  const dispatch = useDispatch<Dispatch<any>>();
  const controlGroup: TControlGroupState = useSelector<
    IAppState,
    TControlGroupState
  >((state: IAppState) => state.controlGroupState);

  useEffect(() => {
    dispatch(getControlGroup(controlGroupId));
  }, [dispatch, controlGroupId]);

  if (controlGroup.data) {
    const pointId = parseInt(controlPointId);
    let controlPoint: any = find(
      controlGroup.data.openInstances,
      (o: TOpenInstance) => {
        return o.ControlID === pointId;
      }
    );
    if (!controlPoint) {
      const point = find(
        controlGroup.data.contents,
        p => p.TreeNode.ID === pointId
      );
      controlPoint = {
        ControlID: pointId,
        ConditionDetails: point!.ConditionDetails
      };
    }
    const controlPointInfo = getControlPointInfo(
      controlGroup.data && controlGroup.data.info,
      parseInt(controlPointId)
    );
    const instance: TControlPointInstance<
      TControlPointTypeBase
    > = generateControlPointInstance(controlGroup, controlPoint);

    const doc = getDocumentation(
      (instance && instance.TreeNode && instance.TreeNode.ID) || -1,
      controlGroup.data ? controlGroup.data.documentation : {}
    );

    const handleDateSelected = () => {
      setDateSelected(!!startDate);
    };

    return (
      <>
        <Navbar
          headerText={trans("config-repeat-control-point")}
          backLink={() => {
            if (location.state.startDate) {
              return history.goBack();
            }
            return history.push(location.state.redirectUrl);
          }}
        />
        {!dateSelected && (
          <div className="text-block mt-3">
            <p className="pb-3">
              {trans("repeat-control-point-datepicker-info-text")}
            </p>
            <label htmlFor="start">{trans("input-select-date")}</label>
            <div className="input-group mb-3">
              <ReactDatePicker
                selected={startDate}
                onChange={e => setStartDate(e)}
                showTimeSelect={true}
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="time"
                maxDate={maxDate}
                className="inp"
                placeholderText={trans("input-select-date")}
              />
            </div>
            <div className="input-group mt-3 d-flex justify--center">
              <button
                onClick={handleDateSelected}
                disabled={!startDate}
                className="btn btn-small btn-primary"
              >
                {trans("global-ok")}
              </button>
            </div>
          </div>
        )}

        {dateSelected && (
          <div className="item-list">
            <ListItem
              key={controlPoint.ControlID}
              title={controlPointInfo.TreeNode.Name}
              itemType={"control-point"}
              id={controlPoint.ControlID}
              settings={!!doc}
              controls={{ doc }}
              docs={!!doc}
              content={
                <ControlPointBase
                  instance={instance}
                  performAdHoc={true}
                  params={{
                    redirectUrl: location.state.redirectUrl,
                    startDate: startDate
                  }}
                />
              }
              openByDefault={true}
            />
          </div>
        )}
      </>
    );
  }

  return <Loading text={trans("global-loading")} />;
};

export default RepeatControlPoint;
