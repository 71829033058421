import { RouteComponentProps, withRouter } from "react-router";
import { TControlGroupState } from "../../../types/ControlGroup";
import * as React from "react";
import { sortBy } from "lodash";
import { TOpenInstance } from "../../../types/ControlPoint";
import {
  hasOpenInstance,
  hasUnscheduledControl
} from "../../../generic/Helpers";
import OpenInstance from "./types/OpenInstance";
import UnscheduledControl from "./types/UnscheduledControl";
import StandaloneReminderInstance from "./types/StandaloneReminderInstance";

interface IRegularProps extends RouteComponentProps<any> {
  controlGroup: TControlGroupState;
}

const Regular: React.FC<IRegularProps> = ({ controlGroup }) => {
  if (!controlGroup.data) {
    return <></>;
  }

  const instanceRenders: Array<React.ReactElement> = [];

  // reminders without associated control points go first
  // ordered by start time ascending
  const openInstances = Object.values(controlGroup.data.openInstances);
  const standaloneInstances = openInstances.filter((inst: TOpenInstance) => {
    return !inst.ControlID;
  });
  sortBy(standaloneInstances, inst => inst.DueStartTime);

  standaloneInstances.forEach(inst => {
    instanceRenders.push(
      <StandaloneReminderInstance key={inst.ID} instance={inst} />
    );
  });

  // instances for control points go next
  // ordered by control point tree order
  controlGroup.data.contents.forEach(controlPoint => {
    if (hasOpenInstance(controlGroup, controlPoint)) {
      instanceRenders.push(
        <OpenInstance
          key={controlPoint.TreeNode.ID}
          controlGroup={controlGroup}
          controlPoint={controlPoint}
        />
      );
    } else if (hasUnscheduledControl(controlGroup, controlPoint)) {
      instanceRenders.push(
        <UnscheduledControl
          key={controlPoint.TreeNode.ID}
          controlGroup={controlGroup}
          controlPoint={controlPoint}
        />
      );
    }
  });

  return (
    <div className="item-list">
      <>{instanceRenders}</>
    </div>
  );
};

export default withRouter(Regular);
