import React from "react";
import Slide from "@material-ui/core/Slide";

import { trans } from "../../../generic/Helpers";
import Loading from "../../Loading";

// Accept Terms and Conditions
// To continue working with the system please Accept Terms and Conditions
// You can read our Terms and Conditions at
// http://www.dokon.dk/terms

interface IAcceptContainerProps {
  handleDeclineClick: any;
  handleAcceptClick: any;
  isFetching: boolean;
  hasFailed: boolean;
}

function AcceptContainer(props: IAcceptContainerProps) {
  const {
    handleAcceptClick,
    handleDeclineClick,
    isFetching,
    hasFailed
  } = props;
  return (
    <Slide direction="down" in={true}>
      <div className="accept-tos">
        <h1 className="accept-tos__title">
          {trans("accept-tos-accept-title")}
        </h1>
        <div className="accept-tos__content">
          <div className="accept-tos__body-text">
            {trans("accept-tos-accept-body-text")}
          </div>
          <div className="accept-tos__view-more">
            <span className="accept-tos__link-info">
              {trans("accept-tos-accept-link-info")}
            </span>
            <a
              className="accept-tos__link"
              href={trans("accept-tos-accept-link-url")}
              target="_blank"
              rel="noopener"
            >
              {trans("accept-tos-accept-link-title")}
            </a>
          </div>
          {isFetching && (
            <div className="accept-tos__loader">
              <Loading text={trans("accept-tos-loading")} />
            </div>
          )}
          {hasFailed && (
            <h4 className="accept-tos__failed">
              {trans("accept-tos-accept-failed")}
            </h4>
          )}
        </div>
        <div className="accept-tos__actions">
          <button
            onClick={handleDeclineClick}
            className="btn btn-primary accept-tos__decline-button"
          >
            {trans("accept-tos-decline-button")}
          </button>
          <button
            onClick={handleAcceptClick}
            className="btn btn-primary accept-tos__accept-button"
          >
            {trans("accept-tos-accept-button")}
          </button>
        </div>
      </div>
    </Slide>
  );
}

export default AcceptContainer;
