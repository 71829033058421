import { Reducer } from "redux";
import { mapKeys, sortBy } from "lodash";
import {
  ControlGroup,
  ControlGroupActions,
  initialControlGroupState,
  TControlGroupState
} from "../types/ControlGroup";
import { ControlGroupType } from "../generic/Config";
import { DropdownActions, GetDropdown } from "../types/Dropdown";
import { TOpenInstance } from "../types/ControlPoint";
import { endReducer } from "../store/Store";

export const controlGroupReducer: Reducer<
  TControlGroupState,
  ControlGroupActions | DropdownActions
> = (state = initialControlGroupState, action) => {
  switch (action.type) {
    case ControlGroup.REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case ControlGroup.SUCCESS:
      const isWorkflow =
        action.payload.data.node.CheckpointType === ControlGroupType.WORKFLOW;
      const openInstances =
        isWorkflow && action.payload.data.openInstances.length
          ? [action.payload.data.openInstances[0]]
          : action.payload.data.openInstances;
      return {
        ...state,
        isFetching: false,
        data: {
          ...action.payload.data,
          contents: sortBy(action.payload.data.contents, v =>
            action.payload.data.node.CheckpointType ===
            ControlGroupType.WORKFLOW
              ? v.WorkflowStep
              : v.TreeNode.TreeLeft
          ),
          info: mapKeys(action.payload.data.contents, value => {
            return value.TreeNode.ID;
          }),
          openInstances: indexOpenInstances(action.payload.data.openInstances),
          unscheduledControls: mapKeys(
            action.payload.data.unscheduledControls,
            value => {
              return value;
            }
          ),
          reminders: openInstances
            .filter((instance: any) => {
              return instance.IsReminder;
            })
            .map((instance: any) => {
              return instance.ControlID;
            })
        }
      };

    case ControlGroup.FAILED:
      return state;

    case GetDropdown.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GetDropdown.SUCCESS:
      return {
        ...state,
        isFetching: false,
        dropdown: {
          ...state.dropdown,
          [action.payload.data.dropdown.ID]: action.payload.data.dropdown
        }
      };

    case GetDropdown.FAILED:
      return state;

    default:
      return endReducer(state, action);
  }
};

function indexOpenInstances(
  this: void,
  instances: Array<TOpenInstance>
): Record<number, TOpenInstance> {
  let standaloneIndex = -1;
  return instances.reduce(
    (indexed: Record<number, TOpenInstance>, value: TOpenInstance) => {
      const key = value.ControlID;
      if (key) {
        const oldValue = indexed[key];
        if (!oldValue || value.IsReminder) {
          indexed[key] = value;
        }
      } else {
        indexed[standaloneIndex] = value;
        --standaloneIndex;
      }
      return indexed;
    },
    {}
  );
}
