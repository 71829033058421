import React from "react";
import iconPencil from "../../../assets/images/icons/icon-pencil.svg";
import MaterialDatePicker from "../../shared/MaterialDatePicker";

const DateInput = ({
  value,
  onChange,
  disabled
}: {
  value: any;
  onChange: any;
  disabled: boolean;
}) => (
  <div className="form-control date-picker">
    <div className="form-control__icons">
      <img src={iconPencil} className="inp-addon" alt="" />
    </div>
    <MaterialDatePicker
      disabled={disabled}
      selectedDate={value}
      handleDateChange={onChange}
    />
    <div className="form-control__actions" />
  </div>
);

export default DateInput;
