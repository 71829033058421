import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useEffect } from "react";
import { getCustomer } from "../actions/CustomerActions";
import { getSipList } from "../actions/SipActions";
import { IAppState } from "../store/Store";
import { TSipListState } from "../types/Sip";
import Loading from "../components/Loading";
import { trans } from "../generic/Helpers";
import SipList from "../components/pages/SipList";
import Navbar from "../components/nav/Navbar";
import { IAuthState } from "../types/Auth";

interface ISipListContainerProps extends RouteComponentProps<any> {}

const SipListContainer: React.FC<ISipListContainerProps> = ({
  match: {
    params: { customerId }
  },
  history
}): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const sips = useSelector<IAppState, TSipListState>(
    (state: IAppState) => state.sipListState
  );
  const auth: IAuthState = useSelector<IAppState, IAuthState>(
    (state: IAppState) => state.authState
  );

  useEffect(() => {
    dispatch(getCustomer(customerId));
    dispatch(getSipList(customerId));
  }, [dispatch, customerId]);

  return (
    <section>
      <Navbar
        headerText={trans("page-sip-list-title")}
        backLink={() => {
          if (auth.permissions && auth.permissions.canSelectCustomer) {
            history.push("/app");
          }
        }}
        hideBackLink={!auth.permissions || !auth.permissions.canSelectCustomer}
      />
      {sips && sips.isFetching ? (
        <Loading text={trans("global-loading")} />
      ) : (
        <SipList sips={sips} />
      )}
    </section>
  );
};

export default SipListContainer;
