const REPEAT_CONTROL_GROUP_SET_FORM = "REPEAT_CONTROL_GROUP_SET_FORM";
const REPEAT_CONTROL_GROUP_SET_FORMS = "REPEAT_CONTROL_GROUP_SET_FORMS";
const REPEAT_CONTROL_GROUP_SET_FORM_FIELDS =
  "REPEAT_CONTROL_GROUP_SET_FORM_FIELDS";
const REPEAT_CONTROL_GROUP_CLEAR_FORMS = "REPEAT_CONTROL_GROUP_CLEAR_FORMS";
const REPEAT_CONTROL_GROUP_SET_START_DATE =
  "REPEAT_CONTROL_GROUP_SET_START_DATE";

export const setForm = (id, form) => ({
  type: REPEAT_CONTROL_GROUP_SET_FORM,
  payload: { id: `${id}`, form }
});

export const setForms = forms => ({
  type: REPEAT_CONTROL_GROUP_SET_FORMS,
  payload: { forms }
});

export const setFormFields = (id, fields) => ({
  type: REPEAT_CONTROL_GROUP_SET_FORM_FIELDS,
  payload: { id: `${id}`, fields }
});

export const clearForms = () => ({
  type: REPEAT_CONTROL_GROUP_CLEAR_FORMS
});

export const setStartDate = date => ({
  type: REPEAT_CONTROL_GROUP_SET_START_DATE,
  payload: { startDate: date }
});

const initialState = {
  forms: {},
  ids: [],
  startDate: null
};

// TODO: convert to TS
export const repeatControlGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPEAT_CONTROL_GROUP_SET_START_DATE: {
      return {
        ...state,
        startDate: action.payload.startDate
      };
    }
    case REPEAT_CONTROL_GROUP_SET_FORM: {
      const { id, form } = action.payload;
      const performData = {
        performTime: state.startDate,
        startTime: state.startDate,
        endTime: state.startDate
      };
      return {
        ...state,
        ids: !state.ids.includes(id) ? [...state.ids, id] : state.ids,
        forms: {
          ...state.forms,
          [id]: {
            ...form,
            ...performData
          }
        }
      };
    }
    case REPEAT_CONTROL_GROUP_SET_FORM_FIELDS: {
      const { id, fields } = action.payload;
      return {
        ...state,
        ids: !state.ids.includes(id) ? [...state.ids, id] : state.ids,
        forms: { ...state.forms, [id]: { ...state.forms[id], ...fields } }
      };
    }
    case REPEAT_CONTROL_GROUP_CLEAR_FORMS: {
      return initialState;
    }
    case REPEAT_CONTROL_GROUP_SET_FORMS: {
      return {
        ...state,
        ids: Object.keys(action.payload.forms),
        forms: action.payload.forms
      };
    }
    default:
      return state;
  }
};
