import {AxiosResponse} from 'axios';
import {TApiTreeNodeEntityIsCustomer, TNodeTypeIdentifier} from "./Tree";
import {TLanguage} from "./Auth";

export interface TApiCustomerEntity {
  DefaultLanguage: TLanguage;
  EnableMobileLogging: boolean;
  Languages: Array<TLanguage>;
  LogoImage: string | null;
  NodeTypeIdentifier: TNodeTypeIdentifier.CUSTOMER;
  TreeNode: TApiTreeNodeEntityIsCustomer;
}

export interface TCustomerState {
  readonly isFetching?: boolean;
  readonly data?: TApiCustomerEntity;
}

export const initialCustomerState: TCustomerState = {
  isFetching: false,
  data: undefined
};

export enum Customer {
  REQUEST = "CUSTOMER_REQUEST",
  SUCCESS = "CUSTOMER_SUCCESS",
  FAILED = "CUSTOMER_FAILED"
}

export type TGetCustomerAction = {
  type: Customer.REQUEST;
} | {
  type: Customer.SUCCESS;
  payload: AxiosResponse<TApiCustomerEntity>;
} | {
  type: Customer.FAILED;
  payload: AxiosResponse;
}

export interface TApiCustomerList {
  customers: Array<TApiCustomerEntity>;
  uploadBaseUrl: string;
}

export interface TCustomerListState {
  readonly isFetching?: boolean;
  readonly data?: TApiCustomerList;
}

export const initialCustomerListState: TCustomerListState = {
  isFetching: false,
  data: undefined
};

export enum CustomerList {
  REQUEST = "CUSTOMER_LIST_REQUEST",
  SUCCESS = "CUSTOMER_LIST_SUCCESS",
  FAILED = "CUSTOMER_LIST_FAILED"
}

export type TGetCustomerListAction = {
  type: CustomerList.REQUEST;
} | {
  type: CustomerList.SUCCESS;
  payload: AxiosResponse<TApiCustomerList>;
} | {
  type: CustomerList.FAILED;
  payload: AxiosResponse<any>;
}

export type CustomerActions = TGetCustomerAction | TGetCustomerListAction;
