import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { FormEvent, useEffect } from "react";
import Navbar from "../nav/Navbar";
import { TApiControlGroupEntity } from "../../types/ControlGroup";
import { IAppState } from "../../store/Store";
import Loading from "../Loading";
import { trans } from "../../generic/Helpers";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { TSipControlGroupsState } from "../../types/Sip";
import { getSipControlGroups } from "../../actions/SipActions";
import EmptyPage from "../pages/EmptyPage";
import { generateUrl } from "../../generic/Requests";
import { ControlGroupType } from "../../generic/Config";
import {useAdHocPerformTime} from "../../hooks/useAdHocPerformTime";

interface IAdHocFromControlGroup extends RouteComponentProps<any> {}

const AdHocFromControlGroup: React.FC<IAdHocFromControlGroup> = ({
  match: {
    params: { customerId, sipId }
  },
  location,
  history
}): JSX.Element => {
  const [maxDate] = useAdHocPerformTime();
  const [startDate, setStartDate] = useState<any>(new Date());
  const dispatch = useDispatch<Dispatch<any>>();
  const [selectedControlGroup, setSelectedControlGroup] = useState("");
  const sipGroups = useSelector<IAppState, TSipControlGroupsState>(
    (state: IAppState) => state.sipControlGroupsState
  );

  let redirectUrl: string = generateUrl(customerId);
  if (location.state && location.state.redirectUrl) {
    redirectUrl = location.state.redirectUrl;
  }

  useEffect(() => {
    dispatch(getSipControlGroups(sipId));
  }, [dispatch, sipId]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    history.push({
      pathname: generateUrl(customerId, sipId, +selectedControlGroup, "ad-hoc"),
      state: {
        redirectUrl,
        startDate
      }
    });
  };

  const eligibleGroups: Array<TApiControlGroupEntity> = [];

  let loaded = false;
  if (sipGroups && sipGroups.data && sipGroups.data.controlGroups) {
    loaded = true;
    eligibleGroups.push(
      ...sipGroups.data.controlGroups.filter(
        controlGroup =>
          controlGroup.CheckpointType !== ControlGroupType.FORMULA &&
          controlGroup.CheckpointType !== ControlGroupType.WORKFLOW
      )
    );
  }

  if (!loaded) {
    return <Loading text={trans("global-loading")} />;
  }

  if (eligibleGroups.length < 1) {
    return (
      <>
        <Navbar
          headerText={trans("config-adhoc-from-control-group")}
          backLink={() => {
            return history.push(redirectUrl);
          }}
        />

        <EmptyPage text={trans("no-active-control-groups")} />
      </>
    );
  }

  return (
    <>
      <Navbar
        headerText={trans("config-adhoc-from-control-group")}
        backLink={() => {
          return history.push(redirectUrl);
        }}
      />
      <form onSubmit={handleFormSubmit}>
        <div className="text-block">
          {eligibleGroups.map(
              (controlGroup: TApiControlGroupEntity, index: number) => {
                return (
                    <div className="input-group pb-1" key={index}>
                      <input
                          type="radio"
                          name="controlGroupId"
                          id={"cg-" + index}
                          value={controlGroup.TreeNode.ID}
                          onChange={e => setSelectedControlGroup(e.target.value)}
                          required
                      />
                      <label className="ml-1" htmlFor={"cg-" + index}>
                        {controlGroup.TreeNode.Name}
                      </label>
                    </div>
                );
              }
          )}
        </div>
        <div className="text-block">
          <label htmlFor="start">{trans("input-select-date")}</label>
          <div className="input-group mb-3">
            <ReactDatePicker
                selected={startDate}
                onChange={e => setStartDate(e)}
                showTimeSelect={true}
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="time"
                maxDate={maxDate}
                className="inp"
            />
          </div>
        </div>
        <div className="input-group mt-3 d-flex justify--center">
          <button className="btn btn-small btn-primary">
            {trans("global-ok")}
          </button>
        </div>
      </form>
    </>
  );
};

export default AdHocFromControlGroup;
